<template>
    <div class="no-xhide">
        <div data-aos="fade-up">
            <v-card class="elevation-0 px-16 py-10 support-main-bg rounded-xl d-none d-md-block">
                <v-row no-glutters>
                    <v-col cols="6">
                        <p class="white--text fs-50 font-weight-bold mb-6">Terms & Conditions</p>
                        <p class="white--text headline">Simple terms and conditions with <br> no jargons and no fine print.
                        </p>

                    </v-col>
                    <v-col cols="6">
                        <v-container fill-height class="d-flex">
                            <v-card
                                class="elevation-0 my-auto pl-12 pr-16 py-8 c-brd-3 rounded-xl d-inline-flex white--text">
                                <div class="mt-3 ml-10">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56"
                                        fill="none">
                                        <path d="M13.881 29.5859L5.25 47.2504L14 45.5004L19.25 52.5004L26.5895 36.6944"
                                            stroke="white" stroke-width="2" stroke-miterlimit="10" />
                                        <path
                                            d="M42.1187 29.5859L50.7496 47.2504L41.9997 45.5004L36.7497 52.5004L29.4102 36.6944"
                                            stroke="white" stroke-width="2" stroke-miterlimit="10" />
                                        <path
                                            d="M28 36.75C37.665 36.75 45.5 28.915 45.5 19.25C45.5 9.58502 37.665 1.75 28 1.75C18.335 1.75 10.5 9.58502 10.5 19.25C10.5 28.915 18.335 36.75 28 36.75Z"
                                            stroke="white" stroke-width="2" stroke-miterlimit="10"
                                            stroke-linecap="square" />
                                        <path
                                            d="M28 26.25C31.866 26.25 35 23.116 35 19.25C35 15.384 31.866 12.25 28 12.25C24.134 12.25 21 15.384 21 19.25C21 23.116 24.134 26.25 28 26.25Z"
                                            stroke="white" stroke-width="2" stroke-miterlimit="10"
                                            stroke-linecap="square" />
                                    </svg>
                                </div>
                                <p class="mb-0 ml-6 mr-12">
                                    <span class="font-weight-bold fs-20">Our Promise.</span> <br>
                                    <span class="font-weight-light fs-16">
                                        Easy to understand<br>
                                        & simple to use.</span>
                                </p>
                            </v-card>
                        </v-container>

                    </v-col>
                </v-row>
            </v-card>
            <v-card class="elevation-0 px-6 px-sm-8 py-10 support-main-bg rounded-0 d-md-none text-center">
                <v-row no-glutters>
                    <v-col cols="12">
                        <p class="white--text fs-38 lh-38 font-weight-bold mb-6">Terms & Conditions</p>
                        <p class="white--text fs-18">Simple terms and conditions with no jargons and no fine print.
                        </p>

                    </v-col>
                    <v-col cols="12">
                        <v-card width="100%" class="elevation-0 py-2 c-brd-3 rounded-xl white--text">

                            <p class="mb-0 font-weight-bold fs-38">7 <span class="fs-24">Our Promise.makers</span></p>
                        </v-card>

                    </v-col>
                </v-row>
            </v-card>
        </div>
        <div class="py-md-10 mb-6 px-3 px-sm-5 px-md-16">
            <div class="pa-md-4">
                <v-row>
                    <v-col cols="3" class="pr-1 d-none d-md-flex">
                        <v-card width="100%" class="elevation-0">
                            <v-list dense nav>
                                <v-list-item-group @change="setScrollwindow()" v-model="panelselecte" class="list-group">
                                    <v-list-item v-for="(p, h) in terms" :key="h">
                                        <v-list-item-content>
                                            <v-list-item-title
                                                :class="panelselecte == h ? 'primary--text' : 'txt-black'"
                                                class="font-weight-bold subtitle-1">{{ h + 1 }}. {{ p.title
                                                }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-card>
                    </v-col>

                    <v-col cols="12" md="9" class="mt-4">
                        <v-row justify="center" class="px-md-4">
                            <v-expansion-panels dense v-model="panelselecte" class="elevation-0 rounded-md">
                                <v-expansion-panel class="exp-sty" v-for="(p, h) in terms" :key="h">
                                    <v-expansion-panel-header @click="setScrollwindow()" class="font-weight-bold subtitle-1 py-0 ml-md-3">{{ h + 1 }}.
                                        {{
                                            p.title }}
                                        <template v-slot:actions>
                                            <v-icon></v-icon>
                                        </template>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <p class=" font-weight-light" v-html="p.content"></p>
                                    </v-expansion-panel-content>
                                    <v-divider></v-divider>

                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-row>
                    </v-col>
                </v-row>
            </div>

        </div>
    </div>
</template>

<script>
export default {

    data: () => ({
        terms: [
            {
                title: 'Internet trading account',
                content: "<p class='font-weight-bold title mb-1'>Opening your internet trading account</p><p class='mb-0 font-weight-light lh-28'>  To commence internet trading with Zebu, you have to open a trading account and a depository account (D-mat)with Zebu. The receipts and payments for buying and selling of shares and the commissions and charges will be posted to your trading account. The shares you buy and sell through the trading account will be received in or delivered from your D-mat account.<br><br>The trading account refers to your account maintained by Zebu in your books of accounts.<br><br>D-mat account is the account opened by you with the depository for holding securities in an electronic form. For the purpose of internet trading through Zebu, the D-mat account refers to the account opened by you with Central Depository Services Limited (CDSL) through Zebu Share and Wealth Managements Pvt Ltd as depository participants (DP). If you already have a D-mat account with Zebu, then the same account can be linked to your internet trading account.<br><br>Provide your account details to us at the time of becoming an internet trading customer. Before your account is made operational, you have to fill up and sign all forms/agreements that are part of your account opening kit. You also need to furnish the relevant legal documents and financial information, which we need to maintain as per the law. You have to assure that the information you provide in your account application (or any other information that Zebu may require) is accurate and truthful. You also have to authorize Zebu to obtain reports concerning your credit standing and business conduct.<br><br>If your application is approved, Zebu will open an internet trading account (an ‘account') for you. Zebu holds the absolute right to accept or reject your application on any grounds whatsoever and you agree that you shall not dispute the result in any manner whatsoever. </p>"
            },
            {
                title: 'Investment advice',
                content: "<p class='font-weight-bold title mb-1'>Investment advice</p><p class='mb-0 font-weight-light lh-28'>   While Zebu provides advice regarding the suitability/profitability of a scrip, investment or any other tax or legal advice, you assume full responsibility for the transactions in your account and your investment decisions. Zebu and its officers, directors, employees, agents, and affiliates cannot be held liable with respect to the above. </p>"
            },
            {
                title: 'Rules and regulations',
                content: "<p class='font-weight-bold title mb-1'>Rules and regulations</p><p class='mb-0 font-weight-light lh-28'>All transactions in your account shall are subject to the constitution, rules, regulations, customs, and usage of the exchange or the market and its clearing house, if any, where the transactions are executed by Zebu or its agents, including Zebu's subsidiaries and affiliates. Also, wherever applicable, the transactions shall be subject to the provisions of the Securities and Contracts Regulations Act (SCRA), as amended, and the rules and regulations of the Securities and Exchange Board of India (SEBI), and the Central Depository Services Limited (CDSL), the exchanges where Zebu will facilitate trading, and any applicable self-regulatory organisation. </p> "
            },
            {
                title: 'Eligibility criteria',
                content: "<p class='font-weight-bold title mb-1'>Eligibility criteria</p><p class='mb-0 font-weight-light lh-28'>You can open accounts if you are over 18 years of age. You must operate these accounts on an individual basis. The internet trading account is open for Indian residents only. Indian regulations require us to maintain the basic financial details of each client. You need to provide us the details of your bank account. We also need your signature on the account opening form, as maintained in the bank records. All resident individuals must also provide the income tax returns details (IT PAN/GIR number), at the time of account opening.<br><br>Commissions and fees<br>Commissions and fees mutually agreed and subject to the maximum prescribed by SEBI.</p>"
            },
            {
                title: 'Depository Account',
                content: "<p class='font-weight-bold title mb-1'>Depository Account</p><p class='mb-0 font-weight-light lh-28'>Joint accounts<br>Your D-mat account can be held jointly with other people (typically relatives). For this, instructions and sufficient conditions to effect a transaction must be given to us by someone who knows your password. Please note that regulations require both the account holders to sign on the instructions, stating that you are agreeable to any transaction on the account, executed in the manner outlined.<br><br>Nomination<br>You can also nominate someone for your D-mat account. He or she becomes the beneficiary of the shares held in the account in the unfortunate event of your demise.<br><br>Cheque receipt/ payment<br>You can deposit amount in your Account by using Internet banking, Payment Gateway or by personal cheque or a demand draft. Credit will be available in your Account after the funds are cleared.<br><br>Digital contract notes<br>Contract notes, as per the prevailing rules and regulations, shall be issued within the stipulated time limit by email to the address provided by you.</p>"
            },
            {
                title: 'Exposure Limit',
                content: "<p class='font-weight-bold title mb-1'>Exposure Limit</p><p class='mb-0 font-weight-light lh-28'>Compliance action includes but is not limited to:<br><br><ul><li>Squaring off of outstanding transactions</li><li>We have a full lien on cash and securities balances in the trading account /depository account of the client and may opt for the sale of such securities lying or stock lying in the client depository account with us. Any loss arising out of squaring off or sale of securities will be to the client account and we will not be liable for any cost, damage or any consequences.</li><li>Locking / suspending client account and no further transactions will be allowed till the time all dues are settled or till the time deemed fit by us.</li><li>Withholding payout of securities bought by the client against any dues receivable by us.</li><li>Levy of interest @18 p.a. for any delay in receipt of our dues, which include payment in amount, margin amount, fees, commission, or other charges.</li></ul><br><br>We may take any one or all compliance actions mentioned above. We will intimate the client about the compliance action being taken and the details regarding the same.<br><br>To avoid compliance action, the client should deposit additional funds, squared off outstanding transactions or sale other securities lying in the depository account to make the ledger positive. The client should always look at the exposure, market-to-market losses, pay in and other obligation alerts forwarded by us, to enable himself to take necessary action in a timely manner.</p> "
            },
            {
                title: 'Disclosure of information',
                content: "<p class='font-weight-bold title mb-1'>Disclosure of information regarding client</p><p class='mb-0 font-weight-light lh-28'>Zebu hereby undertakes to maintain the details of the client as mentioned in the client registration form or any other information pertaining to the client, in confidence. We shall not disclose the same to any person/entity except when required by the law.<br><br>However, Zebu shall be allowed to share the details of the client as mentioned in the client registration form or any other information pertaining to the client with parties/entities with the express permission of the client.<br><br>The following advice is issued in the interest of investors:<br>Get all information related to your transactions directly from the stock exchanges on your mobile phone/email id, at the end of every day. Please intimate us in the case of any change in your address, bank account, email, mobile number etc.<br><br>You do not have to issue a cheque while subscribing to an IPO. Write your bank account number clearly on the IPO application and sign it, sanctioning your bank to make payments when there is an allotment. Your funds will remain in your bank account in the case of non-allotment.</p>"
            }
        ],
        panelselecte: 0,
    }),
    methods: {
        setScrollwindow() {
            window.scrollTo(0, 280)
         },
    }
}
</script>