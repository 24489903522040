<template>
    <div class="no-xhide">
        <div data-aos="fade-up" class="pt-md-6">
            <v-card class="elevation-0 px-16 py-10 rounded-xl pos-rlt d-none d-md-block" color="#E5EBEC">
                <p class="fs-50 font-weight-bold mb-0">Calculators</p>
                <p class="headline">Discover our calculator designed to assist you in <br> making informed trading decisions.
                </p>
                <v-autocomplete @change="goCalculator()" item-text="text" item-value="too" append-icon="" :items="calcitems"
                    v-model="calcs" block flat solo background-color="#ffffff"
                    class="search-field elevation-0 mr-3 rounded-pill caption" label="Search calculators" hide-details>
                    <template #prepend-inner>
                        <img src="@/assets/search-icon.svg" width="100%" class="mx-2" alt="search-icon">
                    </template>
                </v-autocomplete>
                <div>
                    <img class="pos-abs calc-img text-right" alt="main-bg.png" src="@/assets/calculators/main-bg.png" width="42%" />
                </div>
                <div class="pos-abs learn-img">
                    <img alt="learn-bg.svg" src="@/assets/learn-bg.svg" width="172px" />
                </div>
            </v-card>
            <v-card class="elevation-0 px-6 px-sm-8 py-10 rounded-0 d-md-none text-center" color="#E5EBEC">
                <p class="fs-38 font-weight-bold">Calculators</p>
                <p class="fs-18 mb-8">Discover our calculator designed to assist you in making informed trading decisions.
                </p>
                <v-autocomplete @change="goCalculator()" item-text="text" item-value="too" append-icon="" :items="calcitems"
                    v-model="calcs" block flat solo background-color="#ffffff"
                    class="elevation-0 rounded-pill caption" label="Search calculators" hide-details>
                    <template #prepend-inner>
                        <img src="@/assets/search-icon.svg" width="100%" class="mx-2" alt="search-icon">
                    </template>
                </v-autocomplete>
            </v-card>
        </div>
        <div class="py-md-16">
            <router-view />
        </div>
    </div>
</template>
  
<script>
export default {

    data: () => ({
        calcitems: [
            { text: 'Brokerage Calculator', too: '/calculator/brokerage', sub: "Our brokerage calculator provides valuable assistance in determining the break-even point for any trade and estimating potential earnings." },
            { text: 'Span Calculator', too: '/calculator/span', sub: "Span Calculator is the premier online trading tool in India, providing users with the capability to calculate comprehensive span margin requirements for option writing/shorting or for multi-leg F&O strategies across equity, F&O, commodity, and currency trading before executing a trade. Gone are the days of entering trades blindly, as our platform empowers traders with precise margin estimations." },
            { text: 'CAGR Calculator', too: '/calculator/cagr', sub: "Compound Annual Growth Rate (CAGR) represents the annualized return on an investment, regardless of its yearly performance. It provides insight into the cumulative growth of an investment over a specified period of time." },
            { text: 'Car Loan Calculator', too: '/calculator/carloan', sub: "India presently stands as the world's fourth-largest market for four-wheelers, with a consistent year-on-year growth rate of 10%. Consequently, there has been a substantial surge in demand for a reliable and user-friendly car loan EMI calculator." },
            { text: 'Compound Interest Calculator', too: '/calculator/compoundinterest', sub: "A Compound Interest calculator is utilized to forecast compound growth for savings accounts or investments over various time periods, based on a specified interest rate." },
            { text: 'EMI Calculator', too: '/calculator/emi', sub: "The credit market in India is experiencing consistent growth, currently ranking as the fourth largest credit industry globally, with a Compound Annual Growth Rate (CAGR) exceeding 11% year on year. A significant portion of these loans comprises short-term credit facilities such as personal loans and credit cards." },
            { text: 'EPF Calculator', too: '/calculator/epf', sub: "The Employee Provident Fund (EPF) is a retirement benefits scheme catering to salaried employees in the private sector. Managed by the Employees Provident Fund Organisation (EPFO), it extends coverage to organizations or firms employing 20 or more individuals. The EPFO administers three schemes under its purview." },
            { text: 'Fixed-deposit Calculator', too: '/calculator/fixeddeposit', sub: "In India, fixed deposits stand out as one of the most favored investment vehicles. They offer assured returns and flexibility in choosing the tenure that best suits an individual's financial goals." },
            { text: 'Flat & Reducing Intrest calculator', too: '/calculator/flatvsreduce', sub: "Interest constitutes a portion of the loan amount remitted to the lender alongside the principal sum. Across different financial institutions, loan interest rates are uniformly computed. This rate, typically expressed as a yearly percentage, is also termed the Annual Percentage Rate (APR). Every Equated Monthly Installment (EMI) comprises two components: one allocated to the principal repayment and the other designated for interest payment on the personal loan." },
            { text: 'Gratuity Calculator', too: '/calculator/gratuity', sub: "A gratuity calculator is a valuable tool for quickly estimating the amount you're entitled to receive upon leaving a job after serving at least five years with the company. To utilize this calculator effectively, you'll need to input your final salary, the duration of your tenure with the company (including months), and the dearness allowance component of your salary." },
            { text: 'GST Calculator', too: '/calculator/gst', sub: "The GST calculator is an online tool designed to compute the Goods and Services Tax payable to the Government of India, either on a monthly or quarterly basis. It offers a user-friendly interface and versatility, catering to a wide range of users including retailers, wholesalers, buyers, and manufacturers." },
            { text: 'Home Loan Calculator', too: '/calculator/homeloan', sub: "Given the exorbitant prices of real estate in India, acquiring a residential property can pose a formidable challenge without recourse to external financial assistance. Consequently, an escalating number of prospective homebuyers are availing themselves of housing credit to facilitate their property acquisitions. It comes as no surprise that housing credit experienced a growth rate exceeding 16% during the fiscal year 2018." },
            { text: 'HRA Calculator', too: '/calculator/hra', sub: "House Rent Allowance (HRA) constitutes the portion of an employee's salary allocated by the employer to cover accommodation rental expenses." },
            { text: 'Lumpsum Intrest Calculator', too: '/calculator/lumpsum', sub: "A lump sum calculator assists investors in estimating the returns expected from a lump sum mutual fund investment. It calculates the maturity amount for a given lump sum investment made at present, providing insight into the potential wealth accumulation during the investment tenure." },
            { text: 'Mutual Fund Calculator', too: '/calculator/mutualfund', sub: "This calculator serves to ascertain the projected total value of your investment in a mutual fund over a specified duration, considering a given rate of return. Additionally, it aids in determining the required investment amount to achieve a predetermined target at the end of a specified period." },
            { text: 'NPS Calculator', too: '/calculator/nps', sub: "The National Pension Scheme (NPS) Calculator assists individuals in determining the monthly pension and lump sum amount they may receive upon retirement. Utilizing the NPS Calculator allows for strategic planning of monthly contributions towards the NPS. Investing in NPS provides tax benefits under Section 80CCD and is regarded as an appealing retirement solution. The NPS calculator serves as a valuable tool for making well-informed investment decisions in NPS." },
            { text: 'PPF Calculator', too: '/calculator/ppf', sub: "If you're considering investing in a Public Provident Fund (PPF) and uncertain about the optimal investment amount or potential returns, our PPF calculator is tailored for you. Once you determine the amount you can comfortably invest regularly, the calculator assumes a tenure of 15 years and utilizes the prevailing interest rate to compute the anticipated returns." },
            { text: 'RD Calculator', too: '/calculator/rd', sub: "Recurring deposits (RDs) serve as an investment vehicle akin to fixed deposits, with the distinction that they require fixed monthly deposits rather than a lump sum amount as with FDs. RDs cultivate a habit of consistent investment among earners, fostering disciplined savings practices. They are commonly provided by numerous banks and financial institutions." },
            { text: 'Simple Interest Calculator', too: '/calculator/simpleinterest', sub: "The term used to denote the cost of borrowing or lending funds is 'simple interest.' When a borrower obtains funds from a lender, an additional sum is repaid to the lender. The initial sum borrowed, which is provided for a defined duration, is referred to as the principal. The supplementary amount repaid to the lender for the utilization of the funds is known as the interest." },
            { text: 'SIP Calculator', too: '/calculator/sip', sub: "Our Systematic Investment Plan (SIP) calculator assists in estimating potential returns based on a specified SIP amount and interest rate over a predetermined period. Additionally, it can be utilized to ascertain the monthly investment required to achieve a desired target amount within a set timeframe." },
            { text: 'SSY Calculator', too: '/calculator/ssy', sub: "The Sukanya Samriddhi Yojana (SSY) is a government-supported small savings scheme aimed at securing the future of the girl child. It serves as a high-yield, tax-saving investment instrument designed to provide financial stability for young girls." },
        ],
        calcs: null,
    }),
    methods: {
        goCalculator() {
            this.$router.push(this.calcs);
        }
    }
}
</script>