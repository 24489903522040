<template>
  <div class="no-xhide">
    <v-snackbar class="snakbar-sty pt-6 d-md-none pr-6 z-i6 rounded-pill" transition="slide-x-reverse-transition" v-model="snackbar"
      timeout="4000" :value="true" :color="snackbarclr" absolute text-color="white">
      <v-icon class="mr-2" color="#fff">mdi-alert-outline</v-icon>
      {{ snackmsgbar }}
      <v-icon @click="snackbar = false" class="float-right" color="#fff">mdi-close-circle</v-icon>
    </v-snackbar>
    <v-snackbar class="snakbar-sty d-none d-md-flex pt-6 pr-6 z-i6 rounded-pill mt-10" transition="slide-x-reverse-transition" top
      right v-model="snackbar" timeout="4000" :value="true" :color="snackbarclr" absolute text-color="white">
      <v-icon class="mr-2" color="#fff">mdi-alert-outline</v-icon>
      {{ snackmsgbar }}
      <v-icon @click="snackbar = false" class="float-right" color="#fff">mdi-close-circle</v-icon>
    </v-snackbar>
    <div data-aos="fade-up" class="pos-rlt pt-md-6 mb-6 mb-md-16">
      <v-card class="elevation-0 px-16 py-10 rounded-xl pos-rlt d-none d-md-block" color="#E5EBEC">
        <p class="fs-50 font-weight-bold mb-0">Zebu Blogs</p>
        <div class="d-inline-flex mb-6">
          <v-btn @click="activePage(i = p)" v-for="(p, b) in blogitems" :key="b" medium color="#666666"
            class="rounded-pill mr-2 text-none brd-2" outlined>
            {{ p.txt }}
          </v-btn>
        </div>
        <BlogSearch />
        <div>
          <img class="pos-abs calc-img text-right" alt="main-bg.png" src="@/assets/blog/main-bg.png" width="42%" />
        </div>
        <div class="pos-abs learn-img">
          <img alt="learn-bg.svg" src="@/assets/learn-bg.svg" width="172px" />
        </div>
      </v-card>

      <v-card class="elevation-0 px-6 px-sm-8 py-10 rounded-0 text-center d-md-none" color="#E5EBEC">
        <p class="fs-38 font-weight-bold mb-0">Zebu Blogs</p>
        <div class="my-4">
          <v-btn @click="activePage(i = p)" v-for="(p, b) in blogitems" :key="b" small
            :color="p.val === pagename.val ? '#000000' : '#666666'" class="rounded-pill text-none brd-2 mb-2 mr-2"
            outlined>
            {{ p.txt }}
          </v-btn>
        </div>
        <BlogSearch />
      </v-card>
    </div>

    <div class="px-6 px-sm-8 px-md-14 pt-4 pt-md-0">
      <p class="mb-0 fw-6 secondary-infont fs-32 d-none d-md-flex">Today's Daily</p>
      <p class="mb-0 fw-6 secondary-infont fs-28 d-md-none">Today's Daily</p>

      <div v-if="mainblog.attributes">
        <v-row no-glutters>
          <v-col cols="12" md="6">
            <v-card data-aos="zoom-in-up" @click="singleBlog(b = mainblog)" width="100%" class="elevation-0 mr-md-4">
              <v-card class="rounded-lg elevation-0">
                <img :src="mainblog.attributes ? mainblog.attributes.img : ''" width="100%" height="336px"
                  class="d-none d-md-block"
                  :alt="mainblog.attributes ? mainblog.attributes.blog_image.data[0].attributes.url : ''">
                <img :src="mainblog.attributes ? mainblog.attributes.img : ''" width="100%" class="d-md-none"
                  :alt="mainblog.attributes ? mainblog.attributes.blog_image.data[0].attributes.url : ''">
              </v-card>
              <v-list-item data-aos="fade-left" class="px-0">
                <v-list-item-content class="pt-1">
                  <v-list-item-title class="font-weight-bold subtitle-1 text-rap-l2">{{ mainblog.attributes ?
      mainblog.attributes.blog_title : ''
                    }}</v-list-item-title>

                  <p class="mb-0 subtitle-2 font-weight-light txt-666666 text-rap-l3">{{ mainblog.attributes ?
      mainblog.attributes.blog_description : '' }}
                  </p>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-row no-glutters>
              <v-col v-for="(b, l) in mainside" :key="l" cols="6" sm="6" class="pb-0 pb-md-3">
                <v-card data-aos="zoom-in-up" @click="singleBlog(b)" width="100%" class="elevation-0"
                  :class="l != mainside.length - 1 ? 'mr-md-4' : 'mr-md-0'">
                  <v-card class="rounded-lg elevation-0">
                    <img :src="b.attributes.img" width="100%" :alt="b.attributes.blog_image.data[0].attributes.url">
                  </v-card>

                  <v-list-item data-aos="fade-left" class="px-0">
                    <v-list-item-content class="pt-1">
                      <v-list-item-title class="font-weight-bold subtitle-1 text-rap-l2">{{ b.attributes.blog_title
                        }}</v-list-item-title>
                      <p class="mb-0 subtitle-2 font-weight-regular txt-666666">{{ b.attributes.publishedAt
                        }}
                      </p>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-glutters>
          <v-col v-for="(b, l) in mainslide" :key="l" cols="12" sm="4" md="3" class="pb-0 pb-md-3">
            <v-card data-aos="zoom-in-up" @click="singleBlog(b)" width="100%" class="elevation-0"
              :class="l != mainslide.length - 1 ? 'mr-md-4' : 'mr-md-0'">
              <v-card class="rounded-lg elevation-0">
                <img :src="b.attributes.img" width="100%" :alt="b.attributes.blog_image.data[0].attributes.url">
              </v-card>

              <v-list-item data-aos="fade-left" class="px-0">
                <v-list-item-content class="pt-1">
                  <v-list-item-title class="font-weight-bold subtitle-1 text-rap-l2">{{ b.attributes.blog_title
                    }}</v-list-item-title>
                  <p class="mb-0 subtitle-2 font-weight-regular txt-666666">{{ b.attributes.publishedAt }}
                  </p>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-container fill-height>
          <v-card class="elevation-0 mx-auto my-16">
            <v-progress-circular size="80" indeterminate color="#1e53e5"></v-progress-circular>
          </v-card>
        </v-container>
      </div>

      <div class="my-6 my-md-16">
        <v-card width="100%" class="price-card-sty elevation-0 rounded-xl py-6 px-8 d-none d-md-block">
          <v-row>
            <v-col cols="5">
              <p class="mb-0 font-weight-bold secondary-infont mb-4 fs-32">Know the Markets Better
              </p>
              <p class="mb-2 font-weight-regular">Easy-to-consume investment insights, <br> news & deep-dives delivered
                to your inbox</p>
            </v-col>
            <v-col cols="7">

              <v-container fill-height>
                <v-text-field flat solo background-color="#ffffff" outlined hide-details v-model="marketemail"
                  class="menu-field-num elevation-0 mr-3 rounded-pill caption my-auto ml-16" label="Enter your email"
                  required
                  :rules="[v => !!v || 'E-mail is required', v => /^[\w\.-]+@[\w\.-]+\.\w+$/.test(v) || 'E-mail must be valid']">
                  <template #prepend-inner>
                    <img src="@/assets/mail-icon.svg" width="100%" class="mx-2" alt="mail-icon">
                  </template>
                  <template #append>
                    <v-btn color="#000" :disabled="!marketemail" @click="sendmailMarket()"
                      class="text-none rounded-pill elevation-0"><span
                        class="black--text font-weight-bold white--text">Send</span></v-btn>
                  </template>
                </v-text-field>
              </v-container>
            </v-col>
          </v-row>
        </v-card>
        <v-card width="100%" class="price-card-sty elevation-0 rounded-xl py-6 px-6 px-sm-8 d-md-none">
          <v-row>
            <v-col cols="12" md="5">
              <p class="font-weight-bold secondary-infont mb-2 fs-24">Know the Markets Better
              </p>
              <p class="mb-2 fs-16 font-weight-regular">Easy-to-consume investment insights, <br> news & deep-dives
                delivered
                to your inbox</p>
            </v-col>
            <v-col cols="12" md="7" class="pa-0 pa-md-3">

              <v-container fill-height>
                <v-text-field flat solo background-color="#ffffff" outlined hide-details block v-model="marketemail"
                  class="menu-field-num elevation-0 rounded-pill caption my-auto" label="Enter your email" required
                  :rules="[v => !!v || 'E-mail is required', v => /^[\w\.-]+@[\w\.-]+\.\w+$/.test(v) || 'E-mail must be valid']">
                  <template #prepend-inner>
                    <img src="@/assets/mail-icon.svg" width="100%" class="mx-2" alt="mail-icon">
                  </template>
                  <template #append>
                    <v-btn color="#000" :disabled="!marketemail" @click="sendmailMarket()"
                      class="text-none rounded-pill elevation-0"><span
                        class="black--text font-weight-bold white--text">Send</span></v-btn>
                  </template>
                </v-text-field>
              </v-container>
            </v-col>
          </v-row>
        </v-card>
      </div>

      <div v-if="blogitems.length > 0">
        <div v-for="(i, k) in blogitems" :key="k" class="mb-8">
          <v-toolbar dense class="elevation-0 mb-3 d-none d-md-block">
            <div>
              <p class="mb-0 font-weight-bold secondary-infont fs-32 lh-32">{{ i.tit }}</p>
            </div>
            <v-spacer></v-spacer>
            <v-btn @click="activePage(i)" color="#0037B7" text class="text-capitalize"><span
                class="font-weight-medium">{{
      i.btn }}</span></v-btn>
          </v-toolbar>

          <v-toolbar dense class="elevation-0 mb-3 d-md-none blog-subheader">
            <div>
              <p class="mb-0 font-weight-bold secondary-infont fs-24 lh-24">{{ i.tit }}</p>
            </div>
            <v-spacer></v-spacer>
            <v-btn @click="activePage(i)" color="#0037B7" text class="text-capitalize fs-14" small><span
                class="font-weight-medium">{{
      i.btn }}</span></v-btn>
          </v-toolbar>

          <div class="pl-md-4">
            <v-row no-glutters>
              <v-col v-for="(b, l) in i.data" :key="l" cols="12" sm="4" md="3" class="pb-0 pb-md-3">
                <v-card data-aos="zoom-in-up" @click="singleBlog(b, i)" width="100%" class="elevation-0"
                  :class="l != i.data.length - 1 ? 'mr-md-4' : 'mr-md-0'">
                  <v-card class="rounded-lg elevation-0">
                    <img :src="b.attributes.img" width="100%" :alt="b.attributes.blog_image.data[0].attributes.url">
                  </v-card>

                  <v-list-item data-aos="fade-left" class="px-0">
                    <v-list-item-content class="pt-1">
                      <v-list-item-title class="font-weight-bold subtitle-1 text-rap-l2">{{ b.attributes.blog_title
                        }}</v-list-item-title>
                      <p class="mb-0 subtitle-2 font-weight-regular txt-666666">{{ b.attributes.publishedAt
                        }}
                      </p>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { strapi } from '../../apiurl'
import BlogSearch from '../Blogs/BlogSearch.vue'

export default {

  data: () => ({
    snackbar: false,
    snackbarclr: "#2a2a2a",
    snackmsgbar: "",
    marketemail: null,
    page: 1,
    blogitems: [],

    pagename: {},

    allblogs: [],
    catblogs: [],

    detailblog: {},
    mainblog: {},
    mainside: [],
    mainslide: [],

  }),

  components: {
    BlogSearch
  },

  mounted() {
    this.getCategorie();
    this.getBlogdata();
  },

  methods: {
    activePage(i) {
      this.$router.push({ name: 'blog category', params: i });
    },
    getCategorie() {
      this.blogitems = [];
      this.blogitems = [{ tit: 'All', txt: 'All', btn: 'Show all blogs', val: 'all', id: 1 }]
      let config = {
        method: 'get',
        url: `${strapi}/blog-categories`,
      };

      let axiosThis = this;
      axios.request(config)
        .then((response) => {
          if (response.data.data) {
            let data = response.data.data;
            for (let c = 0; c < data.length; c++) {
              let cconfig = {
                method: 'get',
                url: `${strapi}/blogs?filters[blog_categories][name][$eq]=${data[c].attributes.name}&sort[0]=blogdate:desc&pagination[pageSize]=${4}&populate[blog_image]=*`,
              };
              let catdata = [];
              axios.request(cconfig)
                .then((response) => {
                  if (response.data.data) {
                    let datas = response.data.data
                    for (let i = 0; i < datas.length; i++) {
                      datas[i].attributes['img'] = `${strapi}${datas[i].attributes.blog_image.data[0].attributes.url}`;
                      datas[i].attributes.publishedAt = `${new Date(datas[i].attributes.publishedAt).toDateString().slice(3)} ${new Date(datas[i].attributes.publishedAt).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })} IST`;
                      catdata.push(datas[i]);
                    }
                  } else {
                    catdata = []
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
              axiosThis.blogitems.push({ tit: data[c].attributes.name, txt: data[c].attributes.name.split(' ', 1)[0], btn: `More ${data[c].attributes.name} blog`, val: data[c].attributes.name.slice(0, 3), id: c + 2, data: catdata })
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getBlogdata() {
      this.allblogs = [];

      let config = {
        method: 'get',
        url: `${strapi}/blogs?sort[0]=blogdate:desc&pagination[page]=${this.page}&pagination[pageSize]=24&populate=*`,
      };

      let axiosThis = this;
      axios.request(config)
        .then((response) => {
          if (response.data.data) {
            let data = response.data.data;
            for (let i = 0; i < data.length; i++) {
              data[i].attributes['img'] = `${strapi}${data[i].attributes.blog_image.data[0].attributes.url}`;
              data[i].attributes.publishedAt = `${new Date(data[i].attributes.publishedAt).toDateString().slice(3)} ${new Date(data[i].attributes.publishedAt).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })} IST`;
              axiosThis.allblogs.push(data[i]);
            }
            axiosThis.mainblog = axiosThis.allblogs[0];
            axiosThis.mainside = axiosThis.allblogs.slice(1, 5);
            axiosThis.mainslide = axiosThis.allblogs.slice(5, 9);
            let index = axiosThis.blogitems.findIndex(x => x.tit === "All");
            axiosThis.blogitems[index]['data'] = axiosThis.allblogs.slice(9, 13);
            window.scrollTo(0, 0);
          }
        })
        .catch((error) => {
          console.log(error);
        });

    },
    singleBlog(b, p) {
      if (p) {
        //  
      }
      else {
        p = this.blogitems.find(o => o.tit === 'All');
      }
      delete p.data;
      b.attributes['pagename'] = p;
      this.$router.push({ name: 'single blog', params: b });
    },
    catBlog() {
      var item = this.blogitems.find(o => o.tit === this.pagename.tit);
      if (item == undefined) {
        this.pagename = this.blogitems.find(o => o.tit === 'All');
      } else {
        this.pagename = item;
      }
      var i = this.pagename;
      this.activePage(i)
    },
    sendmailMarket() {
    
      if (this.marketemail) {
        setTimeout(() => {
          this.snackbar = true;
          this.snackbarclr = "#2a2a2a";
          this.snackmsgbar = "Thank you for your interest.";
        }, 100);
      }
    },
  }
}
</script>