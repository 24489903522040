<template>
  <div class="no-xhide">
    <div data-aos="fade-up" class="pos-rlt pt-md-6 mb-md-16">
      <v-card class="elevation-0 px-16 py-10 rounded-xl pos-rlt d-none d-md-block" color="#E5EBEC">
        <p class="fs-50 font-weight-bold mb-0">Zebu News</p>
        <div class="d-inline-flex mb-6">
          <v-btn @click="setFiltere(i = p)" v-for="(p, b) in newstypes" :key="b" medium
            :color="p.key == fliter.key ? '#000000' : '#666666'" class="rounded-pill mr-2 text-none brd-2" outlined>
            {{ p.txt }}
          </v-btn>
        </div>
        <v-autocomplete @change="newsPage(n = newsis)" item-text="title" item-value="link" append-icon="" :items="allnews"
          :disabled="isLoading" :loading="isLoading" v-model="newsis" block flat solo background-color="#ffffff" clearable
          return-object class="search-field elevation-0 mr-3 rounded-pill caption" label="Search news articles"
          hide-details>
          <template #prepend-inner>
            <img src="@/assets/search-icon.svg" width="100%" class="mx-2" alt="search-icon">
          </template>
        </v-autocomplete>
        <div>
          <img class="pos-abs calc-img text-right" alt="main-bg.png" src="@/assets/news/main-bg.png" width="40%" />
        </div>
        <div class="pos-abs learn-img">
          <img alt="learn-bg.svg" src="@/assets/learn-bg.svg" width="172px" />
        </div>
      </v-card>
      <v-card class="elevation-0 px-6 px-sm-8 py-10 rounded-0 text-center d-md-none" color="#E5EBEC">
        <p class="fs-38 font-weight-bold mb-0">Zebu News</p>
        <div class="my-4">
          <v-btn @click="setFiltere(i = p)" v-for="(p, b) in newstypes" :key="b" small
            :color="p.key == fliter.key ? '#000000' : '#666666'" class="rounded-pill mr-2 text-none brd-2 mb-2 mr-2"
            outlined>
            {{ p.txt }}
          </v-btn>
        </div>
        <v-autocomplete @change="newsPage(n = newsis)" item-text="title" item-value="link" append-icon="" :items="allnews"
          :disabled="isLoading" :loading="isLoading" v-model="newsis" block flat solo background-color="#ffffff" clearable
          return-object class="elevation-0 rounded-pill caption" label="Search news articles" hide-details>
          <template #prepend-inner>
            <img src="@/assets/search-icon.svg" width="100%" class="mx-2" alt="search-icon">
          </template>
        </v-autocomplete>
      </v-card>
    </div>

    <div class="py-6 pb-md-16 px-6 px-sm-8 px-md-16">
      <p class="mb-0 font-weight-medium secondary-infont mb-6 fs-32 d-none d-md-block">{{ fliter.tit }} News ({{ totalnews
        > 0 ? totalnews :
        '...' }})</p>
      <p class="mb-0 font-weight-medium secondary-infont mb-6 fs-24 d-md-none">{{ fliter.tit }} News ({{ totalnews > 0 ?
        totalnews :
        '...' }})</p>
      <div v-if="allnews.length > 0">
        <v-card class="rounded-lg elevation-0 mb-2 mb-md-4" v-for="(n, e) in allnews" :key="e" data-aos="fade-left"
          @click="newsPage(n)">
          <v-row no-glutters>
            <v-col cols="4" sm="3" xl="2">
              <v-card class="rounded-lg elevation-0">
                <img :src="n.image" width="100%" height="150px" class="rounded-lg d-none d-sm-block" :alt="n.image">
                <img :src="n.image" width="100%" height="68px" class="rounded-lg d-sm-none" :alt="n.image">

              </v-card>
            </v-col>
            <v-col cols="8" sm="9" xl="10" class="pl-0 pl-sm-3">
              <v-list-item two-line class="px-0 pr-sm-3">
                <v-list-item-content class="pt-1 d-none d-sm-block">
                  <v-list-item-title class="font-weight-medium title">{{ n.title }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="mb-8 subtitle-2 font-weight-regular">{{ n.isdate }}</v-list-item-subtitle>
                  <p class="mb-0 ln-24px" v-html="n.description"></p>
                </v-list-item-content>
                <v-list-item-content class="pt-0 d-sm-none">
                  <p class="font-weight-medium fs-16 mb-3 text-rap-l2 lh-16">{{ n.title }}</p>
                  <v-list-item-subtitle class="fs-12 font-weight-regular">{{ n.isdate }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-card>

        <v-container class="mt-10">
          <v-card v-if="pagecount" data-aos="fade-down" max-width="600px" class="box-s1 rounded-xl mx-auto py-1">
            <v-pagination @input="getNews()" color="#000" prev-icon="mdi-arrow-left" next-icon="mdi-arrow-right"
              v-model="page" class="my-3 elevation-0" :total-visible="11" :length="pagecount"></v-pagination>
          </v-card>
        </v-container>
      </div>
      <div v-else>
        <v-container fill-height>
          <v-card class="elevation-0 mx-auto my-16 py-16">
            <v-progress-circular size="80" indeterminate color="#1e53e5"></v-progress-circular>
          </v-card>
        </v-container>
      </div>
    </div>

  </div>
</template>
  
<script>
import axios from 'axios';
import { sessapi } from '../apiurl'

export default {

  data: () => ({
    newstypes: [
      { txt: 'Daily', key: 'day', tit: "Today's" },
      { txt: 'Weekly', key: 'weekly', tit: "Week's" },
      { txt: 'Monthly', key: 'monthly', tit: "Month's" },
      // { txt: 'Older news', key: 'older', tit: '' }
    ],
    page: 1,
    pagecount: 0,
    totalnews: 0,
    allnews: [],
    fliter: [],
    newsis: null,
    isLoading: null,
  }),

  mounted() {
    this.fliter = this.newstypes[0];
    this.getNews();
  },
  methods: {
    getNews() {
      this.isLoading = true;
      this.allnews = [];
      this.totalnews = 0;
      let config = {
        method: 'get',
        url: `${sessapi}/newsfeedin?pagesize=48&pagecount=${this.page}&filterdate=${this.fliter.key}`,
      };

      let axiosThis = this;
      axios.request(config)
        .then((response) => {
          if (response.data.data) {
            let data = response.data.data;
            for (let v = 0; v < data.length; v++) {
              data[v]['isdate'] = `${new Date(data[v].pubDate).toDateString().slice(3)} ${new Date(data[v].pubDate).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })} IST`;
              axiosThis.allnews.push(data[v]);
            }
            axiosThis.totalnews = response.data.newsCount
            axiosThis.pagecount = response.data.Totalpages;
            window.scrollTo(0, 0);
          }
          axiosThis.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });

    },
    differentDate(date) {
      var date1 = new Date(date);
      var date2 = new Date();
      var ov = Math.abs(date2.getTime() - date1.getTime())
      var mt = Math.round(ov / 3600000)
      var dd = mt > 60 ? Math.round(mt / 24) : 0;
      var mm = dd > 30.4166667 ? Math.round(dd / 30.4166667) : 0;
      var yy = mm > 12 ? Math.round(dd / 365) : 0;
      return `${yy != 0 ? yy : mm != 0 ? mm : dd != 0 ? dd : mt != 0 ? mt : 0} ${yy != 0 ? yy > 1 ? 'years' : 'year' : mm != 0 ? mm > 1 ? 'months' : 'month' : dd != 0 ? dd > 1 ? 'days' : 'day' : mt > 1 ? 'hours' : 'minutes'} ago`;
    },
    newsPage(n) {
      if (n) {
        window.open(n.link, "_blank");
        this.newsis = null;
      }
    },
    setFiltere(p) {
      this.fliter = p;
      this.getNews();
    }
  }
}
</script>