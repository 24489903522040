<template>
    <div>
        <v-snackbar class="snakbar-sty pt-6 d-md-none pr-6 z-i6 rounded-pill" transition="slide-x-reverse-transition" v-model="snackbar"
          timeout="4000" :value="true" :color="snackbarclr" absolute text-color="white">
          <v-icon class="mr-2" color="#fff">mdi-alert-outline</v-icon>
          {{ snackmsgbar }}
          <v-icon @click="snackbar = false" class="float-right" color="#fff">mdi-close-circle</v-icon>
        </v-snackbar>
        <v-snackbar class="snakbar-sty d-none d-md-flex pt-6 pr-6 z-i6 rounded-pill mt-10" transition="slide-x-reverse-transition"
            top right v-model="snackbar" timeout="4000" :value="true" :color="snackbarclr" absolute text-color="white">
            <v-icon class="mr-2" color="#fff">mdi-alert-outline</v-icon>
            {{ snackmsgbar }}
            <v-icon @click="snackbar = false" class="float-right" color="#fff">mdi-close-circle</v-icon>
          </v-snackbar>

        <div class="px-0 px-md-14">
            <v-card width="100%" class="price-card-sty elevation-0 rounded-c-xl mb-md-6 overflow-hidden">
                <div class="pb-md-8 px-6 px-sm-8 pt-12">
                    <p class="mb-6 font-weight-bold secondary-infont fs-32 lh-32">Span calculator</p>
                    <v-row>
                        <v-col cols="12" md="5">
                            <p class="mb-0 title font-weight-regular">Symbol</p>
                            <v-autocomplete v-model="model" dense :items="items" :loading="isLoading"
                                :search-input.sync="search" clearable hide-details hide-selected item-text="tsym"
                                 item-value="token" prepend-inner-icon="mdi-magnify" append-icon=""
                                no-filter return-object auto-select-first oninput="this.value = this.value.toUpperCase()"
                                block flat solo background-color="#ffffff" color="#2A2A2A"
                                class="elevation-0 rounded-pill caption mb-6" label="Search contract">

                                <template v-slot:no-data>
                                    <v-col class="text-center mx-auto pa-6">
                                        <p class="font-weight-bold mb-2 black--text">
                                            {{
                                                nodata == null ? "Type more than 2 letter" : "No Contract Found" }} </p>
                                        <span class="body-2 mb-5 txt-666666">
                                            {{
                                                nodata == null ? "Eg. for Nifty Type: Nif" : "Try search for diff name"
                                            }}</span>
                                    </v-col>
                                </template>
                                <template v-slot:items="{ items }">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ items.tsym }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ items.exch }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>
                            </v-autocomplete>

                            <v-row no-glutters>
                                <v-col cols="6">
                                    <p class="mb-0 title font-weight-regular">Quantity</p>
                                    <v-text-field @keyup="qtyKeyup()" maxlength="9" :min="defqtys" dense type="number"
                                        v-model="qtys" :step="qtys" oninput="this.value=this.value.replace(/[^0-9]+/g, '')"
                                        hide-spin-buttons block flat solo background-color="#ffffff"
                                        class="elevation-0 rounded-pill" label="Enter quantity" hide-details>

                                        <template #prepend-inner>
                                            <div class="mr-1">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17"
                                                    viewBox="0 0 16 17" fill="none">
                                                    <g clip-path="url(#clip0_1116_9811)">
                                                        <path
                                                            d="M8 7.02304L14 3.68904L8.486 0.626041C8.33735 0.543381 8.17008 0.5 8 0.5C7.82992 0.5 7.66265 0.543381 7.514 0.626041L2 3.68904L8 7.02304Z"
                                                            fill="#666666" />
                                                        <path
                                                            d="M9 8.75488V16.0879L14.486 13.0399C14.6418 12.9532 14.7716 12.8265 14.862 12.6729C14.9524 12.5192 15 12.3441 15 12.1659V5.42188L9 8.75488Z"
                                                            fill="#666666" />
                                                        <path
                                                            d="M7 8.75488L1 5.42188V12.1659C0.999993 12.3441 1.04764 12.5192 1.13802 12.6729C1.22839 12.8265 1.3582 12.9532 1.514 13.0399L7 16.0879V8.75488Z"
                                                            fill="#666666" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_1116_9811">
                                                            <rect width="16" height="16" fill="white"
                                                                transform="translate(0 0.5)" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </template>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="6" >
                                    <p class="mb-0 title font-weight-regular">Transtype</p>
                                    <v-radio-group class="mt-1 mx-auto" row v-model="bayorsell">
                                        <v-radio color="#43A833" label="Buy" value="B" hide-details></v-radio>
                                        <v-radio color="#FF1717" label="Sell" value="S" hide-details></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-btn @click="resetScript" :disabled="getspandata.length == 0" block color="#2A2A2A"
                                        large outlined class="text-none elevation-0 rounded-pill brd-2"><span
                                            class="font-weight-bold fs-16">Reset</span></v-btn>
                                </v-col>
                                <v-col cols="6">
                                    <v-btn @click="addScript"
                                        :disabled="model == null ? true : nodata == 'allready' ? true : false" block large
                                        class="text-none elevation-0 rounded-pill" color="#2A2A2A">
                                        <span class="font-weight-bold white--text fs-16">+ Add</span></v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="7" class="pl-md-8">
                            <v-row no-glutters>
                                <v-col cols="12" class="pb-2">
                                    <p class="mb-3 title font-weight-regular">Combine margin requirements</p>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item class="pa-0">
                                                <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                                    color="#015FEC"></v-card>

                                                <v-list-item-content class="pa-0">
                                                    <v-list-item-subtitle
                                                        class="mb-2 fs-18 font-weight-regular">Span</v-list-item-subtitle>
                                                    <p class="mb-0 title font-weight-black">&#8377; {{ chartdata.span ?
                                                        chartdata.span : '0.00'
                                                    }}</p>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list-item-content>
                                        <v-list-item-content>
                                            <v-list-item class="pa-0">
                                                <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                                    color="#015FEC"></v-card>

                                                <v-list-item-content class="pa-0">
                                                    <v-list-item-subtitle
                                                        class="mb-2 fs-18 font-weight-regular">Exposure</v-list-item-subtitle>
                                                    <p class="mb-0 title font-weight-black">&#8377; {{ chartdata.expo ?
                                                        chartdata.expo : '0.00'
                                                    }}</p>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list-item-content>
                                        <v-list-item-content>
                                            <v-list-item class="pa-0">
                                                <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                                    color="#AAAAAA"></v-card>
                                                <v-list-item-content class="pa-0">
                                                    <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Total
                                                        margin</v-list-item-subtitle>
                                                    <p class="mb-0 title font-weight-black">&#8377; {{ chartdata.total ?
                                                        chartdata.total : '0.00'
                                                    }}</p>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="12" class="pt-3">
                                    <p class="title font-weight-regular">Margin benefits : <span
                                            class="font-weight-black"> &#8377; {{ alltoo?.toFixed(2) }}</span></p>

                                    <v-card v-if="getspandata.length > 1"
                                        class="elevation-0 rounded-lg d-inline-flex overflow-hidden" height="45px"
                                        width="100%" color="transparent">
                                     
                                  

                                        <v-card v-if="perc" class="elevation-0 rounded-r-0 rounded-l-lg d-inline-flex" height="45px"
                                            :width="`${perc}%`" color="transparent">
                                            <div v-for="n in 1000" :key="n" class="pad-x-2">
                                                <v-card class="elevation-0 rounded-lg d-inline-flex" height="45px"
                                                    width="10px" color="#015FEC">
                                                </v-card>
                                            </div>
                                        </v-card>

                                        <v-card  class="elevation-0 rounded-l-0 rounded-r-lg m-1" height="45px"
                                            :width="`${100 - perc}%`" color="#d9d9d9"></v-card>

                                    </v-card>
                                    <v-card v-else class="elevation-0 rounded-l-lg rounded-r-md d-inline-flex overflow-hidden"
                                        height="45px" width="100%" color="transparent">
                                        <div v-for="n in 1000" :key="n" class="pad-x-2">
                                            <v-card class="elevation-0 rounded-lg d-inline-flex" height="45px" width="10px"
                                                color="#d9d9d9">
                                            </v-card>
                                        </div>
                                    </v-card>
                                </v-col>

                            </v-row>

                        </v-col>
                    </v-row>
                    <v-card outlined class="rounded-lg elevation-0 pt-10" color="transparent">
                        <v-divider></v-divider>
                        <v-data-table disable-sort :headers="spantablehead" :items="getspandata"
                            :items-per-page="getspandata.length" class="elevation-0 pledge-table" hide-default-footer>
                            <template v-slot:[`item.action`]="{ item }">
                                <svg @click="deleteItem(item)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28"
                                    width="24" height="24">
                                    <path fill="currentColor" fill-rule="evenodd"
                                        d="M11.5 6a.5.5 0 0 0-.5.5V8h6V6.5a.5.5 0 0 0-.5-.5h-5zM18 8V6.5c0-.83-.67-1.5-1.5-1.5h-5c-.83 0-1.5.67-1.5 1.5V8H5.5a.5.5 0 0 0 0 1H7v12.5A2.5 2.5 0 0 0 9.5 24h9a2.5 2.5 0 0 0 2.5-2.5V9h1.5a.5.5 0 0 0 0-1H18zm2 1H8v12.5c0 .83.67 1.5 1.5 1.5h9c.83 0 1.5-.67 1.5-1.5V9zm-8.5 3c.28 0 .5.22.5.5v7a.5.5 0 0 1-1 0v-7c0-.28.22-.5.5-.5zm5.5.5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z">
                                    </path>
                                </svg>
                            </template>
                            <template v-slot:[`item.buysell`]="{ item }">
                                <v-chip class="ma-2" :color="item.buysell == 'B' ? '#ECF8F1' : '#FCF3F3'"
                                    :text-color="item.buysell == 'B' ? '#43A833' : '#FF1717'">
                                    <span class="font-weight-bold">{{ item.buysell == 'B' ? 'BUY' : 'SELL' }}</span>
                                </v-chip>
                            </template>
                            <template v-slot:no-data>
                                <v-col cols="12" class="text-center pa-16">
                                    <span>No contract</span>
                                </v-col>
                            </template>
                        </v-data-table>
                    </v-card>

                </div>
            </v-card>
        </div>
    </div>
</template>
  
<script>
// import { Chart, registerables } from 'chart.js';
// Chart.register(...registerables);
import axios from 'axios';
import { genuid, myntapi } from '../../apiurl';

export default {

    data: () => ({
        snackbar: false,
        snackbarclr: "default",
        snackmsgbar: "",

        isLoading: false,
        items: [],
        model: null,
        search: null,
        nodata: null,
        bayorsell: 'B',
        qtys: 0,
        defqtys: 0,


        getspandata: [],
        grpspan: [],
        chartdata: {},

        alltoo: 0.00,
        doughnuts: [1],
        doughnutcolor: ["#CCCCCC"],

        perc: 0,
    }),

    mounted() {
        // this.setChart();
    },

    methods: {
        addScript() {
            if (this.model != null) {
                var data = `jData={"uid":"${genuid.uid}","exch":"${this.model.exch}","token":"${this.model.token}"}&jKey=${genuid.token}`;
                var Quotesconfig = {
                    method: 'post',
                    url: `${myntapi}/GetQuotes`,
                    headers: {
                        'Content-Type': 'text/plain'
                    },
                    data: data
                };
                // console.log("GetQuotes data", data);

                let axiosThis = this;
                axios(Quotesconfig)
                    .then(function (response) {
                        // console.log("GetQuotes", response.data);
                        let GetQuotes = response.data;
                        let getspandata = null;
                        if (response.data.strprc) {
                            if (axiosThis.bayorsell == 'B') {
                                getspandata = ({ "prd": "M", "exch": `${response.data.exch}`, "tsym": `${response.data.tsym}`, "symname": `${response.data.symname}`, "instname": `${response.data.instname}`, "exd": `${response.data.exd}`, "netqty": `${axiosThis.qtys}`, "optt": `${response.data.optt}`, "strprc": `${response.data.strprc}` })
                            } else if (axiosThis.bayorsell == 'S') {
                                getspandata = ({ "prd": "M", "exch": `${response.data.exch}`, "tsym": `${response.data.tsym}`, "symname": `${response.data.symname}`, "instname": `${response.data.instname}`, "exd": `${response.data.exd}`, "netqty": `-${axiosThis.qtys}`, "optt": `${response.data.optt}`, "strprc": `${response.data.strprc}` })
                            }
                        } else {
                            if (axiosThis.bayorsell == 'B') {
                                getspandata = ({ "prd": "M", "exch": `${response.data.exch}`, "tsym": `${response.data.tsym}`, "symname": `${response.data.symname}`, "instname": `${response.data.instname}`, "exd": `${response.data.exd}`, "netqty": `${axiosThis.qtys}`, "optt": `${response.data.optt}` })
                            } else if (axiosThis.bayorsell == 'S') {
                                getspandata = ({ "prd": "M", "exch": `${response.data.exch}`, "tsym": `${response.data.tsym}`, "symname": `${response.data.symname}`, "instname": `${response.data.instname}`, "exd": `${response.data.exd}`, "netqty": `-${axiosThis.qtys}`, "optt": `${response.data.optt}` })
                            }
                        }
                        axiosThis.grpspan.push(getspandata)
                        let singlespan = JSON.stringify(getspandata)
                        if (singlespan) {
                            let data = `jData:{"actid":"${genuid.uid}", "pos":[${singlespan}]}`;
                            let config = {
                                method: 'post',
                                url: `${myntapi}/SpanCalc`,
                                headers: {
                                    'Content-Type': 'text/plain'
                                },
                                data: data
                            };
                            // console.log(data, "data");
                            axios.request(config)
                                .then((response) => {
                                    // console.log("SpanCalc", response.data);
                                    let getspan = response.data;
                                    if (response.data.stat == "Ok") {
                                        axiosThis.getspandata.push({ "exch": `${GetQuotes.exch}`, "tsym": `${GetQuotes.tsym}`, "buysell": `${axiosThis.bayorsell}`, "qty": `${axiosThis.qtys}`, "defqtys": `${axiosThis.defqtys}`, "expo": `${getspan.expo}`, "expo_trade": `${getspan.expo_trade}`, "span": `${getspan.span}`, "span_trade": `${getspan.span_trade}`, "total": Number((Number(getspan.span) + Number(getspan.expo))?.toFixed(2)) })
                                        axiosThis.chartSpan();
                                        axiosThis.model = null;
                                        axiosThis.qtys = 0;
                                        axiosThis.defqtys = 0;
                                        axiosThis.bayorsell = 'B';
                                    } else {
                                        axiosThis.snackbar = true;
                                        axiosThis.snackbarclr = "warning";
                                        axiosThis.snackmsgbar = response.data.emsg;
                                        axiosThis.qtys = axiosThis.defqtys;

                                    }
                                    // console.log("SpanCalc calc getspan", axiosThis.getspandata);
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        },
        chartSpan() {
            let grpin = JSON.stringify(this.grpspan)
            // console.log("SpanCalc sss", this.grpspan, grpin);
            if (this.getspandata.length > 0) {
                this.chartdata = {};
                let data = `jData:{"actid":"${genuid.uid}", "pos":${grpin}}`;
                let config = {
                    method: 'post',
                    url: `${myntapi}/SpanCalc`,
                    headers: {
                        'Content-Type': 'text/plain'
                    },
                    data: data
                };
                // console.log("data", data);
                let axiosThis = this;
                axios.request(config)
                    .then((response) => {
                        // console.log("SpanCalc grp", response.data);
                        if (response.data.stat == "Ok") {
                            let tolcalc = response.data
                            tolcalc['total'] = Number(response.data.span) + Number(response.data.expo)
                            axiosThis.chartdata = response.data;

                        } else {
                            axiosThis.snackbar = true;
                            axiosThis.snackbarclr = "warning";
                            axiosThis.snackmsgbar = response.data.emsg;
                        }
                        axiosThis.doughnutUpdate();
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                this.chartdata = [];
                this.alltoo = 0.00;
                this.doughnuts = [1];
                this.doughnutcolor = ["#CCCCCC"];
                // this.doughnut.destroy();
                this.setChart();
            }

        },
        doughnutUpdate() {
            // console.log("this.doughnutUpdate 2", this.doughnut);
            if ((this.getspandata.length > 1) && (Number(this.chartdata.expo) > 0) && (Number(this.chartdata.span) + Number(this.chartdata.expo) > 0)) {
                let sumof = this.getspandata.reduce((n, { total }) => n + total, 0)
                let alltol = Number(this.chartdata.span) + Number(this.chartdata.expo)
                this.alltoo = sumof - alltol;
                this.doughnuts = [this.alltoo, (Number(this.chartdata.span) + Number(this.chartdata.expo))];
                this.doughnutcolor = ["#015FEC", "#CCCCCC"];
            } else {
                this.alltoo = 0.00;
                this.doughnuts = [1];
                this.doughnutcolor = ["#CCCCCC"];
            }
            // this.doughnut.destroy();
            this.setChart();
        },
        setChart() {
            this.perc = Math.round((100 * this.doughnuts[0]) / (this.doughnuts[0] + this.doughnuts[1]))
            // const setdoughnut = document.getElementById("myChart").getContext("2d");
            // this.doughnut = new Chart(setdoughnut, {
            //     type: 'doughnut',
            //     data: {
            //         datasets: [{
            //             data: this.doughnuts,
            //             backgroundColor: this.doughnutcolor,
            //             radius: "100%",
            //             borderColor: this.doughnutcolor,
            //             // data: [4100, 2500, 1800],
            //             // backgroundColor: ['#015FEC', '#AAAAAA', '#1D1D1D',],
            //             // borderColor: ['#015FEC', '#AAAAAA', '#1D1D1D'],
            //             // hoverBorderColor: 'transparent',
            //             borderRadius: 8,
            //             borderWidth: 0,
            //             hoverOffset: 4,
            //             borderAlign: 'center'
            //         }]
            //     },

            //     options: {
            //         cutout: "70%",
            //         rotation: -90,
            //         circumference: 180,
            //         events: [],
            //         title: {
            //             // display: false,
            //         },
            //         legend: {
            //             // display: false,
            //         },
            //         layout: {
            //             padding: {
            //                 top: 0,
            //                 left: 0,
            //                 right: 0,
            //                 bottom: 0,
            // }
            // },
            // }
            // });
        },
        deleteItem(item) {
            let delset = this.getspandata.indexOf(item)
            this.getspandata.splice(delset, 1)
            let chartupdate = [item.tsym]
            let mardata = this.grpspan.filter(item => !chartupdate.includes(item.tsym));
            this.grpspan = [];
            this.grpspan = mardata;
            let axiosThis = this;
            axiosThis.chartSpan();
        },
        resetScript() {
            this.model = null;
            this.qtys = 0;
            this.defqtys = 0;

            this.bayorsell = 'B';
            this.grpspan = [];
            this.getspandata = [];
            this.chartdata = {};
            this.doughnutUpdate();
        },
        refeshData() {
            if (this.getspandata.length > 0) {
                this.chartdata = {};
                this.chartSpan();
            }
        },
        qtyKeyup() {
            setTimeout(() => {
                this.qtys = Math.ceil(this.qtys / this.defqtys) * this.defqtys;
            }, 1600);
        }
    },

    watch: {
        search(val) {
            let checkval = null;
            checkval = this.getspandata.filter((element) => element.tsym === val)
            if (val && val.length > 2 && checkval.length == 0) {
                this.isLoading = true
                let data = `jData={"uid":"${genuid.uid}","stext":"${val}"}&jKey=${genuid.token}`;
                let config = {
                    method: 'post',
                    url: `${myntapi}/SearchScrip`,
                    headers: {
                        'Content-Type': 'text/plain'
                    },
                    data: data
                };

                let axiosThis = this;
                axios.request(config)
                    .then((response) => {
                        // console.log("SearchScrip", response.data);
                        axiosThis.nodata = null;
                        if (response.data.stat == "Ok" || response.data.values) {
                            axiosThis.items = [];
                            for (const element of response.data.values) {
                                if (element.exch == "NFO" || element.exch == "MCX" || element.exch == "CDS") {
                                    if (element.instname !== "COM") {
                                        axiosThis.items.push({ "exch": element.exch, "instname": element.instname, "ls": element.ls, "optt": element.optt, "pp": element.pp, "ti": element.ti, "token": element.token, "tsym": element.tsym });
                                    }
                                }
                            }
                            axiosThis.isLoading = false
                            if (axiosThis.model) {
                                axiosThis.qtys = Number(axiosThis.model.ls);
                                axiosThis.defqtys = Number(axiosThis.model.ls);

                            }

                        } else if (response.data.emsg == "No Data :   ") {
                            axiosThis.items = [];
                            axiosThis.qtys = 0;
                            axiosThis.defqtys = 0;

                            axiosThis.isLoading = false;
                            axiosThis.nodata = 'noooo';
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        if (error) {
                            axiosThis.items = [];
                            axiosThis.qtys = 0;
                            axiosThis.defqtys = 0;

                            axiosThis.isLoading = false;
                            axiosThis.nodata = 'noooo';
                        }
                    });
            } else {
                if (checkval.length != 0) {
                    this.snackbar = true;
                    this.snackbarclr = "warning";
                    this.snackmsgbar = "You can't add the same script in contract.";
                    this.nodata = 'allready'
                } else {
                    this.nodata = 'noooo';
                }
                this.qtys = 0;
                this.defqtys = 0;

                this.model = null;
                this.items = [];
            }
        },
    },

    computed: {
        spantablehead() {
            return [
                { text: 'Exch', value: "exch" },
                { text: 'Symbol', value: "tsym" },
                { text: 'Buy/Sell', value: "buysell" },
                { text: 'Qty', value: 'qty', align: 'right' },
                { text: 'Span margin', value: 'span', align: 'right' },
                { text: 'Exposure', value: 'expo', align: 'right' },
                { text: 'Total', value: 'total', align: 'right' },
                { text: '', value: "action" },
            ]
        }
    },
}
</script>