<template>
    <div class="px-0 px-md-14">
        <div>
            <v-card width="100%" class="price-card-sty elevation-0 rounded-c-xl mb-md-6 overflow-hidden">
                <div class="pb-md-8 px-6 px-sm-8 pt-12">
                    <p class="mb-6 font-weight-bold secondary-infont fs-32 lh-32">GST calculator</p>
                    <v-row no-gutters>
                        <v-col cols="12" md="6" class="pr-md-16">
                            <p class="mb-0 title font-weight-regular">Total amount</p>
                            <v-text-field type="number" @keyup="atChange()" hide-spin-buttons dense
                                v-model="Principleammount" block flat solo background-color="#ffffff" min="1"
                                class="calc-field elevation-0 rounded-pill mb-6" prefix="₹" hide-details>
                            </v-text-field>

                            <v-row class="my-6">
                                <v-col cols="9">
                                    <p class="mb-0 title font-weight-regular">Tax slab</p>
                                </v-col>
                                <v-col cols="3" class="pt-2">
                                    <v-text-field block flat solo background-color="#ffffff"
                                        class="calc-field elevation-0 rounded-pill" hide-details dense @keyup="atChange()"
                                        type="number" suffix="Yr" v-model="InterestRate" hide-spin-buttons min="1" max="50"
                                        step="1"></v-text-field>
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    <v-slider @change="atChange()" v-model="InterestRate" ticks="always" tick-size="6"
                                        min="1" max="20" step="1" class='rounded-pill slider-range' track-color="#EEEEEE"
                                        thumb-color="#015FEC" hide-details color='#A3C8FF'></v-slider>

                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="6">
                            <p class="title font-weight-regular">Estimation</p>

                            <v-row>
                                <v-col cols="6">
                                    <p class="title font-weight-regular">GST Inclusive</p>
                                    <v-list-item class="pa-0 mb-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#015FEC"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Total GST
                                            </v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emiammount ?
                                                emiammount : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="pa-0 mb-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#1D1D1D"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Pre-GST
                                                amount</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emiinterest ?
                                                emiinterest : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="6">
                                    <p class="title font-weight-regular">GST exclusive</p>
                                    <v-list-item class="pa-0 mb-8">
                                     
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Total GST
                                            </v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emiammountt ?
                                                emiammountt : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="pa-0 mb-8">
                                       
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Pre-GST
                                                amount</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emiinterestt ?
                                                emiinterestt : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
            <v-card width="100%" class="elevation-0 rounded-c-xl overflow-hidden" outlined>
                <div class="px-6 px-sm-8 px-md-10 py-6">
                    <p class="mb-0 font-weight-bold title">What is meant by GST?</p>
                    <p class="mb-6 font-weight-light txt-444">The Goods and Services Tax, or GST, is the indirect tax
                        imposed on the provision of goods and services. On July 1, 2017, the Goods and Services Tax (GST)
                        was implemented as a single taxation system in India, replacing all indirect taxes. In the 2017
                        Budget Session, the Central Government passed the GST Act, which was adopted by the Parliament on
                        March 29, 2017. Among the indirect taxes that were eliminated were the Central Excise Duty, the
                        Value-Added Tax, the Entry Tax, and the Octroi.
                        <br><br>
                        The Products and Services Tax (GST) is a comprehensive tax levied on the production, sale, and
                        consumption of goods and services in the country. Under GST regulation, various small and huge
                        enterprises are required to get a GST Identification Number in order to register. The Integrated
                        Goods and Services Tax (GST) is levied on all intrastate sales. And any intrastate sales are subject
                        to both the Central and State GST.
                        <br><br>
                        Various types of GST collected by the government include:
                        <br><br>
                        SGST - State GST >
                        CGST - Central GST>
                        Integrated GST (IGST): The Central Government collects this tax on interstate transactions and
                        imports.>
                        Union Territory Government collects Union Territory GST (UTGST).
                    </p>
                    <p class="mb-0 font-weight-bold title">What is the GST calculator?</p>
                    <p class="mb-6 font-weight-light txt-444">We, at Zebu, provide taxpayers with a specialised and
                        expert GST Calculator that facilitates the calculation of GST. Utilizing our tool, taxpayers can
                        calculate GST using the differential GST rate.
                        <br><br>
                        Step 1: Select GST Inclusive or GST Exclusive based on your needs.
                        Step 2: Enter the amount originally paid
                        Select the GST rate from the drop-down menu in Step 3.
                        <br><br>
                        Click the Calculate button to view the outcome. The result will display the overall GST amount as
                        well as the Pre-GST/Post-GST amount according to your initial request.
                    </p>
                    <p class="mb-0 font-weight-bold title">How do I calculate GST amount?</p>
                    <p class="mb-6 font-weight-light txt-444">With the unified system of taxation, taxpayers can now
                        determine the tax levied at multiple stages for different goods and services under the GST regime.
                        For calculating GST, the taxpayer must be aware of the applicable GST rate for each category. The
                        various GST rates are 5 percent, 12 percent, 18 percent, and 28 percent.
                        <br><br>
                        If a product or service is sold for Rs. 1,000 and the applicable GST rate is 18 percent, then the
                        net price will be = Rs. 1,000 plus (1,000 x (18/100)) = Rs. 1,000 plus Rs. 180, or Rs. 1,180.
                        <br><br>
                        GST Calculation Formula
                        For calculating GST, a taxpayer can use the formula below:
                        <br><br>
                        To add GST to the base amount,
                        <br><br>
                        Add GST<br>
                        GST Amount = (Original Cost * GST %)/100 Net Price = Original Cost plus GST Amount
                        <br><br>
                        To eliminate GST from the basic amount,
                        <br><br>
                        Remove GST<br>
                        GST Amount = Original Price - (Original Price * (100 / (100 + GST %)))<br>
                        Net Price = Original Price - Amount of GST
                    </p>
                    <p class="mb-0 font-weight-bold title">Benefits of GST calculator</p>
                    <p class="mb-6 font-weight-light txt-444">The easy-to-use GST calculator enables you to calculate the
                        price of a gross or net product based on the amount and provides a breakdown of percentage-based GST
                        rates. It finds the accurate division of CGST and SGST rates or calculation of IGST. </p>
                </div>
            </v-card>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            Principleammount: 10000,
            InterestRate: 10,

            emiammount: 0,
            emiinterest: 0,
            emiammountt: 0,
            emiinterestt: 0,
        }
    },

    mounted() {
        this.compCalculator();
    },

    methods: {
        atChange() {
            setTimeout(() => {
                this.compCalculator();
            }, 600);
        },
        compCalculator() {
            var tamount = parseFloat(this.Principleammount);
            var tax = parseFloat(this.InterestRate);

            var gst = Math.round(tamount - (tamount * (100 / (100 + tax))))
            var pregst = Math.round(tamount - gst)
            // console.log(gst, "GST")

            var gstex = Math.round((tamount * (tax / 100)) + tamount)
            var postgst = Math.round(gstex - tamount)
            var total = parseFloat(gst) + parseFloat(pregst) + parseFloat(postgst)

            this.emiammount = gst.toLocaleString();
            this.emiinterest = pregst.toLocaleString();

            this.emiammountt = postgst.toLocaleString();
            this.emiinterestt = total.toLocaleString();
        },
    }


}
</script>