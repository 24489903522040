// import axios from 'axios';
import { accapi, myntapi, myntltp, bemynt, genuid } from '../../apiurl.js'
let myHeaders = new Headers();
var requestOptions = {
    method: 'POST',
    redirect: 'follow',
    headers: myHeaders
};
myHeaders.append("Content-Type", "application/json");
// Auth
export async function getToken() {
    var response = await fetchMyntAPI('https://sess.mynt.in/getoken', requestOptions)
    return response
}

export async function getQuotesdata(item, usdata) {
    requestOptions['body'] = `jData={"uid":"${usdata.uid}","exch":"${item.split('|')[0]}","token":"${item.split('|')[1]}"}&jKey=${usdata.token}`
    var response = await fetchMyntAPI(myntapi + "GetQuotes", requestOptions)
    return response
}

export async function getSecuritydata(item) {
    requestOptions['body'] = `jData={"uid":"${genuid.uid}","exch":"${item.split('|')[0]}","token":"${item.split('|')[1]}"}&jKey=${genuid.token}`
    var response = await fetchMyntAPI(myntapi + "GetSecurityInfo", requestOptions)
    return response
}


export async function getLtpdata(item) {
    requestOptions['body'] = `{ "data": ${JSON.stringify(item)} }`
    var response = await fetchMyntAPI(myntltp + "GetLtp", requestOptions)
    return response
}

// single stocks accod API
export async function getssDetails(item) {
    requestOptions['body'] = `{"symbol":"${item}"}`
    var response = await fetchMyntAPI(accapi + "stockFundamentalDetails", requestOptions)
    return response
}
export async function getFundamental(item) {
    requestOptions['body'] = `{"symbol":"${item}"}`
    var response = await fetchMyntAPI(accapi + "stockFundamental", requestOptions)
    return response
}
export async function getSSEvents(item) {
    requestOptions['body'] = `{"symbol":"${item}"}`
    var response = await fetchMyntAPI(accapi + "stockEvents", requestOptions)
    return response
}
export async function getSSHoldings(item) {
    requestOptions['body'] = `{"symbol":"${item}"}`
    var response = await fetchMyntAPI(accapi + "stockHoldings", requestOptions)
    return response
}
export async function getConsolidated(item) {
    requestOptions['body'] = `{"symbol":"${item}"}`
    var response = await fetchMyntAPI(accapi + "stockFinancialsConsolidated", requestOptions)
    return response
}
export async function getStandalone(item) {
    requestOptions['body'] = `{"symbol":"${item}"}`
    var response = await fetchMyntAPI(accapi + "stockFinancialsStandalone", requestOptions)
    return response
}
export async function getPeers(item) {
    requestOptions['body'] = `{"symbol":"${item}"}`
    var response = await fetchMyntAPI(accapi + "getStockPeers", requestOptions)
    return response
}
export async function getMFHoldings(item) {
    requestOptions['body'] = `{"symbol":"${item}"}`
    var response = await fetchMyntAPI(accapi + "stockMFHoldings", requestOptions)
    return response
}
export async function getReturns(item) {
    requestOptions['body'] = `{"symbol":"${item}"}`
    var response = await fetchMyntAPI(accapi + "stockReturns", requestOptions)
    return response
}
export async function getQuotedata(item) {
    requestOptions['body'] = `{"symbol":"${item}"}`
    var response = await fetchMyntAPI(accapi + "getQuotes", requestOptions)
    return response
}

export async function getMasters() {
    requestOptions['body'] = `{"exc":["NSE"]}`
    var response = await fetchMyntAPI(bemynt + "Masters", requestOptions)
    return response
}

export async function fetchMyntAPI(path, requestOptions) {
    try {
        const response = await fetch(path, requestOptions);
        let data = await response.json();
        data = JSON.stringify(data);
        data = JSON.parse(data);
        // if(data.emsg && data.emsg.includes('Session Expired')) {
        //     // location.href = '/'
        //     // alert(data.emsg)
        // } 
        return data
    } catch (error) {
        let data = 500
        return data
        // throw new Error(error.status);
    }
}