<template>
    <div>
        <div data-aos="fade-up" class="pt-md-4">
            <v-card class="elevation-0 px-16 py-10 support-main-bg rounded-xl pos-rlt d-none d-md-block">
                <p class="white--text fs-50 font-weight-bold mb-6">CSR Center</p>
                <p class="white--text headline">Social responsibility is the fabric with <br> which Zebu functions. We pride
                    ourselves <br> at giving back to the community.
                </p>

                <div class="pos-abs csr-img text-right">
                    <img alt="main-bg.png" src="@/assets/csr/main-bg.png" width="130%" />
                </div>
            </v-card>
            <v-card class="elevation-0 px-6 px-sm-8 py-10 support-main-bg rounded-0 d-md-none text-center">
                <p class="white--text fs-38 lh-38 font-weight-bold mb-6">CSR Center</p>
                <p class="white--text fs-18">Social responsibility is the fabric with <br> which Zebu functions. We pride
                    ourselves <br> at giving back to the community.
                </p>
            </v-card>
        </div>
        <div class="pa-6 px-6 px-sm-8 py-md-16 mb-md-6 pl-md-16 no-xhide">
            <v-row>
                <v-col cols="12" md="8">
                    <p class="fs-18 font-weight-regular">CSR ACTIVITIES</p>
                    <v-card v-for="(i, n) in  activities" :key="n" data-aos="flip-up" width="100%"
                        class="box-s1 py-4 px-4 px-md-6 sec-two-sty1 rounded-xl mb-4">
                        <v-row>
                            <v-col cols="12" md="4">
                                <img :alt="i.icon" :src="i.icon" width="100%" />
                            </v-col>
                            <v-col cols="12" md="6" class="pa-0 pa-md-3">
                                <v-list-item class="px-md-0">
                                    <v-list-item-content>
                                        <v-list-item-title class="font-weight-bold title mb-2">{{ i.title
                                        }}</v-list-item-title>
                                        <!-- <p class="font-weight-light text-rap-l3 mb-0 mb-md-3">{{ i.subtitle }}</p> -->
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col cols="12" md="2" class="pl-md-0">
                                <p class="font-weight-bold title text-none txt-fobly mt-md-3">
                                    Case Study</p>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col cols="12" md="4">
                    <p class="fs-18 font-weight-regular">REPORT CENTER</p>
                    <v-card data-aos="flip-up" width="100%" class="box-s1 py-4 px-4 px-md-6 sec-two-sty1 rounded-xl mb-4"
                        v-for="( r, k ) in  Reports " :key="k">
                        <p class="font-weight-bold title mb-0">{{ r.title }}</p>
                        <!-- <p class="font-weight-light">{{ r.subtitle }}</p> -->

                        <p class="mb-0 font-weight-bold text-none txt-fobly">{{ r.ourDetailes }} <span class="float-right">
                                <a target="_blank" rel="noopener noreferrer" :href="r.href" >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                                        fill="none">
                                        <path opacity="0.12" d="M2.5 17.5H17.5V12.5H2.5V17.5Z" fill="#6941C6" />
                                        <path
                                            d="M17.5 12.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V12.5M14.1667 8.33333L10 12.5M10 12.5L5.83333 8.33333M10 12.5V2.5"
                                            stroke="#0037B7" stroke-width="2.33" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </svg></a>
                            </span></p>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
export default {

    data: () => ({
        activities: [
            {
                icon: require('@/assets/csr/1.png'),
                title: "Setting up rural artisan enclave in Trichy.",
                subtitle: "Financial document given to a participant in a transaction explaining key information in plain language",
            },
            {
                icon: require('@/assets/csr/2.png'),
                title: "Revitalizing a lost art in the lands of the chera",
                subtitle: "Financial document given to a participant in a transaction explaining key information in plain language",
            },
            {
                icon: require('@/assets/csr/3.png'),
                title: "Setting up rural artisan enclave in Trichy.",
                subtitle: "Financial document given to a participant in a transaction explaining key information in plain language",
            },
            {
                icon: require('@/assets/csr/4.png'),
                title: "Revitalizing a lost art in the lands of the chera",
                subtitle: "Financial document given to a participant in a transaction explaining key information in plain language",
                CaseStudy: "CaseStudy",
            },
        ],

        Reports: [
            {
                title: "CSR Policy",
                subtitle: "Financial document given to a participant in a transaction explaining.",
                ourDetailes: "Our CSR policy",
                href: 'https://zebu.mynt.in/static/zebu/reports/CSR-POLICY.pdf',
            },
            {
                title: "CSR Committee",
                subtitle: "Financial document given to a participant in a transaction explaining.",
                ourDetailes: "Committee details",
                href: 'https://zebu.mynt.in/static/zebu/reports/CSR-COMMITTEE.pdf',
            },
            {
                title: "CSR Reports",
                subtitle: "Financial document given to a participant in a transaction explaining.",
                ourDetailes: "Our CSR report",
                href: 'https://zebu.mynt.in/static/zebu/reports/CSR-REPORT.pdf',
            },
        ]
    }),
}
</script>