<script>
export default {
    created() {
        if ((/iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent))) {
            window.location.href = 'https://testflight.apple.com/join/uvQJ0bnV'
        } else {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.mynt.trading_app_zebu'
        } 
        // if (/Android/i.test(navigator.userAgent))
    }
}
</script>