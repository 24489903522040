<template>
    <div class="px-0 px-md-14">
        <div>
            <v-card width="100%" class="price-card-sty elevation-0 rounded-c-xl mb-md-6 overflow-hidden">
                <div class="pb-md-8 px-6 px-sm-8 pt-12">
                    <p class="mb-6 font-weight-bold secondary-infont fs-32 lh-32">Car loan calculator</p>
                    <v-row no-gutters>
                        <v-col cols="12" md="6" class="pr-md-16">
                            <p class="mb-0 title font-weight-regular">Loan amount</p>
                            <v-text-field type="number" @keyup="keyUpchartupdation()" hide-spin-buttons dense
                                v-model="Principleammounts" block flat solo background-color="#ffffff" min="1"
                                class="calc-field elevation-0 rounded-pill mb-6" prefix="₹" hide-details>
                            </v-text-field>

                            <v-row class="mt-6">
                                <v-col cols="9">
                                    <p class="mb-0 title font-weight-regular">Interest Rate (p.a.)</p>
                                </v-col>
                                <v-col cols="3" class="pt-2">
                                    <v-text-field block flat solo background-color="#ffffff"
                                        class="calc-field elevation-0 rounded-pill" hide-details dense
                                        @keyup="keyUpchartupdation()" type="number" suffix="%" v-model="InterestRate"
                                        hide-spin-buttons min="1" max="20" step="1"></v-text-field>
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    <v-slider @change="chartupdation()" v-model="InterestRate" ticks="always" tick-size="6"
                                        min="1" max="20" step="1" class='rounded-pill slider-range' track-color="#EEEEEE"
                                        thumb-color="#015FEC" hide-details color='#A3C8FF'></v-slider>

                                </v-col>
                            </v-row>

                            <v-row class="mt-6">
                                <v-col cols="9">
                                    <p class="mb-0 title font-weight-regular">Tenure period</p>
                                </v-col>
                                <v-col cols="3" class="pt-2">
                                    <v-text-field block flat solo background-color="#ffffff"
                                        class="calc-field elevation-0 rounded-pill" hide-details dense
                                        @keyup="keyUpchartupdation()" type="number" suffix="Yr" v-model="TenurePeriodYear"
                                        hide-spin-buttons min="1" max="50" step="1"></v-text-field>
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    <v-slider @change="chartupdation()" v-model="TenurePeriodYear" ticks="always"
                                        tick-size="6" min="1" max="50" step="1" class='rounded-pill slider-range'
                                        track-color="#EEEEEE" thumb-color="#015FEC" hide-details color='#A3C8FF'></v-slider>

                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="6">
                            <p class="mb-0 title font-weight-regular mt-6 mt-md-0">Estimation</p>
                            <v-row>
                                <v-col cols="12" md="7" class="py-4 px-8 px-md-4 mt-6">
                                    <v-card class="elevation-0 rounded-circle pb-2 px-1 brd-c-4" outlined>
                                        <canvas id="myChart"> </canvas>
                                    </v-card>

                                </v-col>
                                <v-col cols="12" md="5">
                                    <v-list-item class="pa-0 mb-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#AAAAAA"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Monthly
                                                EMI</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emicalreport ?
                                                emicalreport : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="pa-0 mb-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#015FEC"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Loan
                                                Amount</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emicalemiammount ?
                                                emicalemiammount : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="pa-0 mb-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#1D1D1D"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Total
                                                Interest</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emiinterest ?
                                                emiinterest : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="pa-0 mb-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#6EB94B"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Total
                                                Payment</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emitotal ?
                                                emitotal : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
            <v-card width="100%" class="elevation-0 rounded-c-xl overflow-hidden" outlined>
                <div class="px-6 px-sm-8 px-md-10 py-6">
                    <p class="mb-0 font-weight-bold title">What is the Car loan EMI calculator?</p>

                    <p class="mb-6 font-weight-light txt-444">Whether you work for a company or for yourself, you can buy
                        the car of your dreams today. Unlike a few decades ago, you don't have to be rich or save up a lot
                        of money to buy your first car. You can just get a new Car Loan and get behind the wheel of your
                        dream car sooner.
                        <br><br>

                        You can get a pre-approved car loan, but it depends on your income and credit score. There are also
                        limits on how long the loan can last and how much you can borrow.
                        <br><br>

                        For people who want to buy a new car, banks offer Car Loans with an attractive interest rate, a low
                        processing fee, a repayment period of up to 7 years, and a higher loan-to-value ratio (100 percent
                        on-road price funding on certain models). Even sole proprietorships, partnerships, companies,
                        trusts, and societies can get a Car Loan.
                    </p>
                    <p class="mb-0 font-weight-bold title">How is car EMI calculated?</p>
                    <p class="mb-6 font-weight-light txt-444">EMI (Equated Monthly Instalment) is a way to make it easier
                        to pay back your loan.
                        <br><br>

                        With the following math formula, you can figure out how much your car loan payment will be: EMI
                        Amount = [P x R x (1+R)N]/[(1+R)N-1],
                        <br><br>

                        This also means that if you change any of the three variables, the EMI value will change. Let's talk
                        in depth about these three factors.
                        <br><br>

                        "P" stands for the amount you owe. The interest will be figured out based on the original loan
                        amount that the bank gave you.
                        <br><br>

                        "R" stands for the interest rate that the bank decides on.
                        <br><br>

                        N is the number of years the loan has to be paid back. Since you have to pay the EMIs every month,
                        the number of months is used to figure out how long it will take.
                    </p>
                </div>
            </v-card>
        </div>
    </div>
</template>

<script>

import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default {
    data() {
        return {
            Principleammounts: 200000,
            InterestRate: 8,
            TenurePeriodYear: 2,
            emicalreport: 0,
            emicalemiammount: 0,
            emiintrestdisplay: 0,
            emitotal: 0,
            emiinterest: 0,

            doughnuts: [],
        }
    },

    mounted() {
        this.carCalculator();
        this.setChart();
    },

    methods: {
        keyUpchartupdation() {
            setTimeout(() => {
                this.carCalculator();
                this.doughnut.destroy();
                this.setChart();
            }, 600);
        },
        chartupdation() {
            this.carCalculator();
            setTimeout(() => {
                this.doughnut.destroy();
                this.setChart();
            }, 600);
        },
        carCalculator() {
            var Principleammounts = this.Principleammounts;
            this.emicalemiammount = parseInt(Principleammounts).toLocaleString()
            var InterestRate = this.InterestRate / (100 * 12);

            var TenurePeriodYear = this.TenurePeriodYear * 12;
            // console.log("count", Principleammounts, InterestRate, TenurePeriodYear)

            if ((Principleammounts > 0 && InterestRate > 0 && (TenurePeriodYear > 0)) && (Principleammounts <= Math.pow(10, 9) && InterestRate <= 50 / (100 * 12) && (TenurePeriodYear <= 50 * 12))) {
                var emical = Math.round(([(parseFloat(Principleammounts) * parseFloat(InterestRate) * Math.pow(1 + parseFloat(InterestRate), parseFloat(TenurePeriodYear)))] / [(Math.pow((1 + parseFloat(InterestRate)), (parseFloat(TenurePeriodYear))) - 1)]))
                this.emicalreport = (emical.toLocaleString())
                // document.getElementById('#emireport').innerHTML=""
                // ("#emireport").html( emical.toLocaleString());
                // ("#emiammount").html(Principleammounts.toLocaleString());
                //  document.getElementById('emireport').innerHTML=emireport;
                // console.log("emical", emical)
                var emiintrest = (parseFloat(emical) * parseFloat(TenurePeriodYear)) - parseFloat(Principleammounts);
                this.emiintrestdisplay = parseInt(emiintrest.toLocaleString())
                var emitotal = parseInt(Principleammounts) + parseInt(emiintrest);
                this.emitotal = emitotal.toLocaleString();
                this.emiinterest = emiintrest.toLocaleString();

            }
            else {
                this.emicalreport = 0;
                this.emiintrestdisplay = 0;
                this.emitotal = 0;
                this.emiinterest = 0;
            }
            this.doughnuts = [emitotal, emiintrest];

        },
        setChart() {
            const setdoughnut = document.getElementById("myChart").getContext("2d");
            this.doughnut = new Chart(setdoughnut, {
                type: 'doughnut',
                data: {
                    datasets: [{
                        data: this.doughnuts,
                        backgroundColor: ['#015FEC', '#1D1D1D'],
                        radius: "100%",
                        borderColor: ['#015FEC', '#1D1D1D'],
                        borderRadius: 0,
                        borderWidth: 0,
                        hoverOffset: 4,
                        borderAlign: 'center'
                    }]
                },

                options: {
                    cutout: "70%",
                    // rotation: -90,
                    // circumference: 180,
                    events: [],
                    title: {
                        // display: false,
                    },
                    legend: {
                        // display: false,
                    },
                    layout: {
                        padding: {
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                        }
                    },
                }
            });
        },
    }


}
</script>