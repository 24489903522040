<template>
  <v-app>
    <!-- <v-main> -->
    <div>
      <router-view />
    </div>
    <!-- </v-main> -->
  </v-app>
</template>

<script>

export default {
  name: 'App',


  data: () => ({
    //
  }),
};
</script>

<!-- <template>
  <v-container>
  </v-container>
</template>

<script>
  export default {

    data: () => ({
     
    }),
  }
</script> -->
