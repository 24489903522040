<template>
    <div class="no-xhide">
        <div data-aos="fade-up">
            <v-card class="elevation-0 px-16 py-10 support-main-bg rounded-xl d-none d-md-block">
                <v-row no-glutters>
                    <v-col cols="6">
                        <p class="white--text fs-50 font-weight-bold mb-6">Privacy Policy</p>
                        <p class="white--text headline">We value privacy highly at Zebu <br> and our business follows all
                            good <br> practices.
                        </p>

                    </v-col>
                    <v-col cols="6">
                        <v-container fill-height class="d-flex">
                            <v-card class="elevation-0 ml-auto my-auto px-12 py-8 c-brd-3 rounded-xl d-inline-flex white--text">
                                <span class="secondary-font mb-0 fs-60 mt-3">12</span>

                                <span class="font-weight-bold fs-20 ml-2">privacy <br>
                                    markers </span>

                                <span class="font-weight-light fs-16 ml-6 mt-2">for a safe wealth <br> creation with
                                    us.</span>
                            </v-card>
                        </v-container>

                    </v-col>
                </v-row>
            </v-card>
            <v-card class="elevation-0 px-6 px-sm-8 py-10 support-main-bg rounded-0 d-md-none">
                <v-row no-glutters>
                    <v-col cols="12" class="text-center">
                        <p class="white--text fs-38 font-weight-bold mb-6">Privacy Policy</p>
                        <p class="white--text fs-18 headline">We value privacy highly at Zebu and our business follows all
                            good practices.
                        </p>
                    </v-col>
                    <v-col cols="12" class="text-center">
                            <v-card class="elevation-0 my-auto px-6 px-sm-8 py-8 c-brd-3 rounded-xl d-inline-flex white--text">
                                <span class="secondary-font mb-0 fs-60 mt-3">12</span>
                                <span class="font-weight-bold fs-20 ml-2">privacy <br>
                                    markers </span>
                                <span class="font-weight-light fs-16 ml-6 mt-2">for a safe wealth <br> creation with
                                    us.</span>
                            </v-card>
                    </v-col>
                </v-row>
            </v-card>
        </div>
        <div class="px-6 px-sm-8 py-8 pa-16 mb-md-6 mx-md-16">
            <div class="px-md-16">
                <p class="font-weight-bold title">All personal information gathered and stored through this site shall be
                    governed by the terms laid out by Zebu.</p>
                <p class="mb-0 font-weight-light lh-28">
                <ul>
                    <li>This website captures personal information about the proposed user while registering with Zebu to
                        opening an account for online trading and accounts access.</li>
                    <li>The information will be used to promptly identify and attend to you personally based on your
                        specific requests.</li>
                    <li>On successful registration, you are eligible to log in and access the facilities on the site.
                    </li>
                </ul>
                </p>
                <br>
                <p class="font-weight-bold title">Disclosure of information</p>
                <p class="mb-0 font-weight-light lh-28">
                <ul>
                    <li>Zebu will keep your personal information confidential.</li>
                    <li>Zebu shall not sell or rent the information to anyone.</li>
                    <li>In the case of compulsion to share your information, we shall obtain your prior consent; the
                        information shall be shared with only those people/companies who have been authorized by you.
                    </li>
                </ul>
                </p>
                <br>
                <p class="font-weight-bold title">Security</p>
                <p class="mb-0 font-weight-light lh-24">Web policy mandates the use of a password. Hence, your account
                    information is safe and protected.</p>
                <br>
                <p class="font-weight-bold title">Amendment rights</p>
                <p class="mb-0 font-weight-light lh-24">Zebu reserves the right to change the contents of the privacy policy
                    from time to time. Any predominant change in the manner of use of personal information shall be notified
                    on this site. We welcome your suggestions and queries on the same.</p>
                <br>
            </div>
        </div>
    </div>
</template>

<script>
export default {

    data: () => ({
    }),
}
</script>