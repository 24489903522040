<template>
  <div>

    <div id="top" class="pos-rlt d-none d-md-block" data-aos="fade-up">
      <!-- <img class="hero-pg-img" src="@/assets/hero-pg-bg1.svg" width="100%" alt="hero-pg-bg"> -->

      <ul id="app-cards">
        <li class="app-card">
          <v-card class="elevation-0" color="transparent">
            <img class="hero-pg-img" src="@/assets/hero-pg-bg.svg" width="100%" alt="hero-pg-bg">
            <div class="hero-pg-ctn pos-abs">
              <div class="d-inline-flex mb-8">
                <v-card v-if="ltpraw['n'] && ltpraw['n'].lp && Number(ltpraw['n'].lp) > 0"
                  class="home-menu-card elevation-0 rounded-xl mr-4" width="260px">
                  <v-list-item>
                    <v-list-item-avatar size="48" color="#1F226B">
                      <!-- <img src="@/assets/nifty-50.svg"> -->
                      <span class="white--text">N50</span>
                    </v-list-item-avatar>
                    <v-list-item-content class="text-left">
                      <v-list-item-title class="font-weight-bold white--text fs-17">Nifty 50</v-list-item-title>
                      <v-list-item-subtitle class="font-weight-regular white--text fs-14"
                        :class="Number(ltpraw['n'].lp) > 0 ? '' : 'blink-txt'"><span class="fs-12 font-weight-thin">₹
                        </span>{{ Number(ltpraw['n'].lp) > 0 ?
                          Number(ltpraw['n'].lp).toLocaleString() : '0.00' }}
                        <span class="fs-10"
                          :class="ltpraw['n'].ch > 0 ? 'txt-gre-s' : ltpraw['n'].ch < 0 ? 'txt-red-s' : ''">
                          {{ Number(ltpraw['n'].ch) ?
                            ltpraw['n'].ch : '0.00' }}
                          ({{ Number(ltpraw['n'].chp) ? ltpraw['n'].chp.toLocaleString() : '0.00' }}%)</span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

                <v-card v-if="ltpraw['b'] && ltpraw['b'].lp && Number(ltpraw['b'].lp) > 0"
                  class="home-menu-card elevation-0 rounded-xl" width="260px">
                  <v-list-item>
                    <v-list-item-avatar size="44" color="#2E7D32">
                      <span class="white--text">NB</span>
                      <!-- <img src="@/assets/financial.svg"> -->
                    </v-list-item-avatar>
                    <v-list-item-content class="text-left">
                      <v-list-item-title class="font-weight-bold white--text fs-17">NIFTY BANK</v-list-item-title>
                      <v-list-item-subtitle class="font-weight-regular white--text fs-14"
                        :class="Number(ltpraw['b'].lp) > 0 ? '' : 'blink-txt'"><span class="fs-12 font-weight-thin">₹
                        </span>{{ Number(ltpraw['b'].lp) > 0 ?
                          Number(ltpraw['b'].lp).toLocaleString() : '0.00' }}
                        <span class="fs-10"
                          :class="ltpraw['b'].ch > 0 ? 'txt-gre-s' : ltpraw['b'].ch < 0 ? 'txt-red-s' : ''">
                          {{ Number(ltpraw['b'].ch) ?
                            ltpraw['b'].ch.toLocaleString() : '0.00' }}
                          ({{ Number(ltpraw['b'].chp) ? ltpraw['b'].chp.toLocaleString() : '0.00' }}%)</span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </div>

              <p class="white--text headline font-weight-thin mb-2">India's favourite personal online broker.</p>
              <h1 class="hero-ctn-title">Invest in your future <br> with a helping hand.</h1>
              <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="getCall()">

                <v-row no-gutters class="pt-10">
                  <v-col cols="9">
                    <v-text-field v-model="mobile" @keypress="NumberValid($event)" @keyup.enter="getCall()"
                      :maxlength="10" :rules="numberis" required hide-spin-buttons flat solo background-color="#ffffff"
                      class="menu-field-num elevation-0 mr-3 rounded-pill caption" label="Enter mobile number to begin">
                      <template #prepend-inner>
                        <img src="@/assets/phone-icon.svg" width="100%" class="mx-2" alt="phone-icon">
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-btn type="submit" block height="48px" :color="valid ? '#FFF07E' : '#FFF07Ec2'"
                      class="text-none rounded-pill elevation-0"><span class="black--text font-weight-bold">Sign
                        up</span></v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </div>
          </v-card>
        </li>
        <li class="app-card" data-aos="fade-up">
          <v-card class="elevation-0" color="transparent">
            <img class="rounded-xl hero-pg-img" src="@/assets/apps/banner.png" width="100%" alt="banner2">

            <div class="pos-abs apps-float">
              <v-chip text-color="#fff"  small class="home-menu-card font-weight-medium px-2 mb-1">Beta Released
              </v-chip>
              <p class="fs-24 white--text mb-1">
                Our Premier Trading App
              </p>
              <p class="fs-54 white--text font-weight-black ls-1 mb-0">
                MYNT +</p>
              <!-- <p class="fs-24 white--text mb-4">Lighting-fast, Live streaming, Intuitive navigation <br> & an
                extensive set of features.
              </p> -->
              <p class="fs-24 white--text mb-4">Evolving into a full-stack financial monarch.
              </p>
              <br>
              <div class="rounded-0 d-inline-flex">
                <v-card target="_blank" rel="noopener noreferrer" height="46px"
                  href="https://play.google.com/store/apps/details?id=com.mynt.trading_app_zebu" color="#000"
                  class="mr-2 rounded-xl elevation-0">
                  <img src="@/assets/product/scr-mob1.svg" class="px-1 pt-1" alt="playstore-btn-img">
                </v-card>
                <v-card target="_blank" rel="noopener noreferrer" height="46px"
                  href="https://testflight.apple.com/join/uvQJ0bnV" color="#000" class="rounded-xl elevation-0">
                  <img src="@/assets/product/scr-mob2.svg" class="px-1 pt-1" alt="playstore-btn-img">
                </v-card>
              </div>
            </div>

          </v-card>
        </li>
      </ul>

    </div>
    <div data-aos="fade-up" class="d-md-none">
      <ul id="app-cards" class="mb-0">
        <li class="app-card">
      <div class="text-center pt-16 bgg-lin">
        <p class="white--text title font-weight-thin mb-2">India's favourite personal online broker.</p>
        <h1 class="hero-ctn-title mb-8">Invest in your future <br> with a helping hand.</h1>
        <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="getCall()" class="px-6 px-sm-8 mb-6">

          <v-text-field v-model="mobile" @keypress="NumberValid($event)" @keyup.enter="getCall()" :maxlength="10"
            :rules="numberis" required hide-spin-buttons flat solo background-color="#ffffff"
            class="menu-field-num elevation-0 rounded-pill caption" label="Enter mobile number to begin">
            <template #prepend-inner>
              <img src="@/assets/phone-icon.svg" width="100%" class="mx-2" alt="phone-icon">
            </template>
          </v-text-field>

          <v-btn type="submit" block height="48px" :color="valid ? '#FFF07E' : '#FFF07Ec2'"
            class="text-none rounded-pill elevation-0"><span class="black--text font-weight-bold">Sign
              up</span></v-btn>
        </v-form>
        <!-- <div class="d-inline-flex justify-space-between">
          <v-card class="home-menu-card elevation-0 rounded-lg" width="49%">
            <v-list-item class="px-2">
              <v-list-item-avatar size="38" class="mr-2">
                <img src="@/assets/nifty-50.svg">
              </v-list-item-avatar>
              <v-list-item-content class="text-left py-2">
                <v-list-item-title class="font-weight-bold white--text fs-14">Nifty 50</v-list-item-title>
                <v-list-item-subtitle class="font-weight-medium white--text fs-12" :class="Number(ltpraw['n'].lp) > 0 ? '' : 'blink-txt'"><span class="fs-10 font-weight-thin">₹
                  </span>{{ Number(ltpraw['n'].lp) > 0 ?
                    Number(ltpraw['n'].lp).toLocaleString() : '0.00' }}
                  <span class="fs-10" :class="ltpraw['n'].ch > 0 ? 'txt-gre-s' : ltpraw['n'].ch < 0 ? 'txt-red-s' : ''">
                    {{ Number(ltpraw['n'].ch) ?
                      ltpraw['n'].ch : '0.00' }}
                    ({{ Number(ltpraw['n'].chp) ? ltpraw['n'].chp.toLocaleString() : '0.00' }}%)</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>

          <v-card class="home-menu-card elevation-0 rounded-lg" width="49%">
            <v-list-item class="px-2">
              <v-list-item-avatar size="34" class="mr-2">
                <img src="@/assets/financial.svg">
              </v-list-item-avatar>
              <v-list-item-content class="text-left py-2">
                <v-list-item-title class="font-weight-bold white--text fs-14">NIFTY BANK</v-list-item-title>
                <v-list-item-subtitle class="font-weight-medium white--text fs-12" :class="Number(ltpraw['b'].lp) > 0 ? '' : 'blink-txt'"><span class="fs-10 font-weight-thin">₹
                  </span>{{ Number(ltpraw['b'].lp) > 0 ?
                    Number(ltpraw['b'].lp).toLocaleString() : '0.00' }}
                  <span class="fs-10" :class="ltpraw['b'].ch > 0 ? 'txt-gre-s' : ltpraw['b'].ch < 0 ? 'txt-red-s' : ''">
                    {{ Number(ltpraw['b'].ch) ?
                      ltpraw['b'].ch.toLocaleString() : '0.00' }}
                    ({{ Number(ltpraw['b'].chp) ? ltpraw['b'].chp.toLocaleString() : '0.00' }}%)</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </div> -->
        <img class="hero-pg-img mb-n2" src="@/assets/Mhero-pg-bg.svg" width="100%" alt="hero-pg-bg">
      </div>
      </li>
      <li class="app-card">
        <v-card class="Mapps-float elevation-0 rounded-0 px-6 pt-12" width="100%" data-aos="fade-up">
          <v-chip text-color="#fff"  small class="home-menu-card font-weight-medium px-2 mb-1">Beta Released
          </v-chip>
          <p class="fs-20 white--text mb-2">
            Our Premier Trading App
          </p>
          <p class="fs-44 white--text font-weight-black ls-1 mb-1">
            MYNT +</p>
          <!-- <p class="fs-24 white--text mb-4">Lighting-fast, Live streaming, Intuitive navigation <br> & an
            extensive set of features.
          </p> -->
          <p class="fs-20 white--text mb-4">Evolving into a full-stack financial monarch.
          </p>
          <br>
          <div class="d-inline-flex mb-4">
            <v-card target="_blank" rel="noopener noreferrer" height="46px"
              href="https://play.google.com/store/apps/details?id=com.mynt.trading_app_zebu" color="#000"
              class="mr-1 rounded-xl elevation-0 px-1">
              <img src="@/assets/product/scr-mob1.svg" class="px-1 pt-1" alt="playstore-btn-img">
            </v-card>
            <v-card target="_blank" rel="noopener noreferrer" height="46px"
              href="https://testflight.apple.com/join/uvQJ0bnV" color="#000" class="ml-1 rounded-xl elevation-0 px-1">
              <img src="@/assets/product/scr-mob2.svg" class="px-1 pt-1" alt="playstore-btn-img">
            </v-card>
          </div>
          <img class="" src="@/assets/apps/mbanner2.png" width="100%" alt="mbanner2">
        </v-card>
      </li>
      </ul>
    </div>
    <div data-aos="flip-down" class="sec-two py-0 py-md-2">
      <!-- <v-card color="#01409F" class="sectwo-bg rounded-pill pa-2 elevation-0" width="100%">
        <p class="mb-0 px-4 px-md-13 white--text"><span class="font-weight-bold title">&#8377; 0</span> <span
            class="font-weight-regular">Annual charges</span> <span class="px-4 opc-05"><v-icon color="#FEFEFE"
              size="8">mdi-checkbox-blank-circle</v-icon></span> <span class="font-weight-thin subtitle-2">No
            account opening charges. No demat charges.</span></p>
      </v-card> -->
      <v-card to="/referral" class="refer-bar sectwo-bg rounded-pill px-2 elevation-0 overflow-hidden d-none d-md-flex"
        width="100%">
        <v-row no-glutters>
          <v-col cols="9">
            <p class="mb-0 pl-4 pl-md-13 white--text pt-3 pb-2"> <span class="font-weight-regular">Refer your friends to
                Zebu</span>
              <span class="px-4 opc-05"><v-icon color="#FEFEFE" size="8">mdi-checkbox-blank-circle</v-icon></span> <span
                class="font-weight-bold">Get 20% of <span class="px-1 opc-05"></span> </span> <span
                class="font-weight-regular"> in 3 easy steps</span>
            </p>
          </v-col>
          <v-col cols="3" class="text-right py-0">
            <img class="refer-bar-img py-1" src="@/assets/refer-bar.svg" width="100%" alt="refer-bar">
          </v-col>
        </v-row>
      </v-card>
      <v-card to="/referral" class="refer-bar sectwo-bg rounded-0 px-2 elevation-0 overflow-hidden d-flex d-md-none"
        width="100%">
        <v-row no-glutters>
          <v-col cols="7">
            <p class="mb-0 pl-2 fs-10 white--text pt-3 pb-2"> <span class="font-weight-regular">Refer your friends to
                Zebu</span>
              <span class="px-2 opc-05"><v-icon color="#FEFEFE" size="8">mdi-checkbox-blank-circle</v-icon></span> <span
                class="font-weight-bold">Get 20% of </span> <span class="font-weight-regular"> in 3 easy steps</span>
            </p>
          </v-col>
          <v-col cols="5" class="text-right py-0">
            <img class="refer-bar-imgs" src="@/assets/refer-bar.svg" height="90%" alt="refer-bar">
          </v-col>
        </v-row>
      </v-card>
    </div>
    <div data-aos="fade-up" class="sec-three py-0 py-md-2 d-none d-md-flex">
      <v-card color="#E5EBEC" class="secthree-bg rounded-xl pa-14 elevation-0" width="100%">
        <v-toolbar dense flat color="transparent">
          <h2 class="mb-1 secondary-font">Invest in all the <br> products you love</h2>
          <v-spacer></v-spacer>
          <v-btn @click="loveTo()" class="mr-4" icon fab outlined> <v-icon size="40">mdi-chevron-left</v-icon> </v-btn>
          <v-btn @click="loveBk()" icon fab outlined> <v-icon size="40">mdi-chevron-right</v-icon> </v-btn>

        </v-toolbar>
        <p class="font-weight-bold fs-24 ml-4 mt-6 mb-0">using an online stock Trading Platform</p>

        <v-card id="love" width="100%" v-dragscroll.x
          class="d-inline-flex overflow-x-auto no-scroll rounded-lg elevation-0 mt-14" color="transparent">
          <v-card data-aos="flip-right" :to="n.too" v-for="(n, q) in Investproducts" :key="q"
            class="elevation-0 rounded-xl px-6 pt-4 pb-6" :class="Investproducts.length - 1 != q ? 'mr-8' : 'mr-0'"
            width="100%">
            <p class="fw-6 secondary-infont mb-0 fs-28">{{ n.title }}</p>
            <img data-aos="fade-down" class="pa-5" :src="n.image" :alt="n.title" width="248px">
            <p class="subtitle-2 txt-444 font-weight-regular lh-16 mb-0">{{ n.subtitle }}</p>
          </v-card>
        </v-card>
      </v-card>
    </div>
    <div data-aos="fade-up" class="d-md-none">
      <v-card color="#E5EBEC" class="secthree-bg rounded-0 py-14 px-6 px-sm-8 elevation-0 text-center" width="100%">
        <h4 class="mb-8 secondary-fn fs-32 font-weight-regular lh-32">Invest in all the <br> products you love</h4>
        <v-row no-glutters>
          <v-col v-for="(n, q) in Investproducts" :key="q" cols="6">
            <v-card :to="n.too" data-aos="flip-right" class="elevation-0 rounded-xl px-3 pt-4 text-left pb-2"
              :class="Investproducts.length - 1 != q ? 'mr-8' : 'mr-0'" width="100%">
              <p class="fw-6 secondary-infont fs-18" v-html="n.mtitle"></p>
              <img data-aos="fade-down" class="max-width-100" :src="n.image" :alt="n.title">
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <div data-aos="flip-up" class="sec-four py-0 py-md-2">
      <v-card class="rounded-c-pill pt-2 pb-1 px-md-3 elevation-0" color="#212424" width="100%">
        <div class="wrapper">
          <div class="secfour-scroll overflow-hidden">
            <p class="mb-0 white--text">
              <span v-for="(f, a) in secfouritems" :key="a">
                <span class="px-4 subtitle-2 text-uppercase font-weight-thin">{{ f.text }}</span>
                <img src="@/assets/sec-four-divider.svg" alt="sec-four-divider" class="mb-1">
              </span>
            </p>
            <p class="mb-0 white--text">
              <span v-for="(f, a) in secfouritems" :key="a">
                <span class="px-4 subtitle-2 text-uppercase font-weight-thin">{{ f.text }}</span>
                <img src="@/assets/sec-four-divider.svg" alt="sec-four-divider" class="mb-1">
              </span>
            </p>
          </div>
        </div>
      </v-card>
    </div>
    <div class="pt-md-2">
      <v-card data-aos="fade-down" height="420px" class="secfive-bg rounded-xl pa-14 elevation-0 d-none d-md-flex"
        color="#C9EFCD" width="100%">
        <v-col cols="12" class="d-flex align-center my-auto pt-0">
          <div>
            <p class="font-weight-medium mb-2 txt-444 text-uppercase ls-1 fs-16">Investing</p>
            <p class="secondary-font">Invest with an edge.</p>
            <p class="txt-444 font-weight-light title lh-28">
              Invest in stocks, bonds, ETF, IPOs and many more <br> with great set of tools and incredible support.
            </p>
          </div>
        </v-col>
      </v-card>
      <v-card data-aos="fade-down" height="360px"
        class="secfive-bg text-center rounded-0 elevation-0 d-md-none px-6 px-sm-8" color="#C9EFCD" width="100%">
        <v-col cols="12" sm="12" md="6" class=" align-center pt-0">
          <div class="pt-16">
            <p class="font-weight-medium mb-2 txt-444 text-uppercase ls-1 fs-16">Investing</p>
            <p class="secondary-fn fs-32 font-weight-regular lh-32">Invest with an edge.</p>
            <p class="txt-444 font-weight-light fs-18 lh-24">
              Invest in stocks, bonds, ETF, IPOs and many more with great set of tools and incredible support.
            </p>
          </div>
        </v-col>
      </v-card>

      <div class="d-none d-md-block">
        <ul id="cards">
          <li class="card" :id="i.id" v-for="(i, l) in investing" :key="l">
            <div class="secinfive-bg">
              <v-card min-height="480px" class="mx-auto rounded-xl pt-10 pb-4 px-14 sec-five" width="90%">
                <v-row no-gutters>
                  <v-col cols="6" class="pl-12 z-i1">
                    <!-- <v-img :src="i.image" width="100%"  class="px-4" :alt="i.image"></v-img> -->
                    <img :src="i.image" width="100%" class="px-4" :alt="i.image">
                  </v-col>
                  <v-col cols="6" class="pt-16 pl-4">
                    <div class="px-16">
                      <img :src="i.icon" width="50px" :alt="i.icon">
                      <p class="font-weight-bold mt-6 headline">{{ i.title }}</p>
                      <p class="txt-444 font-weight-light fs-18 lh-24">{{ i.subtitle }}</p>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
              <v-card class="mx-auto secinfive-footer rounded-t-0 rounded-b-xl pa-6 elevation-0" color="#FAFAFA"
                width="90%">
                <v-row no-gutters>
                  <v-col cols="6" class="pl-4 ml-auto">
                    <div class="px-16">
                      <v-btn href="https://oa.mynt.in/?ref=zws" target="_blank" rel="noopener noreferrer" text
                        class="text-none rounded-pill"><span class="font-weight-medium title mr-3">Get
                          started now</span> <v-icon color="#1AB700">mdi-arrow-right</v-icon></v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </li>
        </ul>
      </div>

      <ul id="cards" class="d-md-none">
        <li class="card" :id="i.id" v-for="(i, l) in investing" :key="l">
          <div class="secinfive-bg">
            <v-card class="rounded-xl mx-auto sec-five pt-6" width="90%">
              <img :src="i.image" width="100%" class="px-4" :alt="i.image">
              <!-- <v-img :src="i.image" width="100%"  class="px-4" :alt="i.image"></v-img> -->
              <div class="text-center pb-3 px-4">
                <p class="font-weight-bold mt-6 headline">{{ i.title }}</p>
                <p class="txt-444 font-weight-light fs-16 lh-24 mb-16">{{ i.subtitle }}</p>
              </div>
            </v-card>
            <v-card class="secinfive-footer mx-auto rounded-t-0 rounded-b-xl py-3 elevation-0" color="#FAFAFA"
              width="90%">
              <div class="px-16">
                <v-btn href="https://oa.mynt.in/?ref=zws" target="_blank" rel="noopener noreferrer" block text
                  class="text-none rounded-pill"><span class="font-weight-medium title">Get
                    started now</span> <v-icon color="#1AB700">mdi-arrow-right</v-icon></v-btn>
              </div>
            </v-card>
          </div>
        </li>
      </ul>
    </div>
    <div class="pt-md-2 my-10">
      <v-card data-aos="fade-down" height="420px" class="secsix-bg rounded-xl pa-14 elevation-0 d-none d-md-flex"
        color="#FFE5D1" width="100%">
        <v-col cols="12" class="d-flex align-center my-auto pt-0">
          <div>
            <p class="font-weight-medium mb-2 txt-444 text-uppercase ls-1 fs-16">Trading</p>
            <p class="secondary-font">Supercharged Trading.</p>
            <p class="txt-444 font-weight-light title lh-28">
              Powerful indicators and advanced charts. Trade <br>
              with different instruments and become successful.
            </p>
          </div>
        </v-col>
      </v-card>
      <v-card data-aos="fade-down" height="360px"
        class="secsix-bg text-center rounded-0 elevation-0 d-md-none px-6 px-sm-8" color="#FFE5D1" width="100%">
        <v-col cols="12" sm="12" md="6" class=" align-center pt-0">
          <div class="pt-16">
            <p class="font-weight-medium mb-2 txt-444 text-uppercase ls-1 fs-16">Trading</p>
            <p class="secondary-fn fs-32 font-weight-regular lh-32">Supercharged Trading.</p>
            <p class="txt-444 font-weight-light fs-18 lh-24">
              Powerful indicators and advanced charts. Trade
              with different instruments and become successful.
            </p>
          </div>
        </v-col>
      </v-card>
      <div class="d-none d-md-block">
        <ul id="cards">
          <li class="card" :id="i.id" v-for="(i, l) in Trading" :key="l">
            <div class="secinfive-bg">
              <v-card min-height="480px" class="mx-auto rounded-xl pt-10 pb-4 px-14 sec-five" width="90%">
                <v-row no-gutters>
                  <v-col cols="6" class="pl-12 z-i1">
                    <img :src="i.image" width="100%" class="px-4" :alt="i.image">
                  </v-col>
                  <v-col cols="6" class="pt-16 pl-4">
                    <div class="px-16">
                      <img :src="i.icon" width="50px" :alt="i.icon">
                      <p class="font-weight-bold mt-6 headline">{{ i.title }}</p>
                      <p class="txt-444 font-weight-light fs-18 lh-24">{{ i.subtitle }}</p>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
              <v-card class="mx-auto secinfive-footer rounded-t-0 rounded-b-xl pa-6 elevation-0" color="#FAFAFA"
                width="90%">
                <v-row no-gutters>
                  <v-col cols="6" class="pl-4 ml-auto">
                    <div class="px-16">
                      <v-btn href="https://oa.mynt.in/?ref=zws" target="_blank" rel="noopener noreferrer" text
                        class="text-none rounded-pill"><span class="font-weight-medium title mr-3">Get
                          started now</span> <v-icon color="#1AB700">mdi-arrow-right</v-icon></v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </li>
        </ul>
      </div>
      <ul id="cards" class="d-md-none">
        <li class="card" :id="i.id" v-for="(i, l) in Trading" :key="l">
          <div class="secinfive-bg">
            <v-card class="rounded-xl mx-auto sec-five pt-6" width="90%">
              <img :src="i.image" width="100%" class="px-4" :alt="i.image">
              <!-- <v-img :src="i.image" width="100%"  class="px-4" :alt="i.image"></v-img> -->
              <div class="text-center pb-3 px-4">
                <p class="font-weight-bold mt-6 headline">{{ i.title }}</p>
                <p class="txt-444 font-weight-light fs-16 lh-24 mb-16">{{ i.subtitle }}</p>
              </div>
            </v-card>
            <v-card class="secinfive-footer mx-auto rounded-t-0 rounded-b-xl py-3 elevation-0" color="#FAFAFA"
              width="90%">
              <div class="px-16">
                <v-btn href="https://oa.mynt.in/?ref=zws" target="_blank" rel="noopener noreferrer" block text
                  class="text-none rounded-pill"><span class="font-weight-medium title">Get
                    started now</span> <v-icon color="#1AB700">mdi-arrow-right</v-icon></v-btn>
              </div>
            </v-card>
          </div>
        </li>
      </ul>
    </div>
    <div class="sec-seven pt-6">
      <v-card height="500px" class="mx-auto elevation-0 text-center d-none d-md-block" width="64%">
        <p class="secondary-font">Learn with Zebu</p>
        <p class="txt-444 font-weight-light title lh-28 mb-8">Investing and trading can be easy and accessible for
          <br>
          everyone now. Start by learning about how markets <br> and investing works.
        </p>

        <v-row justify="center">
          <v-col v-for="(s, n) in learnitems" :key="n" cols="12" md="6" class="px-4 pb-6">
            <v-card width="256px" :to="s.click" data-aos="zoom-in-up"
              class="secseven-card rounded-xl text-center pa-1 mx-auto">
              <img data-aos="zoom-in-down" :src="s.image" width="160px" class="pa-4" :alt="s.title">
              <p class="font-weight-bold title mb-0">{{ s.title }}</p>
              <p class="txt-444 font-weight-regular caption text-uppercase">{{ s.subtitle }}</p>
            </v-card>
          </v-col>
        </v-row>
      </v-card>

      <v-card height="500px" class="mx-auto elevation-0 text-center d-md-none px-6 px-sm-8" width="100%">
        <p class="secondary-font">Learn with Zebu</p>
        <p class="txt-444 font-weight-light fs-18 lh-28 mb-8">Investing and trading can be easy and accessible for
          everyone now. Start by learning about how markets and investing works.</p>

        <v-row justify="center" class="px-2 px-md-0">
          <v-col v-for="(s, n) in learnitems" :key="n" cols="6" class="px-2 pb-2">
            <v-card width="100%" :to="s.click" data-aos="zoom-in-up"
              class="secseven-card rounded-lg text-center pa-1 mx-auto">
              <img data-aos="zoom-in-down" :src="s.image" width="100%" class="px-6 pt-4" :alt="s.title">
              <p class="font-weight-bold title mb-0">{{ s.title }}</p>
              <p class="txt-444 font-weight-regular caption text-uppercase mb-2">{{ s.subtitle }}</p>
            </v-card>
          </v-col>
        </v-row>
      </v-card>

    </div>
    <div class="sec-eight py-md-16 rounded-xl">
      <!-- <v-card data-aos="fade-down" height="0px" -->
      <v-card data-aos="fade-down" height="380px"
        class="seceight-bg rounded-xl pl-14 elevation-0 d-none d-md-block overflow-hiddens mb-16" color="#E5EBEC"
        width="100%">
        <v-card width="100%" class="d-inline-flex elevation-0 pos-rlt" color="transparent">
          <v-card width="50%" class="elevation-0 py-16" color="transparent">
            <p class="c-secondary-font">
              Exceptional Performance. <br> Exceptional Results.
            </p>
            <p class="txt-444 font-weight-light fs-20 lh-24">
              Mynt is a sophisticated and <br> modern trading application.
            </p>
            <div class="rounded-0 d-inline-flex">
              <v-card target="_blank" rel="noopener noreferrer" height="45px"
                href="https://play.google.com/store/apps/details?id=com.zebu.mynt" color="#000"
                class="mr-2 rounded-lg elevation-0">
                <img src="@/assets/product/scr-mob1.svg" class="px-1 pt-1" alt="playstore-btn-img">
              </v-card>
              <v-card target="_blank" rel="noopener noreferrer" height="45px"
                href="https://apps.apple.com/in/app/mynt-zebu/id1663709767" color="#000"
                class="mr-2 rounded-lg elevation-0">
                <img src="@/assets/product/scr-mob2.svg" class="px-1 pt-1" alt="playstore-btn-img">
              </v-card>
              <v-card target="_" rel="noopener noreferrer" height="45px"
                href="https://zebu.mynt.in/static/Downloads/Apps/ZEBU_INV.exe" color="#000"
                class="rounded-lg elevation-0">
                <img src="@/assets/product/scr-mob3.svg" class="px-1 pt-1" alt="web-btn-img">
              </v-card>
            </div>
          </v-card>
          <v-card width="60%" class="elevation-0 mr-4 overflow-hidden" color="transparent" data-aos="fade-up">
            <img src="@/assets/newimg.png" width="100%" alt="newimg" class="pa-0">
          </v-card>
        </v-card>
      </v-card>
      <v-card data-aos="fade-down"
        class="seceight-bg rounded-0 pt-14 pb-10 mb-10 px-6 px-sm-8 elevation-0 d-md-none text-center" color="#E5EBEC"
        width="100%">
        <!-- <v-card data-aos="fade-down" class="seceight-bg rounded-0 py-14 px-6 px-sm-8 elevation-0 d-md-none text-center"
        color="#E5EBEC" width="100%"> -->
        <v-card width="100%" class="elevation-0" color="transparent">
          <v-card width="100%" class="elevation-0" color="transparent">
            <p class="c-secondary-font">Exceptional Performance. Exceptional Results.</p>
            <p class="txt-444 font-weight-light fs-20 lh-24">
              Mynt is a sophisticated and modern trading application.
            </p>
          </v-card>
          <v-card width="100%" class="elevation-0" color="transparent" data-aos="fade-up">
            <img src="@/assets/newimg.png" width="100%" alt="newimg" class="pa-2">
          </v-card>
          <v-card target="_blank" rel="noopener noreferrer" height="45px"
            href="https://play.google.com/store/apps/details?id=com.zebu.mynt" color="#282828"
            class="rounded-lg elevation-0 mb-3">
            <img src="@/assets/product/scr-mob1.svg" class="px-1 pt-1" alt="playstore-btn-img">
          </v-card>
          <v-card target="_blank" rel="noopener noreferrer" height="45px"
            href="https://apps.apple.com/in/app/mynt-zebu/id1663709767" color="#282828"
            class="rounded-lg elevation-0 mb-3">
            <img src="@/assets/product/scr-mob2.svg" class="px-1 pt-1" alt="playstore-btn-img">
          </v-card>
          <v-card target="_" rel="noopener noreferrer" height="45px"
            href="https://zebu.mynt.in/static/Downloads/Apps/ZEBU_INV.exe" color="#282828"
            class="rounded-lg elevation-0">
            <img src="@/assets/product/scr-mob3.svg" class="px-1 pt-1" alt="web-btn-img">
          </v-card>
        </v-card>
      </v-card>
      <div data-aos="fade-up" class="secineight-bg mb-8 mb-md-0">
        <v-card color="transparent" class="secineight-card pos-rlt mx-auto brd-r-32 elevation-0 d-none d-md-block"
          width="90%">
          <img src="@/assets/sec-eight-bg.webp" width="100%" alt="sec-eight-bg">

          <div class="seceight-ctn pos-abs">
            <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="getCall()">
              <v-row no-gutters class="pt-10">
                <v-col cols="9" class="pr-4">
                  <v-text-field v-model="mobile" @keypress="NumberValid($event)" @keyup.enter="getCall()"
                    :maxlength="10" :rules="numberis" required hide-spin-buttons flat solo background-color="#595959"
                    class="menu-field-seceight elevation-0 mr-3 rounded-pill caption txt-field"
                    label="Enter mobile number to begin">
                    <template #prepend-inner>
                      <img src="@/assets/phone-icon white.svg" width="100%" class="mx-2" alt="phone-icon">
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-btn type="submit" block height="48px" :color="valid ? '#FFF07E' : '#FFF07Ec2'"
                    class="text-none rounded-pill elevation-0"><span class="black--text font-weight-bold">Sign
                      up</span></v-btn>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card>
        <v-card color="#282828" class="secineight-card pos-rlt mx-auto brd-r-32 elevation-0 d-md-none pa-6" width="90%">
          <img src="@/assets/Msec-eight-bg.svg" width="100%" alt="sec-eight-bg">
          <!-- <v-img src="@/assets/Msec-eight-bg.svg" width="100%" alt="sec-eight-bg"></v-img> -->

          <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="getCall()">

            <v-text-field v-model="mobile" @keypress="NumberValid($event)" @keyup.enter="getCall()" :maxlength="10"
              :rules="numberis" required hide-spin-buttons flat solo background-color="#595959"
              class="menu-field-seceight elevation-0 rounded-pill caption txt-field"
              label="Enter mobile number to begin">
              <template #prepend-inner>
                <img src="@/assets/phone-icon white.svg" width="100%" class="mx-2" alt="phone-icon">
              </template>
            </v-text-field>
            <v-btn type="submit" block height="48px" :color="valid ? '#FFF07E' : '#FFF07Ec2'"
              class="text-none rounded-pill elevation-0"><span class="black--text font-weight-bold">Sign
                up</span></v-btn>
          </v-form>
        </v-card>
      </div>
    </div>
    <!-- <div v-if="topbtn" class="float-btn d-none d-md-block">
      <v-card class="pa-2 rounded-circle">
        <v-btn @click="scrollToTop" color="#015FEC" fab>
          <v-icon size="40" color="#fff">mdi-chevron-up</v-icon>
        </v-btn>
      </v-card>
    </div> -->
  </div>
</template>

<script>
// import axios from "axios";
import { counts } from '../apiurl';
import { dragscroll } from 'vue-dragscroll';
import { getLtpdata } from "@/components/mixins/getAPIdata.js";

export default {
  directives: {
    dragscroll
  },
  data: () => ({
    appson: true,
    investing: [
      {
        title: 'Ideas for Investment',
        image: require('@/assets/invest/inv1.svg'),
        icon: require('@/assets/invest/inv-l1.svg'),
        subtitle: "Don't limit yourself to individual scrips, now if you believe in an idea, you can invest in those ideas.",
        getstart: "/invest",
        id: "card1",
      },
      {
        title: 'IPO investing',
        image: require('@/assets/invest/inv2.svg'),
        icon: require('@/assets/invest/inv-l2.svg'),
        subtitle: "Take a deep dive about the IPO companies with additional and exclusive information about the companies before you invest.",
        getstart: "",
        id: "card2",
      },
      {
        title: 'Thematic Investing',
        image: require('@/assets/invest/inv3.svg'),
        icon: require('@/assets/invest/inv-l3.svg'),
        subtitle: "Themes are an effective way to capture an idea. Find thematic collections and invest in lumpsum or as monthly SIPs to create long term wealth.",
        getstart: "",
        id: "card3",
      },
      {
        title: 'News & Alerts',
        image: require('@/assets/invest/inv4.png'),
        icon: require('@/assets/invest/inv-l4.svg'),
        subtitle: "News and updates from the business world, all delivered in a neat interface and as a newsletter. Additionally alerts for price sensitive information delivered to your inbox.",
        getstart: "",
        id: "card4",
      },
      {
        title: 'Quick ordering',
        image: require('@/assets/invest/inv5.svg'),
        icon: require('@/assets/invest/inv-l5.svg'),
        subtitle: "Buy/sell from Zebu with a simple swipe or click. Try placing orders via different modes and with different time limits. Also set triggers to buy or sell using GTT.",
        getstart: "",
        id: "card5",
      },
    ],
    Trading: [
      {
        title: 'Advanced charting tools',
        image: require('@/assets/trade/trd1.svg'),
        icon: require('@/assets/trade/trd-l1.svg'),
        subtitle: "Free access to Tradingview, plus suite of full tools and indicators that tradingview offers. Coupled with other prices and ratio indicators overlayed on charts.",
        getstart: "/trade",
        id: "card1",
      },
      {
        title: 'OI analysis F&O smartlist',
        image: require('@/assets/trade/trd2.svg'),
        icon: require('@/assets/trade/trd-l2.svg'),
        subtitle: "Clear indication of how the OI is setup for the scrip in consideration. You can also create a smartlist built with strikes that have OI changes.",
        getstart: "",
        id: "card2",
      },
      {
        title: '2X - 5X margins',
        image: require('@/assets/trade/trd3.svg'),
        icon: require('@/assets/trade/trd-l3.svg'),
        subtitle: "Find good margins for your daily trades. Adjust risk based on your profile to get the good out of leverage.",
        getstart: "",
        id: "card3",
      },
      {
        title: 'Option chain with greeks',
        image: require('@/assets/trade/trd4.svg'),
        icon: require('@/assets/trade/trd-l4.svg'),
        subtitle: "Master options trade with all our fabulous tools and indicators. Our option chain spread and pre-made strategies, makes your trade life, simple and easy.",
        getstart: "",
        id: "card4",
      },
      {
        title: 'Express P&L',
        image: require('@/assets/trade/trd5.svg'),
        icon: require('@/assets/trade/trd-l5.svg'),
        subtitle: "Comprehensive P&L info, with granular detail available at all times. Without the need to switch between different applications and screens.",
        getstart: "",
        id: "card5",
      },
    ],
    Investproducts: [
      {
        title: 'Stocks & ETFs',
        mtitle: 'Stocks & ETFs',
        image: require('@/assets/products-sec/Stocks-ETFs.png'),
        subtitle: 'We make stock investing a piece of cake with our user-friendly interface. Discover new stocks, buy/sell stocks in just one click.',
        too: "/stocks"
      },
      {
        title: 'Futures & Options',
        mtitle: 'Futures & Options',

        image: require('@/assets/products-sec/Futures-Options.png'),
        subtitle: 'Hassle free futures and options trading with reliable and powerful tools, that makes leveraging and risk management a breeze.',
        too: "/futuresoptions"
      },
      {
        title: 'Commodities',
        mtitle: 'Commodities',

        image: require('@/assets/products-sec/Commodities.png'),
        subtitle: 'From crude to wheat to cotton, trading them has never been easy. All tradable via future contracts and easily margin ready.',
        too: "/commodities"
      },
      {
        title: 'Currency',
        mtitle: 'Currency',

        image: require('@/assets/products-sec/Currency.png'),
        subtitle: "Trade with the world's currencies with ease. Supports future contracts and margin for currency trades.",
        too: "/currencies"
      },
      {
        title: 'Mutual Funds',
        mtitle: 'Mutual Funds',

        image: require('@/assets/products-sec/mutual-funds.png'),
        subtitle: 'A wonderful diversified way of building wealth. Actively managed for the long term wealth creation with easy SIP investing.',
        too: "/mutualfunds"
      },
      {
        title: 'IPOs',
        mtitle: 'IPOs',

        image: require('@/assets/products-sec/ipos.png'),
        subtitle: "It's India's decade, seize the opportunity by investing in the newest companies getting listed via IPOs.",
        too: "/ipos"
      },
      {
        title: 'Bonds',
        mtitle: 'Bonds',

        image: require('@/assets/products-sec/bonds.png'),
        subtitle: "A good way to invest your money with guaranteed returns and safety is by investing in India's growth story, which is backed by the government.",
        too: "/governmentbonds"
      },
      // {
      //   title: 'US stocks',
      //   image: require('@/assets/products-sec/usStocks.png'),
      //   subtitle: 'Why limit yourself with India, when the whole world is open for you. Invest in global stocks via Zebu. Make wealth worldwide.',
      // },

      {
        title: 'Gold bonds',
        mtitle: 'Gold bonds',

        image: require('@/assets/products-sec/sgb.png'),
        subtitle: 'Nothing beats gold as an investment, but gold bonds are better. See your gold investment grow and also earn interest on it',
        too: "/sgb"
      }
    ],
    secfouritems: [
      { text: 'Option tools', link: '' },
      { text: 'Powerful charting', link: '' },
      { text: 'Collections', link: '' },
      { text: 'Wishlist', link: '' },
      { text: 'Margin', link: '' },
      { text: 'Theme Investing', link: '' },
      { text: 'RIsk', link: '' },
      { text: 'Diversified', link: '' },
      { text: 'Weighted', link: '' },
    ],
    learnitems: [
      // {
      //   title: 'Webinars',
      //   image: require('@/assets/learn-sec/image 29.png'),
      //   subtitle: '14 Webinars',
      //   click: ''
      // },
      {
        title: 'Blogs',
        image: require('@/assets/learn-sec/image 34.png'),
        subtitle: '0 Blogs',
        click: '/blogs'
      },
      {
        title: 'Videos',
        image: require('@/assets/learn-sec/image 28.png'),
        subtitle: '0 Videos',
        click: '/videos'
      },
      // {
      //   title: 'Digest',
      //   image: require('@/assets/learn-sec/image 30.png'),
      //   subtitle: '14 Digests',
      //   click: ''
      // },
      {
        title: 'News',
        image: require('@/assets/learn-sec/image 31.png'),
        subtitle: '0 today',
        click: '/news'
      },
      {
        title: 'Calculator',
        image: require('@/assets/learn-sec/image 32.png'),
        subtitle: '21 Calculators',
        click: '/calculator'
      },
    ],

    // topbtn: false,
    valid: true,
    mobile: '',
    numberis: [
      (v) => !!v || "Your mobile number is required",
      (v) =>
        /^[6-9][0-9]{9}$/.test(v) || "mobile number must be 10 Digit Number",
    ],

    offsetTop: 0,
    ltpraw: {
      "n": {},
      "b": {},
    },
  }),

  created() {
    window.addEventListener("scroll", this.handleScroll);
    // window.addEventListener('scroll', this.scrollFind);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    this.getWalldata();
    setInterval(() => {
      this.getWalldata();
    }, 60000);
    // this.scrollFind();
    this.learnitems[0].subtitle = counts.blogs_count ? `${counts.blogs_count} Blogs` : '0'
    this.learnitems[1].subtitle = counts.youtube_count ? `${counts.youtube_count} Videos` : '0'
    this.learnitems[2].subtitle = counts.today_news ? `${counts.today_news} today` : '0'
  },

  components: {
    // HelloWorld
  },

  methods: {
    async getWalldata() {
      let ltpraw = await getLtpdata([{ "token": '26000', "exch": 'NSE' }, { "token": '26009', "exch": 'NSE' }]);
      if (ltpraw.data && Object.keys(ltpraw.data).length == 2) {
        this.ltpraw['n'] = ltpraw.data['26000'];
        this.ltpraw['n']['lp'] = Number(ltpraw.data['26000'].lp).toFixed(2);
        this.ltpraw['n']['ch'] = Number(ltpraw.data['26000'].lp) > 0 ? (Number(ltpraw.data['26000'].lp) - Number(ltpraw.data['26000'].close)).toFixed(2) : 0;
        this.ltpraw['n']['chp'] = Number(ltpraw.data['26000'].change);
        this.ltpraw['b'] = ltpraw.data['26009'];
        this.ltpraw['b']['lp'] = Number(ltpraw.data['26009'].lp).toFixed(2);
        this.ltpraw['b']['ch'] = Number(ltpraw.data['26009'].lp) > 0 ? (Number(ltpraw.data['26009'].lp) - Number(ltpraw.data['26009'].close)).toFixed(2) : 0;
        this.ltpraw['b']['chp'] = Number(ltpraw.data['26009'].change);
      }
    },
    NumberValid($event) {
      if (($event.keyCode >= 48 && $event.keyCode <= 57) || ($event.keyCode >= 96 && $event.keyCode <= 105)) {
        // 0-9 only
      } else {
        $event.preventDefault();
      }
    },
    // scrollFind() {
    //   if (location.pathname == "/") {
    //     let element = document.getElementById("top").getBoundingClientRect().top;
    //     if (element < -600) {
    //       this.topbtn = true;
    //     } else {
    //       this.topbtn = false;
    //     }
    //   }
    // },

    // scrollToTop() {
    //   // ref="topelement"
    //   // this.$refs.topelement.scrollIntoView({ behavior: 'smooth' });
    //   window.scrollTo(0, 0);
    // },

    handleScroll() {
      if (window.scrollY > 30) {
        return (this.appson = true);
      }
      if (window.scrollY <= 30) {
        if (this.appson) {
          return (this.appson = false);
        }
      }
    },

    getCall() {
      if (this.$refs.form.validate() == true) {
        window.open(
          `https://oa.mynt.in/?ref=zws&mobile=${this.mobile}`
        );
        this.$refs.form.reset();
      }
    },

    loveTo() {
      const element = document.getElementById("love");
      element.scrollBy({
        left: -600,
        behavior: "smooth",
      });
    },

    loveBk() {
      const element = document.getElementById("love");
      element.scrollBy({
        left: 600,
        behavior: "smooth",
      });
    },
  }
}
</script>singelstock page data/mixins/getAPIdata.js
