<template>
    <div>
        <div>
            <div class="my-6 my-md-16">
                <v-toolbar dense class="elevation-0 mb-6 d-none d-md-block">
                    <div>
                        <p class="mb-0 font-weight-bold secondary-infont fs-32 lh-32">{{ playlistname ? playlistname : null }}
                            playlists</p>
                        <p class="mb-0 font-weight-regular">Updated {{ ago ? ago : null }}</p>
                    </div>
                    <v-spacer></v-spacer>
                </v-toolbar>

                <v-toolbar dense class="elevation-0 mb-6 d-md-none">
                    <div class="pl-2 pl-sm-4">
                        <p class="mb-0 font-weight-bold secondary-infont fs-28 lh-24">{{ playlistname ? playlistname : null }}
                            playlists</p>
                        <p class="mb-0 fs-16 font-weight-regular">Updated {{ ago ? ago : null }}</p>
                    </div>
                </v-toolbar>
                <div class="pl-4">
                    <v-row v-if="allplaylist.length > 0" no-glutters class="pt-2 pt-md-6">
                        <v-col v-for="(v, l) in allplaylist" :key="l" cols="12" sm="6" md="4" lg="3" class="pb-0 pb-md-3">
                            <v-card @click="videoShow(val = v)" data-aos="zoom-in-up" width="100%"  class="elevation-0 pl-2 pl-sm-4 pr-6 pr-sm-8 px-md-0"
                                :class="l != homeitems.length - 1 ? 'mr-md-4' : 'mr-md-0'">
                                <div class="pos-rlt">
                                    <v-card class="rounded-lg elevation-0">

                                        <img :src="v.stdurl" width="100%" :alt="v.stdurl">

                                    </v-card>
                                    <v-card color="#ffffff" class="elevation-0 rounded-md py-1 px-3 pos-abs abs-post3">
                                        <span class="font-weight-black subtitle-2">Explainer</span>
                                    </v-card>
                                </div>

                                <v-list-item data-aos="fade-left" class="px-0">
                                    <v-list-item-content class="pt-1">
                                        <v-list-item-title class="font-weight-bold subtitle-1 text-rap-l2">{{ v.title
                                        }}</v-list-item-title>
                                        <p class="mb-0 font-weight-regular subtitle-2">{{ v.view }} views <v-icon
                                                color="#666" size="6">mdi-checkbox-blank-circle</v-icon> {{ v.ago }}</p>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-card>
                        </v-col>
                    </v-row>
                    <div v-else>
                        <v-container fill-height>
                            <v-card class="elevation-0 mx-auto my-16">
                                <v-progress-circular size="80" indeterminate color="#1e53e5"></v-progress-circular>
                            </v-card>
                        </v-container>
                    </div>
                    <v-container class="mt-6 mt-md-10">
                        <v-card v-if="pagecount" data-aos="fade-down" max-width="600px"
                            class="box-s1 rounded-xl mx-auto py-1">
                            <v-pagination @input="getPlaylist()" color="#000" prev-icon="mdi-arrow-left"
                                next-icon="mdi-arrow-right" v-model="page" class="my-3 elevation-0" :total-visible="11"
                                :length="pagecount"></v-pagination>
                        </v-card>
                    </v-container>
                </div>
            </div>
        </div>
        <div>
            <v-dialog v-model="videodialog" class="elevation-0 my-0" content-class="elevation-0">
                <v-container class="pa-md-0" fill-height>
                    <v-card color="transparent" class="video-frame rounded-md mx-0 mx-md-auto elevation-0 pb-0 mb-0">
                        <v-toolbar color="transparent" dense class="tool-dialog elevation-0">
                            <div>
                                <v-list-item class="pl-0 d-none d-md-block">
                                    <v-list-item-content class="pt-1">
                                        <v-list-item-title class="font-weight-bold title white--text">{{ playvideo ?
                                            playvideo.title : null }}</v-list-item-title>
                                        <p class="mb-0 white--text">{{ playvideo ? playvideo.view : null }} views <v-icon
                                                color="#fff" size="8">mdi-checkbox-blank-circle</v-icon> {{ playvideo ?
                                                    playvideo.ago : null }}</p>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item class="pl-0 d-md-none">
                                    <v-list-item-content class="pt-1">
                                        <v-list-item-title class="font-weight-bold fs-14 white--text">{{ playvideo ?
                                            playvideo.title : null }}</v-list-item-title>
                                        <p class="mb-0 white--text fs-12">{{ playvideo ? playvideo.view : null }} views <v-icon
                                                color="#fff" size="8">mdi-checkbox-blank-circle</v-icon> {{ playvideo ?
                                                    playvideo.ago : null }}</p>
                                    </v-list-item-content>
                                </v-list-item>
                            </div>
                            <v-spacer></v-spacer>
                            <v-icon @click="videoClose()" color="#ffffff">mdi-close</v-icon>
                        </v-toolbar>
                        <div>
                            <iframe class="rounded-lg video-dialog" type="text/html" 
                                :src="`https://www.youtube.com/embed/${playvideo ? playvideo.videoid : null}?autoplay=1&amp;modestbranding=1`"
                                frameborder="0"
                                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen></iframe>
                        </div>
                        <div class="pos-abs z-in1 trans-cent abs-post4">
                            <v-progress-circular v-if="videodialog" size="80" :width="4" indeterminate
                                color="#ffffff"></v-progress-circular>
                        </div>
                    </v-card>
                </v-container>
            </v-dialog>
            <v-dialog v-model="shortdialog" class="elevation-0 my-0" content-class="elevation-0">
                <v-card color="transparent" class="rounded-md mx-auto elevation-0 pb-0 mb-0 short-dialog">
                    <div class="pos-rlt">
                        <div class="pos-abs abs-post5 d-none d-md-block">
                            <v-btn :disabled="playshort ? playshort.id != 0 ? false : true : null" @click="shortPrev()"
                                @keyup.left="shortPrev()" medium color="#ffffff"
                                class="rounded-pill mr-3 text-none my-auto brd-2" outlined>Previous
                                short</v-btn>
                        </div>
                        <div>
                            <v-toolbar color="transparent" heigth="32px" dense class="tool-dialog elevation-0">
                                <v-spacer></v-spacer>
                                <v-icon @click="shortClose()" color="#ffffff">mdi-close</v-icon>
                            </v-toolbar>
                            <iframe class="rounded-lg short-frame" type="text/html" width="100%"
                                :src="`https://www.youtube.com/embed/${playshort ? playshort.videoid : null}?autoplay=1&amp;modestbranding=1`"
                                frameborder="0"
                                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen></iframe>
                        </div>
                        <v-row no-glutters class="d-md-none">
                            <v-col cols="6">
                                <v-btn :disabled="playshort ? playshort.id != 0 ? false : true : null" @click="shortPrev()"
                                    @keyup.left="shortPrev()" medium color="#ffffff" block
                                    class="rounded-pill text-none my-auto brd-2" outlined>Previous
                                    short</v-btn>
                            </v-col>
                            <v-col cols="6">
                                <v-btn :disabled="playshort ? playshort.id != 23 ? false : true : null" @click="shortNext()"
                                    @keyup.right="shortNext()" medium color="#ffffff" block
                                    class="rounded-pill text-none my-auto brd-2" outlined>Next
                                    short</v-btn>
                            </v-col>
                        </v-row>
                        <div class="pos-abs z-in1 trans-cent abs-post4">
                            <v-progress-circular v-if="shortdialog" size="80" :width="4" indeterminate
                                color="#ffffff"></v-progress-circular>
                        </div>
                        <div class="pos-abs abs-post6 d-none d-md-block">
                            <v-btn :disabled="playshort ? playshort.id != 23 ? false : true : null" @click="shortNext()"
                                @keyup.right="shortNext()" medium color="#ffffff"
                                class="rounded-pill ml-3 text-none my-auto brd-2" outlined>Next
                                short</v-btn>
                        </div>
                    </div>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { sessapi } from '../../apiurl'

export default {
    data: () => ({
        playshort: null,
        playvideo: null,
        videodialog: false,
        shortdialog: false,
        homeitems: [],
        shortsitems: [],
        page: 1,
        shortpage: 1,

        pagecount: 0,
        shortpagecount: 0,
        allvideos: [],
        allshort: [],

        shortlists: [],

        isDragging: true,
        ev: null,

        allplaylist: [],
        plyid: 1,
        playlistname: null,
        ago: null,
    }),
    mounted() {
        let v = this.$route.params;
        if (v.id) {
            this.plyid = v.id;
        } else {
            this.plyid = 1;
        }
        this.getPlaylist();
        this.shortClose();
        this.videoClose();
        this.scrollBehavior();
    },
    methods: {
        getPlaylist() {
            this.allplaylist = [];
            let config = {
                method: 'get',
                // url: `${sessapi}/getallplaylist`,
                url: `${sessapi}/getPlaylistVideo?pagecount=${this.page}&pagesize=24&id=${this.plyid}`,
                // url: `http://192.168.5.70:2001/getallplaylist`,
            };

            let axiosThis = this;
            axios.request(config)
                .then((response) => {
                    if (response.data.data) {
                        let data = response.data.data
                        for (let v = 0; v < data.length; v++) {
                            data[v]['isdate'] = `${new Date(data[v].uploadtime).toDateString().slice(3)} ${new Date(data[v].uploadtime).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })} IST`;
                            data[v]['ago'] = axiosThis.differentDate(data[v].uploadtime);
                            data[v]['view'] = axiosThis.differentView(Number(data[v].viewCount));
                            axiosThis.allplaylist.push(data[v]);
                        }
                        axiosThis.pagecount = response.data.TotalPages;
                        axiosThis.playlistname = response.data.playlistName;
                        axiosThis.ago = axiosThis.differentDate(response.data.date);

                        axiosThis.scrollBehavior();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

        },
        differentView(view) {
            return `${view > 999 ? Math.round(view / 1000) : view > 999999 ? Math.round(view / 1000000) : view > 999999999 ? Math.round(view / 1000000000) : view} ${view > 999 ? 'K' : view > 999999 ? 'M' : view > 999999999 ? 'B' : ''}`
        },
        differentDate(date) {
            var date1 = new Date(date);
            var date2 = new Date();
            var ov = Math.abs(date2.getTime() - date1.getTime())
            var mt = Math.round(ov / 3600000)
            var dd = mt > 60 ? Math.round(mt / 24) : 0;
            var mm = dd > 30.4166667 ? Math.round(dd / 30.4166667) : 0;
            var yy = mm > 12 ? Math.round(dd / 365) : 0;
            return `${yy != 0 ? yy : mm != 0 ? mm : dd != 0 ? dd : mt != 0 ? mt : 0} ${yy != 0 ? yy > 1 ? 'years' : 'year' : mm != 0 ? mm > 1 ? 'months' : 'month' : dd != 0 ? dd > 1 ? 'days' : 'day' : mt > 1 ? 'hours' : 'minutes'} ago`;
        },
        scrollBehavior() {
            window.scrollTo(0, 0);
        },

        shortShow(val) {
            this.playshort = val;
            this.shortdialog = true;
        },
        shortNext() {
            let ids = this.allshort[this.playshort.id + 1];
            this.playshort = null;
            this.playshort = ids;
        },
        shortPrev() {
            let ids = this.allshort[this.playshort.id - 1];
            this.playshort = null;
            this.playshort = ids;
        },
        videoShow(val) {
            this.playvideo = val;
            this.videodialog = true;
        },
        shortClose() {
            this.playshort = [];
            this.shortdialog = false;
        },
        videoClose() {
            this.videodialog = false;
            this.playvideo = [];
        },
    }
}
</script>