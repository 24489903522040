<template>
  <div class="no-xhide">
    <div data-aos="fade-up" class="pos-rlt d-none d-md-flex">
      <img class="hero-pg-img" src="@/assets/invest-bg-pg.svg" width="100%" alt="invest-bg-pg">
      <div class="invst-pg-ctn pos-abs">
        <p class="invst-ctn-title font-weight-bold fs-54 lh-58 white--text">Hassle-free <br> investing</p>
        <p class="white--text headline font-weight-thin mb-2">Stocks, IPOs, bonds and US stocks <br> are just a click
          away.</p>
        <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="getCall()">
          <v-row no-gutters class="pt-10">
            <v-col cols="9">
              <v-text-field v-model="mobile" @keypress="NumberValid($event)" @keyup.enter="getCall()" :maxlength="10" :rules="numberis" required
                hide-spin-buttons flat solo background-color="#ffffff"
                class="menu-field-num elevation-0 mr-3 rounded-pill caption" label="Enter mobile number to begin">
                <template #prepend-inner>
                  <img src="@/assets/phone-icon.svg" width="100%" class="mx-2" alt="phone-icon">
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="3">
              <v-btn type="submit" :disabled="!valid" block height="48px" color="#FFF07E"
                class="text-none rounded-pill elevation-0"><span class="black--text font-weight-bold">Sign
                  up</span></v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
    <div data-aos="fade-up" class="d-md-none">
      <div class="text-center pt-16 pos-rlt px-6 px-sm-8 bgg-lin">
        <h1 class="hero-ctn-title mb-3">Hassle - free <br> investing</h1>
        <p class="white--text title font-weight-regular mb-8">Advanced tools that are fast, easy to use and secure.</p>

        <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="getCall()" class="pb-16">
          <v-text-field v-model="mobile" @keypress="NumberValid($event)" @keyup.enter="getCall()" :maxlength="10" :rules="numberis" required
            hide-spin-buttons flat solo background-color="#ffffff"
            class="menu-field-num elevation-0 rounded-pill caption" label="Enter mobile number to begin">
            <template #prepend-inner>
              <img src="@/assets/phone-icon.svg" width="100%" class="mx-2" alt="phone-icon">
            </template>
          </v-text-field>

          <v-btn type="submit" block height="48px" :color="valid ? '#FFF07E' : '#FFF07Ec2'"
            class="text-none rounded-pill elevation-0"><span class="black--text font-weight-bold">Sign
              up</span></v-btn>
        </v-form>

      </div>
    </div>
    <div data-aos="flip-up" class="sec-four py-0 py-md-2">
      <v-card class="rounded-c-pill pt-2 pb-1 px-md-3 elevation-0" color="#212424" width="100%">
        <div class="wrapper">
          <div class="secfour-scroll overflow-hidden">
            <p class="mb-0 white--text">
              <span v-for="(f, a) in secfouritems" :key="a">
                <span class="px-4 subtitle-2 text-uppercase font-weight-thin">{{ f.text }}</span>
                <img src="@/assets/sec-four-divider.svg" alt="sec-four-divider" class="mb-1">
              </span>
            </p>
            <p class="mb-0 white--text">
              <span v-for="(f, a) in secfouritems" :key="a">
                <span class="px-4 subtitle-2 text-uppercase font-weight-thin">{{ f.text }}</span>
                <img src="@/assets/sec-four-divider.svg" alt="sec-four-divider" class="mb-1">
              </span>
            </p>
          </div>
        </div>
      </v-card>
    </div>

    <div data-aos="fade-up" class="sec-three py-0 py-md-2">
      <v-card data-aos="zoom-in-down" color="#E5EBEC" class="secthree-bg rounded-xl pa-14 elevation-0 d-none d-md-block"
        width="100%">
        <p class="mb-0 secondary-font">Grow your wealth.<br>Everything you need.</p>
        <div class="mt-16">
          <v-row no-glutters>
            <v-col cols="3">
              <v-card @click="setinvestItem(n)" v-for="(n, d) in secthreeitems" :key="d"
                class="elevation-0 rounded-l-xl px-6 pt-3 pb-4 mb-8 text-center"
                :class="inaid == n.id ? 'rounded-r-0' : 'rounded-r-xl'" width="100%">
                <img data-aos="zoom-in-down" class="py-2" :src="n.image" :alt="n.title" width="72%">
                <p class="font-weight-bold mb-0 fs-24">{{ n.title }}</p>
                <v-card class="hide-space-crd pos-abs rounded-0 elevation-0" width="20%" height="100%"
                  v-if="inaid == n.id"></v-card>
              </v-card>
            </v-col>
            <v-col cols="9">
              <v-card width="100%" min-height="1160px" class="rounded-r-xl elevation-0 py-10 px-16"
                :class="inaid == 1 ? 'rounded-tl-0 rounded-bl-xl' : 'rounded-xl'">
                <div>
                  <p class="font-weight-medium black--text"><span class="display-1">{{ investactive.tit }}</span></p>
                  <p class="txt-444 font-weight-light title lh-28">{{ investactive.sub }}</p>

                  <v-btn target="_blank" rel="noopener noreferrer" href="https://oa.mynt.in/?ref=zws" height="48px"
                    color="#2A2A2A" class="text-none rounded-pill elevation-0 mt-4 mb-10"><span
                      class="white--text subtitle-1 font-weight-medium px-2">Start investing</span></v-btn>

                  <v-row>
                    <v-col v-for="(l, k) in investactive.data" :key="k" cols="6" :class="k % 2 == 0 ? 'pr-8' : 'pl-8'">
                      <img data-aos="fade-up" :alt="l.tit" :src="l.image" width="100%">
                      <div data-aos="fade-down">
                        <p class="font-weight-medium headline black--text">{{ l.tit }}</p>
                        <p class="font-weight-light mt-3 black--text fs-17">{{ l.sub }}</p>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-col>

          </v-row>
        </div>
      </v-card>
      <v-card data-aos="zoom-in-down" color="#E5EBEC"
        class="secthree-bg rounded-0 py-14 px-6 px-sm-8 elevation-0 d-md-none text-center" width="100%">
        <h4 class="mb-8 secondary-fn fs-32 font-weight-regular lh-32">Grow your wealth.
          Everything you need.</h4>

        <div class="d-inline-flex overflow-x-auto no-scroll pb-6 width-100">
          <div v-for="(l, o) in secthreeitems" :key="o">
            <v-btn @click="setinvestItem(l)" :color="inaid == l.id ? '#444444' : '#ffffff'"
              class="rounded-pill mr-2 text-none brd-c-2">
              <span :class="inaid == l.id ? 'white--text' : 'black--text'">{{ l.title }}</span>
            </v-btn>
          </div>
        </div>

        <div>
          <v-card width="100%" class="elevation-0 mx-auto rounded-xl px-6 px-sm-8">
            <img data-aos="zoom-in-down" class="mt-4" width="180px" :src="investactive.image" :alt="investactive.title">
            <p class="font-weight-bold fs-28">{{ investactive.title }}</p>
            <div>
              <p class="font-weight-medium fs-18 lh-16 mb-3">{{ investactive.tit }}</p>
              <p class="txt-444 font-weight-regular fs-16 lh-16">{{ investactive.sub }}</p>

              <v-btn target="_blank" rel="noopener noreferrer" href="https://oa.mynt.in/?ref=zws" height="48px"
                color="#2A2A2A" class="text-none rounded-pill elevation-0 mt-4 mb-8" block><span
                  class="white--text subtitle-1 font-weight-medium px-2">Start investing</span></v-btn>

              <v-row>
                <v-col v-for="(l, k) in investactive.data" :key="k" cols="12" class="text-left">
                  <img data-aos="fade-up" :alt="l.tit" :src="l.image" width="100%">
                  <div data-aos="fade-down">
                    <p class="font-weight-medium title black--text mb-2">{{ l.tit }}</p>
                    <p class="font-weight-light black--text fs-16">{{ l.sub }}</p>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </div>
      </v-card>
    </div>
    <div class="sec-seven pt-16">
      <v-card height="500px" class="mx-auto elevation-0 text-center d-none d-md-block" width="64%">
        <p class="secondary-font">Learn with Zebu</p>
        <p class="txt-444 font-weight-light title lh-28 mb-8">Investing and trading can be easy and accessible for
          <br>
          everyone now. Start by learning about how markets <br> and investing works.</p>

        <v-row justify="center">
          <v-col v-for="(s, n) in learnitems" :key="n" cols="12" md="6" class="px-4 pb-6">
            <v-card width="256px" :to="s.click" data-aos="zoom-in-up"
              class="secseven-card rounded-xl text-center pa-1 mx-auto">
              <img data-aos="zoom-in-down" :src="s.image" width="160px" class="pa-4" :alt="s.title">
              <p class="font-weight-bold title mb-0">{{ s.title }}</p>
              <p class="txt-444 font-weight-regular caption text-uppercase">{{ s.subtitle }}</p>
            </v-card>
          </v-col>
        </v-row>
      </v-card>

      <v-card height="500px" class="mx-auto elevation-0 text-center d-md-none px-6 px-sm-8" width="100%">
        <p class="secondary-font">Learn with Zebu</p>
        <p class="txt-444 font-weight-light fs-18 lh-28 mb-8">Investing and trading can be easy and accessible for
          everyone now. Start by learning about how markets and investing works.</p>

        <v-row justify="center" class="px-2 px-md-0">
          <v-col v-for="(s, n) in learnitems" :key="n" cols="6" class="px-2 pb-2">
            <v-card width="100%" :to="s.click" data-aos="zoom-in-up"
              class="secseven-card rounded-lg text-center pa-1 mx-auto">
              <img data-aos="zoom-in-down" :src="s.image" width="100%" class="px-6 pt-4" :alt="s.title">
              <p class="font-weight-bold title mb-0">{{ s.title }}</p>
              <p class="txt-444 font-weight-regular caption text-uppercase mb-2">{{ s.subtitle }}</p>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </div>

    <div class="sec-eight py-md-16 rounded-xl">
      <!-- <v-card to="/hidepricepage" data-aos="fade-down" height="360px" -->
      <v-card data-aos="fade-down" height="380px"
        class="seceight-bg rounded-xl pl-14 elevation-0 d-none d-md-block overflow-hiddens mb-16" color="#E5EBEC"
        width="100%">
        <v-card width="100%" class="d-inline-flex elevation-0 pos-rlt" color="transparent">
          <v-card width="50%" class="elevation-0 py-16" color="transparent">
            <p class="c-secondary-font">
              Exceptional Performance. <br> Exceptional Results.
            </p>
            <p class="txt-444 font-weight-light fs-20 lh-24">
              Mynt is a sophisticated and <br> modern trading application.
            </p>
            <div class="rounded-0 d-inline-flex">
              <v-card target="_blank" rel="noopener noreferrer" height="45px"
                href="https://play.google.com/store/apps/details?id=com.zebu.mynt" color="#000"
                class="mr-2 rounded-lg elevation-0">
                <img src="@/assets/product/scr-mob1.svg" class="px-1 pt-1" alt="playstore-btn-img">
              </v-card>
              <v-card target="_blank" rel="noopener noreferrer" height="45px"
                href="https://apps.apple.com/in/app/mynt-zebu/id1663709767" color="#000"
                class="mr-2 rounded-lg elevation-0">
                <img src="@/assets/product/scr-mob2.svg" class="px-1 pt-1" alt="playstore-btn-img">
              </v-card>
              <v-card target="_" rel="noopener noreferrer" height="45px"
                href="https://zebu.mynt.in/static/Downloads/Apps/ZEBU_INV.exe" color="#000"
                class="rounded-lg elevation-0">
                <img src="@/assets/product/scr-mob3.svg" class="px-1 pt-1" alt="web-btn-img">
              </v-card>
            </div>
          </v-card>
          <v-card width="60%" class="elevation-0 mr-4 overflow-hidden" color="transparent" data-aos="fade-up">
            <img src="@/assets/newimg.png" width="100%" alt="newimg" class="pa-0">
          </v-card>
        </v-card>
      </v-card>
      <v-card data-aos="fade-down"
        class="seceight-bg rounded-0 pt-14 pb-10 mb-10 px-6 px-sm-8 elevation-0 d-md-none text-center" color="#E5EBEC"
        width="100%">
        <!-- <v-card data-aos="fade-down" class="seceight-bg rounded-0 py-14 px-6 px-sm-8 elevation-0 d-md-none text-center"
        color="#E5EBEC" width="100%"> -->
        <v-card width="100%" class="elevation-0" color="transparent">
          <v-card width="100%" class="elevation-0" color="transparent">
            <p class="c-secondary-font">Exceptional Performance. Exceptional Results.</p>
            <p class="txt-444 font-weight-light fs-20 lh-24">
              Mynt is a sophisticated and modern trading application.
            </p>
          </v-card>
          <v-card width="100%" class="elevation-0" color="transparent" data-aos="fade-up">
            <img src="@/assets/newimg.png" width="100%" alt="newimg" class="pa-2">
          </v-card>
          <v-card target="_blank" rel="noopener noreferrer" height="45px"
            href="https://play.google.com/store/apps/details?id=com.zebu.mynt" color="#282828"
            class="rounded-lg elevation-0 mb-3">
            <img src="@/assets/product/scr-mob1.svg" class="px-1 pt-1" alt="playstore-btn-img">
          </v-card>
          <v-card target="_blank" rel="noopener noreferrer" height="45px"
            href="https://apps.apple.com/in/app/mynt-zebu/id1663709767" color="#282828"
            class="rounded-lg elevation-0 mb-3">
            <img src="@/assets/product/scr-mob2.svg" class="px-1 pt-1" alt="playstore-btn-img">
          </v-card>
          <v-card target="_" rel="noopener noreferrer" height="45px"
            href="https://zebu.mynt.in/static/Downloads/Apps/ZEBU_INV.exe" color="#282828"
            class="rounded-lg elevation-0">
            <img src="@/assets/product/scr-mob3.svg" class="px-1 pt-1" alt="web-btn-img">
          </v-card>
        </v-card>
      </v-card>
      <div data-aos="fade-up" class="secineight-bg mb-8 mb-md-0">
        <v-card color="transparent" class="secineight-card pos-rlt mx-auto brd-r-32 elevation-0 d-none d-md-block"
          width="90%">
          <img src="@/assets/sec-eight-bg.webp" width="100%" alt="sec-eight-bg">

          <div class="seceight-ctn pos-abs">
            <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="getCall()">
              <v-row no-gutters class="pt-10">
                <v-col cols="9" class="pr-4">
                  <v-text-field v-model="mobile" @keypress="NumberValid($event)" @keyup.enter="getCall()" :maxlength="10" :rules="numberis"
                    required hide-spin-buttons flat solo background-color="#595959"
                    class="menu-field-seceight elevation-0 mr-3 rounded-pill caption txt-field"
                    label="Enter mobile number to begin">
                    <template #prepend-inner>
                      <img src="@/assets/phone-icon white.svg" width="100%" class="mx-2" alt="phone-icon">
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-btn type="submit" block height="48px" :color="valid ? '#FFF07E' : '#FFF07Ec2'"
                    class="text-none rounded-pill elevation-0"><span class="black--text font-weight-bold">Sign
                      up</span></v-btn>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card>
        <v-card color="#282828" class="secineight-card pos-rlt mx-auto brd-r-32 elevation-0 d-md-none pa-6" width="90%">
          <img src="@/assets/Msec-eight-bg.svg" width="100%" alt="sec-eight-bg">
          <!-- <v-img src="@/assets/Msec-eight-bg.svg" width="100%" alt="sec-eight-bg"></v-img> -->

          <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="getCall()">

            <v-text-field v-model="mobile" @keypress="NumberValid($event)" @keyup.enter="getCall()" :maxlength="10" :rules="numberis" required
              hide-spin-buttons flat solo background-color="#595959"
              class="menu-field-seceight elevation-0 rounded-pill caption txt-field"
              label="Enter mobile number to begin">
              <template #prepend-inner>
                <img src="@/assets/phone-icon white.svg" width="100%" class="mx-2" alt="phone-icon">
              </template>
            </v-text-field>
            <v-btn type="submit" block height="48px" :color="valid ? '#FFF07E' : '#FFF07Ec2'"
              class="text-none rounded-pill elevation-0"><span class="black--text font-weight-bold">Sign
                up</span></v-btn>
          </v-form>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { counts } from '../apiurl';

export default {
  data: () => ({
    investactive: [],
    inaid: 1,
    secthreeitems: [
      {
        id: 1,
        title: 'Stocks & ETFs',
        image: require('@/assets/products-sec/Stocks-ETFs.png'),
        tit: "Invest in stocks for longterm wealth",
        sub: "Explore Your Share of Success - Dive into the world of stocks and become a co-owner of leading companies. Discover the power of ownership, potential dividends, and the thrill of watching your investments grow.",
        too: "",
        data: [
          { id: 0, image: require('@/assets/invest/inv-p-l1.png'), tit: "Potential for high returns.", sub: "Investing in stocks for the long term has been proven to be a great way to make high returns in the longer term." },
          { id: 1, image: require('@/assets/invest/inv-p-l2.png'), tit: "Portfolio diversification.", sub: "Traditional investments are no longer offering the ideal return. Making above average return can be easy with diversified equity investing, via stocks and MFs." },
          { id: 2, image: require('@/assets/invest/inv-p-l3.png'), tit: "High Liquidity.", sub: "Unlike bonds and deposits. Stocks and mutual funds offer very high liquidity and near instant price discovery. So your assets are always liquid." },
          { id: 3, image: require('@/assets/invest/inv-p-l4.png'), tit: "Dividend yield", sub: "Passive income has been the trend of the decade. You can also make passive income by dividend investing. Invest in some of the good dividend-paying companies." },
        ]
      },
      {
        id: 2,
        title: 'Mutual funds',
        image: require('@/assets/products-sec/mutual-funds.png'),
        tit: "Mutual Funds offer an effective way to grow your wealth",
        sub: "Enjoy affordability and tax efficiency while growing your wealth. Take advantage of Systematic Investment Plans (SIPs) for a hassle-free journey to financial success. Begin your rewarding investment experience today!",
        too: "",
        data: [
          { id: 0, image: require('@/assets/product/mf/l1.png'), tit: "Professional managed ", sub: "Mutual funds are actively managed by professionals, certified by sebi and often helping you in creating alpha over an index." },
          { id: 1, image: require('@/assets/product/mf/l2.png'), tit: "Tax Efficiency", sub: "Mutual funds are highly tax efficient with indexation. Saves 3-4% avg on tax compared to other investment assets." },
          { id: 2, image: require('@/assets/product/mf/l3.png'), tit: "Systematic Investment Plans", sub: "Smart investment via an investment plan, which tracks and improves allocation of capital to funds based on goals and risk." },
          { id: 3, image: require('@/assets/product/mf/l4.png'), tit: "Direct Investment - Save 2%", sub: "Zebu offers direct mutual funds, helping you save and compound from high expense ratios offered by regular mutual funds." },
          { id: 4, image: require('@/assets/product/mf/l5.png'), tit: "Affordability", sub: "Mutual funds are affordable and you can start saving with Zebu from as low as 500 rupees. Start small and make it huge." },
        ]
      },
      {
        id: 3,
        title: 'IPOs',
        image: require('@/assets/products-sec/ipos.png'),
        tit: "Invest in the upcoming company listings. New opportunities to invest.",
        sub: "Step into the World of New Beginnings: Our IPO (Initial Public Offering) zone unveils exciting opportunities to invest in companies as they make their debut in the stock market. Join us in being part of the early chapters of success stories, and explore the potential growth right from the start. Start your IPO journey with us today!",
        too: "",
        data: [
          { id: 0, image: require('@/assets/product/ipos/l1.png'), tit: "Primary market allocation", sub: "Directly invest in the upcoming companies on the indian stock market. Invest early and participate in their growth story." },
          { id: 1, image: require('@/assets/product/ipos/l2.png'), tit: "Pay easily via UPI", sub: "On applying to an IPO, is just click away. You can now reserve your IPOs bids via UPI." },
        ]
      },
      {
        id: 4,
        title: 'Bonds',
        image: require('@/assets/products-sec/bonds.png'),
        tit: "Prepare to actively engage, strategically plan, and unlock the limitless potential of the future",
        sub: "Explore Government Bonds: A secure haven for your investments. Backed by the strength of the government of India, these bonds offer stability and reliability and a pathway to a balanced portfolio. Step into the realm of Government Bonds and pave your way to financial confidence!",
        too: "",
        data: [
          { id: 0, image: require('@/assets/product/gb/l1.png'), tit: "GOI backed", sub: "All the bonds sold at Zebu are completely backed by the Government of India and verified by all the regulatory bodies." },
          { id: 1, image: require('@/assets/product/gb/l2.png'), tit: "Tax friendly", sub: "Government bonds offer the major tax friendly returns with inflation indexation benefits. Great for long term investors." },
        ]
      },
      {
        id: 5,
        title: 'Gold bonds',
        image: require('@/assets/products-sec/sgb.png'),
        tit: "Get ready to engage, strategize, andUnlock Future Potential",
        sub: "Explore Government Bonds: A secure haven for your investments. Backed by the strength of the government of India, these bonds offer stability and reliability and a pathway to a balanced portfolio. Step into the realm of Government Bonds and pave your way to financial confidence!",
        too: "",
        data: [
          { id: 0, image: require('@/assets/product/sgb/l1.png'), tit: "Benefit from gold rise", sub: "Invest in SGBs and participate in the price growth of gold, without any of the risk of owning actual physical gold." },
          { id: 1, image: require('@/assets/product/sgb/l2.png'), tit: "Earn 2.5%", sub: "SGBs sweeten the deal, by providing additional 2.5% interest on top of the returns from gold price rise for your capital." },
        ]
      }
    ],
    secfouritems: [
      { text: 'Option tools', link: '' },
      { text: 'Powerful charting', link: '' },
      { text: 'Collections', link: '' },
      { text: 'Wishlist', link: '' },
      { text: 'Margin', link: '' },
      { text: 'Theme Investing', link: '' },
      { text: 'RIsk', link: '' },
      { text: 'Diversified', link: '' },
      { text: 'Weighted', link: '' },
    ],
    learnitems: [
      // {
      //   title: 'Webinars',
      //   image: require('@/assets/learn-sec/image 29.png'),
      //   subtitle: '14 Webinars',
      //   click: ''
      // },
      {
        title: 'Blogs',
        image: require('@/assets/learn-sec/image 34.png'),
        subtitle: '0 Blogs',
        click: '/blogs'
      },
      {
        title: 'Videos',
        image: require('@/assets/learn-sec/image 28.png'),
        subtitle: '0 Videos',
        click: '/videos'
      },
      // {
      //   title: 'Digest',
      //   image: require('@/assets/learn-sec/image 30.png'),
      //   subtitle: '14 Digests',
      //   click: ''
      // },
      {
        title: 'News',
        image: require('@/assets/learn-sec/image 31.png'),
        subtitle: '0 today',
        click: '/news'
      },
      {
        title: 'Calculator',
        image: require('@/assets/learn-sec/image 32.png'),
        subtitle: '21 Calculators',
        click: '/calculator'
      },
    ],
    valid: true,
    mobile: '',
    numberis: [
      (v) => !!v || "Your mobile number is required",
      (v) =>
        /^[6-9][0-9]{9}$/.test(v) || "mobile number must be 10 Digit Number",
    ],
  }),
  mounted() {
    this.investactive = this.secthreeitems[0];
    this.learnitems[0].subtitle = counts.blogs_count ? `${counts.blogs_count} Blogs` : '0'
    this.learnitems[1].subtitle = counts.youtube_count ? `${counts.youtube_count} Videos` : '0'
    this.learnitems[2].subtitle = counts.today_news ? `${counts.today_news} today` : '0'
  },

  methods: {
    NumberValid($event) {
      if (($event.keyCode >= 48 && $event.keyCode <= 57) || ($event.keyCode >= 96 && $event.keyCode <= 105)) {
        // 0-9 only
      } else {
        $event.preventDefault();
      }
    },
    setinvestItem(n) {
      this.investactive = [];
      if (n.id == 1) {
        this.inaid = 1;
      } else if (n.id == 2) {
        this.inaid = 2;
      } else if (n.id == 3) {
        this.inaid = 3;
      } else if (n.id == 4) {
        this.inaid = 4;
      } else if (n.id == 5) {
        this.inaid = 5;
      }
      this.investactive = n;

    },
    getCall() {
      if (this.$refs.form.validate() == true) {
        window.open(
          `https://oa.mynt.in/?ref=zws&mobile=${this.mobile}`
        );
        this.$refs.form.reset();

      }

    }
  },
  components: {
    // HelloWorld
  }
}
</script>
