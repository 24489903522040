<template>
  <div class="no-xhide">
    <div data-aos="fade-up" class="pt-4">
      <v-card class="elevation-0 px-16 py-10 support-main-bg rounded-xl pos-rlt d-none d-md-block">
        <p class="white--text fs-50 font-weight-bold mb-6">Government Bonds</p>
        <p class="white--text headline">Explore our Futures section to speculate on <br> upcoming price movements
          and seize <br> opportunities before they arrive.
        </p>

        <div class="pos-abs prd-gb-img text-right">
          <img alt="main-bg.png" src="@/assets/product/gb/main-bg.png" width="90%" />
        </div>
      </v-card>
      <v-card class="elevation-0 px-6 px-sm-8 py-10 support-main-bg rounded-0 d-md-none text-center">
        <p class="white--text fs-38 font-weight-bold mb-6">Government Bonds</p>
        <p class="white--text fs-18">Explore our Futures section to speculate on upcoming price movements
          and seize opportunities before they arrive.
        </p>
      </v-card>
    </div>
    <div class="pt-8 pt-md-16 px-md-16 mb-6">
      <div class="px-16 d-none d-md-block">
        <p class="font-weight-medium black--text"><span class="display-1">Get ready to engage, strategize, and <br>
            Unlock Future Potential</span></p>
        <p class="txt-444 font-weight-light title lh-28">Government Bonds: Your rock-solid investment choice,
          backed by the Indian government's unwavering strength. These bonds provide stability and reliability,
          offering a secure foundation for your investment portfolio. Start your journey toward financial
          confidence with Government Bonds.</p>

        <v-btn target="_blank" rel="noopener noreferrer" href="https://oa.mynt.in/?ref=zws" height="48px"
          color="#2A2A2A" class="text-none rounded-pill elevation-0 mb-10 mt-4"><span
            class="white--text subtitle-1 font-weight-medium px-2">Start investing</span></v-btn>
      </div>
      <div class="px-6 px-sm-8 d-md-none">
        <p class="font-weight-medium black--text"><span class="title">Get ready to engage, strategize, and
            Unlock Future Potential</span></p>
        <p class="txt-444 font-weight-light fs-18 lh-28">Government Bonds: Your rock-solid investment choice,
          backed by the Indian government's unwavering strength. These bonds provide stability and reliability,
          offering a secure foundation for your investment portfolio. Start your journey toward financial
          confidence with Government Bonds.</p>

        <v-btn target="_blank" rel="noopener noreferrer" href="https://oa.mynt.in/?ref=zws" height="48px"
          color="#2A2A2A" block class="text-none rounded-pill elevation-0 mb-10 mt-4"><span
            class="white--text subtitle-1 font-weight-medium px-2">Start investing</span></v-btn>
      </div>

      <div class="px-6 px-sm-8 px-md-16 mb-6">
        <v-card width="100%" data-aos="fade-down" class="box-s1 elevation-0 rounded-xl pt-16 pb-10 d-none d-md-block"
          outlined>
          <div class="pos-rlt">
            <v-row no-glutters>
              <v-col cols="6">
                <div class="px-16">
                  <p class="subtitle-2 text-uppercase ">What's special</p>
                  <p class="secondary-font mb-8">Explore the safety of the bond world.
                  </p>
                  <v-card @click="changeTois(l)" v-for="(l, k) in datas" :key="k" data-aos="fade-down"
                    :color="activelist.id == k ? '#444444' : '#ffffff'" width="100%"
                    class="box-s1 brd-r-16 py-3 px-6 mb-4">
                    <p class="mb-0 title font-weight-regular"
                      :class="activelist.id == k ? 'white--text' : 'black--text'">{{ l.tit }}</p>
                  </v-card>
                </div>
              </v-col>
              <v-col cols="6">
                <div class="px-16">
                  <img data-aos="fade-up" :alt="activelist.image" :src="activelist.image" width="100%">
                  <div data-aos="fade-down">
                    <p class="font-weight-medium headline black--text">{{ activelist.tit }}</p>
                    <p class="font-weight-light mt-3 black--text fs-17">{{ activelist.sub }}</p>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>
        <div class="d-md-none pb-6">
          <p class="subtitle-2 text-uppercase">What's special</p>
          <p class="secondary-fn fs-28 mb-8 lh-32">
          </p>
          <div v-for="(l, k) in datas" :key="k" :class="k != datas.length - 1 ? 'mb-16' : ''">
            <img data-aos="fade-up" :alt="l.image" :src="l.image" width="100%">
            <div data-aos="fade-down">
              <p class="font-weight-medium fs-24 black--text">{{ l.tit }}</p>
              <p class="font-weight-light black--text fs-18">{{ l.sub }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="py-md-16 rounded-xl">
      <v-card data-aos="fade-down" height="380px"
        class="seceight-bg rounded-xl pl-14 elevation-0 d-none d-md-block overflow-hiddens mb-16" color="#E5EBEC"
        width="100%">
        <v-card width="100%" class="d-inline-flex elevation-0 pos-rlt" color="transparent">
          <v-card width="50%" class="elevation-0 py-16" color="transparent">
            <p class="c-secondary-font">
              Exceptional Performance. <br> Exceptional Results.
            </p>
            <p class="txt-444 font-weight-light fs-20 lh-24">
              Mynt is a sophisticated and <br> modern trading application.
            </p>
            <div class="rounded-0 d-inline-flex">
              <v-card target="_blank" rel="noopener noreferrer" height="45px"
                href="https://play.google.com/store/apps/details?id=com.zebu.mynt" color="#000"
                class="mr-2 rounded-lg elevation-0">
                <img src="@/assets/product/scr-mob1.svg" class="px-1 pt-1" alt="playstore-btn-img">
              </v-card>
              <v-card target="_blank" rel="noopener noreferrer" height="45px"
                href="https://apps.apple.com/in/app/mynt-zebu/id1663709767" color="#000"
                class="mr-2 rounded-lg elevation-0">
                <img src="@/assets/product/scr-mob2.svg" class="px-1 pt-1" alt="playstore-btn-img">
              </v-card>
              <v-card target="_" rel="noopener noreferrer" height="45px"
                href="https://zebu.mynt.in/static/Downloads/Apps/ZEBU_INV.exe" color="#000"
                class="rounded-lg elevation-0">
                <img src="@/assets/product/scr-mob3.svg" class="px-1 pt-1" alt="web-btn-img">
              </v-card>
            </div>
          </v-card>
          <v-card width="60%" class="elevation-0 mr-4 overflow-hidden" color="transparent" data-aos="fade-up">
            <img src="@/assets/newimg.png" width="100%" alt="newimg" class="pa-0">
          </v-card>
        </v-card>
      </v-card>
      <v-card data-aos="fade-down"
        class="seceight-bg rounded-0 pt-14 pb-10 mb-10 px-6 px-sm-8 elevation-0 d-md-none text-center" color="#E5EBEC"
        width="100%">
        <!-- <v-card data-aos="fade-down" class="seceight-bg rounded-0 py-14 px-6 px-sm-8 elevation-0 d-md-none text-center"
        color="#E5EBEC" width="100%"> -->
        <v-card width="100%" class="elevation-0" color="transparent">
          <v-card width="100%" class="elevation-0" color="transparent">
            <p class="c-secondary-font">Exceptional Performance. Exceptional Results.</p>
            <p class="txt-444 font-weight-light fs-20 lh-24">
              Mynt is a sophisticated and modern trading application.
            </p>
          </v-card>
          <v-card width="100%" class="elevation-0" color="transparent" data-aos="fade-up">
            <img src="@/assets/newimg.png" width="100%" alt="newimg" class="pa-2">
          </v-card>
          <v-card target="_blank" rel="noopener noreferrer" height="45px"
            href="https://play.google.com/store/apps/details?id=com.zebu.mynt" color="#282828"
            class="rounded-lg elevation-0 mb-3">
            <img src="@/assets/product/scr-mob1.svg" class="px-1 pt-1" alt="playstore-btn-img">
          </v-card>
          <v-card target="_blank" rel="noopener noreferrer" height="45px"
            href="https://apps.apple.com/in/app/mynt-zebu/id1663709767" color="#282828"
            class="rounded-lg elevation-0 mb-3">
            <img src="@/assets/product/scr-mob2.svg" class="px-1 pt-1" alt="playstore-btn-img">
          </v-card>
          <v-card target="_" rel="noopener noreferrer" height="45px"
            href="https://zebu.mynt.in/static/Downloads/Apps/ZEBU_INV.exe" color="#282828"
            class="rounded-lg elevation-0">
            <img src="@/assets/product/scr-mob3.svg" class="px-1 pt-1" alt="web-btn-img">
          </v-card>
        </v-card>
      </v-card>
      <div data-aos="fade-up" class="secineight-bg mb-8 mb-md-0">
        <v-card color="transparent" class="secineight-card pos-rlt mx-auto brd-r-32 elevation-0 d-none d-md-block"
          width="90%">
          <img src="@/assets/sec-eight-bg.webp" width="100%" alt="sec-eight-bg">

          <div class="seceight-ctn pos-abs">
            <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="getCall()">
              <v-row no-gutters class="pt-10">
                <v-col cols="9" class="pr-4">
                  <v-text-field v-model="mobile" @keypress="NumberValid($event)" @keyup.enter="getCall()" :maxlength="10" :rules="numberis"
                    required hide-spin-buttons flat solo background-color="#595959"
                    class="menu-field-seceight elevation-0 mr-3 rounded-pill caption txt-field"
                    label="Enter mobile number to begin">
                    <template #prepend-inner>
                      <img src="@/assets/phone-icon white.svg" width="100%" class="mx-2" alt="phone-icon">
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-btn type="submit" block height="48px" :color="valid ? '#FFF07E' : '#FFF07Ec2'"
                    class="text-none rounded-pill elevation-0"><span class="black--text font-weight-bold">Sign
                      up</span></v-btn>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card>
        <v-card color="#282828" class="secineight-card pos-rlt mx-auto brd-r-32 elevation-0 d-md-none pa-6" width="90%">
          <img src="@/assets/Msec-eight-bg.svg" width="100%" alt="sec-eight-bg">
          <!-- <v-img src="@/assets/Msec-eight-bg.svg" width="100%" alt="sec-eight-bg"></v-img> -->

          <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="getCall()">

            <v-text-field v-model="mobile" @keypress="NumberValid($event)" @keyup.enter="getCall()" :maxlength="10" :rules="numberis" required
              hide-spin-buttons flat solo background-color="#595959"
              class="menu-field-seceight elevation-0 rounded-pill caption txt-field"
              label="Enter mobile number to begin">
              <template #prepend-inner>
                <img src="@/assets/phone-icon white.svg" width="100%" class="mx-2" alt="phone-icon">
              </template>
            </v-text-field>
            <v-btn type="submit" block height="48px" :color="valid ? '#FFF07E' : '#FFF07Ec2'"
              class="text-none rounded-pill elevation-0"><span class="black--text font-weight-bold">Sign
                up</span></v-btn>
          </v-form>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  data: () => ({
    datas: [
      { id: 0, image: require('@/assets/product/gb/l1.png'), tit: "GOI backed", sub: "All the bonds sold at Zebu are completely backed by the Government of India and verified by all the regulatory bodies." },
      { id: 1, image: require('@/assets/product/gb/l2.png'), tit: "Tax friendly", sub: "Government bonds offer the major tax friendly returns with inflation indexation benefits. Great for long term investors." },
      // { id: 3, image: require('@/assets/product/ipos/l2.png'), tit: "Tax Friendly", sub: "Access key information such as company profiles, trading history, technical data and" },
    ],
    activelist: {},

    valid: true,
    mobile: '',
    numberis: [
      (v) => !!v || "Your mobile number is required",
      (v) =>
        /^[6-9][0-9]{9}$/.test(v) || "mobile number must be 10 Digit Number",
    ],
  }),

  mounted() {
    this.activelist = this.datas[0];
  },
  methods: {
    NumberValid($event) {
      if (($event.keyCode >= 48 && $event.keyCode <= 57) || ($event.keyCode >= 96 && $event.keyCode <= 105)) {
        // 0-9 only
      } else {
        $event.preventDefault();
      }
    },
    getCall() {
      if (this.$refs.form.validate() == true) {
        window.open(
          `https://oa.mynt.in/?ref=zws&mobile=${this.mobile}`
        );
        this.$refs.form.reset();
      }
    },
    changeTois(l) {
      this.activelist = l;
    }
  }
}
</script>