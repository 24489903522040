<template>
    <div class="px-0 px-md-14">
        <div>
            <v-card width="100%" class="price-card-sty elevation-0 rounded-c-xl mb-md-6 overflow-hidden">
                <div class="pb-md-8 px-6 px-sm-8 pt-12">
                    <p class="mb-6 font-weight-bold secondary-infont fs-32 lh-32">RD calculator</p>
                    <v-row no-gutters>
                        <v-col cols="12" md="6" class="pr-md-16">
                            <p class="mb-0 title font-weight-regular">Monthly amount</p>
                            <v-text-field type="number" @keyup="keyUpchartupdation()" hide-spin-buttons dense
                                v-model="Monthlyinvestment" block flat solo background-color="#ffffff" min="1"
                                class="calc-field elevation-0 rounded-pill mb-6" prefix="₹" hide-details>
                            </v-text-field>
                            <v-row class="mt-6">
                                <v-col cols="9">
                                    <p class="mb-0 title font-weight-regular">Interest Rate (p.a.)</p>
                                </v-col>
                                <v-col cols="3" class="pt-2">
                                    <v-text-field block flat solo background-color="#ffffff"
                                        class="calc-field elevation-0 rounded-pill" hide-details dense
                                        @keyup="keyUpchartupdation()" type="number" suffix="%" v-model="interestrate"
                                        hide-spin-buttons min="1" max="20" step="1"></v-text-field>
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    <v-slider @change="chartupdation()" v-model="interestrate" ticks="always" tick-size="6"
                                        min="1" max="20" step="1" class='rounded-pill slider-range' track-color="#EEEEEE"
                                        thumb-color="#015FEC" hide-details color='#A3C8FF'></v-slider>
                                </v-col>
                            </v-row>

                            <v-row class="my-6">
                                <v-col cols="9">
                                    <p class="mb-0 title font-weight-regular">Tenure period</p>
                                </v-col>
                                <v-col cols="3" class="pt-2">
                                    <v-text-field block flat solo background-color="#ffffff"
                                        class="calc-field elevation-0 rounded-pill" hide-details dense
                                        @keyup="keyUpchartupdation()" type="number" suffix="Yr" v-model="tenureperiod"
                                        hide-spin-buttons min="1" max="50" step="1"></v-text-field>
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    <v-slider @change="chartupdation()" v-model="tenureperiod" ticks="always" tick-size="6"
                                        min="1" max="50" step="1" class='rounded-pill slider-range' track-color="#EEEEEE"
                                        thumb-color="#015FEC" hide-details color='#A3C8FF'></v-slider>

                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="6">
                            <p class="mb-0 title font-weight-regular">Estimation</p>
                            <v-row>
                                <v-col cols="12" md="7" class="py-4 px-8 px-md-4 mt-6">
                                    <v-card class="elevation-0 rounded-circle pb-2 px-1 brd-c-4" outlined>
                                        <canvas id="myChart"> </canvas>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" md="5">
                                    <v-list-item class="pa-0 mb-8 mt-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#015FEC"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Investment amount
                                            </v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emireport ?
                                                emireport : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="pa-0 mb-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#1D1D1D"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle
                                                class="mb-2 fs-18 font-weight-regular">Est.intrest</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emiammount ?
                                                emiammount : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="pa-0 mb-8">
                                        <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                            color="#6EB94B"></v-card>
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Total
                                                amount</v-list-item-subtitle>
                                            <p class="mb-0 title font-weight-black">&#8377; {{ emiinterest ?
                                                emiinterest : '0.00'
                                            }}</p>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
            <v-card width="100%" class="elevation-0 rounded-c-xl overflow-hidden" outlined>
                <div class="px-6 px-sm-8 px-md-10 py-6">
                    <p class="mb-0 font-weight-bold title">What is an RD?</p>
                    RDs, which stand for "Recurring Deposits," are a type of investment that lets people make monthly
                    payments and save money for the long term. Investors can choose how long they want their deposit to last
                    and how much they want to pay each month as a minimum. RD schemes are typically more flexible than FD
                    schemes, and many people choose to start an account to save money and build up a fund for emergencies.
                    <p class="mb-0 font-weight-bold title">How is the interest on an RD figured out?</p>
                    <p class="mb-6 font-weight-light txt-444"> Major banks add up the interest on RDs every three months.
                        This is how it works:
                        <br><br>
                        M = R[(1+i)^n-1]/(1-(1+i)^(-1/3) )
                        <br><br>
                        M = Maturity Value
                        <br><br>
                        R = Payment every month
                        <br><br>
                        n = How many quarters
                        <br><br>
                        I = Interest rate divided by 400
                        <br><br>
                        So, if you put Rs. 5,000 into RD every month for a year and the interest rate is 8%, your total
                        value
                        will be:
                        <br><br>
                        R = 5000 n = 4 (each year is split into four parts)
                        <br><br>
                        I = 8.00/400
                        <br><br>
                        M = 62,647 rupees in one year
                    </p>
                    <p class="mb-0 font-weight-bold title">
                        The benefits of RD</p>
                    <p class="mb-6 font-weight-light txt-444">
                        RD can be used as security to get a loan. You can borrow up to 80-90% of the amount of your RD.
                        <br><br>
                        Under RD, early withdrawals are allowed. But this might come with a small fee.
                        <br><br>
                        Seniors can get a higher rate of interest through RD schemes.
                        <br><br>
                        Children under the supervision and care of their parents can also open RD accounts.
                        <br><br>
                        The length of time is also flexible, and you can choose anything from 7 days to 10 years.
                        <br><br>
                        You can save money regularly with RD schemes, and the minimum deposit can be as low as RS. 10
                    </p>
                    <p class="mb-0 font-weight-bold title">
                        The tax breaks for RD</p>
                    <p class="mb-6 font-weight-light txt-444">
                        Recurring Deposit schemes are taxed, just like other ways to save on taxes and make investments. If
                        the
                        total interest on an RD is more than Rs. 10,000 in a single financial year, a TDS of 10% is taken
                        out of
                        the returns</p>
                </div>
            </v-card>
        </div>
    </div>
</template>

<script>

import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default {
    data() {
        return {
            Monthlyinvestment: 200000,
            interestrate: 8,
            tenureperiod: 2,

            emireport: 0,
            emiammount: 0,
            emiinterest: 0,

            doughnuts: [],
        }
    },

    mounted() {
        this.compCalculator();
        this.setChart();
    },

    methods: {
        keyUpchartupdation() {
            setTimeout(() => {
                this.compCalculator();
                this.doughnut.destroy();
                this.setChart();
            }, 600);
        },
        chartupdation() {
            this.compCalculator();
            setTimeout(() => {
                this.doughnut.destroy();
                this.setChart();
            }, 600);
        },
        compCalculator() {
            var amt = this.Monthlyinvestment;
            var rate = this.interestrate;
            var year = this.tenureperiod;
            var freq = 4;
            var months = year * 12;
            var investedamt = Math.round(amt * months);
            var maturity = 0;
            for (var i = 1; i <= months; i++) {
                maturity += Math.round(amt * Math.pow((1 + ((rate / 100) / freq)), freq * ((months - i + 1) / 12)));
            }
            var estreturns = Math.round(maturity - investedamt)
            this.emireport = (investedamt.toLocaleString());
            this.emiammount = (estreturns.toLocaleString());
            this.emiinterest = (maturity.toLocaleString());
            this.doughnuts = [investedamt, estreturns];
        },
        setChart() {
            const setdoughnut = document.getElementById("myChart").getContext("2d");
            this.doughnut = new Chart(setdoughnut, {
                type: 'doughnut',
                data: {
                    datasets: [{
                        data: this.doughnuts,
                        backgroundColor: ['#015FEC', '#1D1D1D'],
                        radius: "100%",
                        borderColor: ['#015FEC', '#1D1D1D'],
                        borderRadius: 0,
                        borderWidth: 0,
                        hoverOffset: 4,
                        borderAlign: 'center'
                    }]
                },

                options: {
                    cutout: "70%",
                    // rotation: -90,
                    // circumference: 180,
                    events: [],
                    title: {
                        // display: false,
                    },
                    legend: {
                        // display: false,
                    },
                    layout: {
                        padding: {
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                        }
                    },
                }
            });
        },
    }


}
</script>