<template>
    <div class="no-xhide">
        <div data-aos="fade-up">
            <v-card class="elevation-0 px-16 py-10 support-main-bg rounded-xl d-none d-md-block">
                <p class="white--text fs-50 font-weight-bold mb-6">Depository Investor Charter</p>
                <p class="white--text headline text-capitalize">Investor Charter For Depositories <br> And Depository
                    Participants
                </p>
            </v-card>
            <v-card class="elevation-0 px-6 px-sm-8 py-10 text-center support-main-bg rounded-0 d-md-none">
                <p class="white--text fs-38 lh-38 font-weight-bold mb-6">Depository Investor Charter</p>
                <p class="white--text fs-18 text-capitalize">Investor Charter For Depositories And Depository
                    Participants
                </p>
            </v-card>
        </div>
        <div class="pa-6 px-sm-8 px-md-14 py-md-16 mb-6">
            <p class="mb-0 font-weight-bold title">1. Vision</p>
            <p class="font-weight-light lh-28">Towards making Indian Securities Market - Transparent, Efficient, &
                Investor friendly by providing safe, reliable, transparent and trusted record keeping platform for
                investors to hold and transfer securities in dematerialized form.</p>
            <p class="mb-0 font-weight-bold title">2. Mission</p>
            <p class="font-weight-light lh-28">

            <ul>
                <li>To hold securities of investors in dematerialised form and facilitate its transfer, while ensuring
                    safekeeping of securities and protecting interest of investors.</li>
                <li>To provide timely and accurate information to investors with regard to their holding and transfer of
                    securities held by them.</li>
                <li>To provide the highest standards of investor education, investor awareness and timely services so as
                    to enhance Investor Protection and create awareness about Investor Rights.</li>
            </ul>
            </p>
            <p class="mb-0 font-weight-bold title">3. Details of business transacted by the Depository and Depository
                Participant (DP)</p>
            <p class="font-weight-light lh-28">A Depository is an organization which holds securities of investors
                in electronic form. Depositories provide services to various market participants - Exchanges, Clearing
                Corporations, Depository Participants (DPs), Issuers and Investors in both primary as well as secondary
                markets. The depository carries out its activities through its agents which are known as Depository
                Participants (DP). Details available on the link <a target="_blank" rel="noopener noreferrer" class="mailto-txt font-weight-medium"
                    href="https://www.cdslindia.com/DP/dplist.aspx">https://www.cdslindia.com/DP/dplist.aspx</a></p>

            <p class="mb-0 font-weight-bold title">4. Description of services provided by the Depository through
                Depository
                Participants (DP) to investors</p>
            <p class="font-weight-light lh-28">(1) Basic Services</p>
            <v-card width="100%" class="price-card-sty elevation-0 rounded-xl overflow-hidden mb-6">
                <v-row class="pa-4">
                    <v-col cols="1" class="d-none d-md-block"><span class="font-weight-bold subtitle-1">Sr.no</span></v-col>
                    <v-col cols="5" md="4"><span class="font-weight-bold subtitle-1">Brief about the Activity /
                            Service</span></v-col>
                    <v-col cols="7"><span class="font-weight-bold subtitle-1">Expected Timelines for processing by the
                            DP after receipt of proper documents</span></v-col>
                </v-row>
                <v-divider></v-divider>
                <div v-for="(l, j) in basicservices" :key="j">
                    <v-row class="pa-4">
                        <v-col cols="1" class="d-none d-md-block"><span class="font-weight-regular txt-444">{{ l.sr
                        }}</span></v-col>
                        <v-col cols="5" md="4"><span class="font-weight-regular txt-444">{{ l.activiserv
                        }}</span></v-col>
                        <v-col cols="7"><span class="font-weight-regular txt-444">{{ l.timelines }}</span></v-col>
                    </v-row>
                    <v-divider v-if="basicservices.length - 1 != j"></v-divider>
                </div>
            </v-card>
            <p class="font-weight-light lh-28">(2) Depositories provide special services like pledge, hypothecation,
                internet based services etc. in addition to their core services and these include</p>

            <v-card width="100%" class="price-card-sty elevation-0 rounded-xl overflow-hidden mb-6">
                <v-row class="pa-4">
                    <v-col cols="1" class="d-none d-md-block"><span class="font-weight-bold subtitle-1">Sr.no</span></v-col>
                    <v-col cols="4" md="3"><span class="font-weight-bold subtitle-1">Type of Activity
                            /Service</span></v-col>
                    <v-col cols="8"><span class="font-weight-bold subtitle-1">Brief about the Activity /
                            Service</span></v-col>
                </v-row>
                <v-divider></v-divider>
                <div v-for="(l, j) in depositpovi" :key="j">
                    <v-row class="pa-4">
                        <v-col cols="1" class="d-none d-md-block"><span class="font-weight-regular txt-444">{{ l.sr
                        }}</span></v-col>
                        <v-col cols="4" md="3"><span class="font-weight-regular txt-444">{{ l.typeact }}</span></v-col>
                        <v-col cols="8"><span class="font-weight-regular txt-444" v-html="l.breifabo"></span></v-col>
                    </v-row>
                    <v-divider v-if="depositpovi.length - 1 != j"></v-divider>
                </div>
            </v-card>
            <v-card width="100%" class="price-card-sty elevation-0 rounded-xl overflow-hidden mb-6">
                <v-row class="pa-4">
                    <v-col cols="2" class="d-none d-md-block"><span class="font-weight-bold subtitle-1">Sr.no</span></v-col>
                    <v-col cols="4"><span class="font-weight-bold subtitle-1">Type of Activity /Service</span></v-col>
                    <v-col cols="8" md="6"><span class="font-weight-bold subtitle-1">Brief about the Activity /
                            Service</span></v-col>
                </v-row>
                <v-divider></v-divider>
                <div v-for="(l, j) in emtrtab" :key="j">
                    <v-row class="pa-4">
                        <v-col cols="2" class="d-none d-md-block"><span class="font-weight-regular txt-444">{{ l.sr
                        }}</span></v-col>
                        <v-col cols="4"><span class="font-weight-regular txt-444">{{ l.typeofser }}</span></v-col>
                        <v-col cols="8" md="6"><span class="font-weight-regular txt-444"
                                v-html="l.breifser"></span></v-col>
                    </v-row>
                    <v-divider v-if="emtrtab.length - 1 != j"></v-divider>
                </div>
            </v-card>

            <p class="mb-0 font-weight-bold title">4. Details of Grievance Redressal Mechanism</p>
            <p class="font-weight-light lh-28">(1) The Process of investor grievance redressal</p>
            <v-card width="100%" class="price-card-sty elevation-0 rounded-xl overflow-hidden mb-6">
                <div v-for="(l, j) in detagrievance" :key="j">
                    <v-row class="pa-4">
                        <v-col cols="2" class="d-none d-md-block"><span class="font-weight-regular txt-444">{{ l.sr
                        }}</span></v-col>
                        <v-col cols="5" md="3"><span class="font-weight-regular txt-444">{{ l.invername }}</span></v-col>
                        <v-col cols="7"><span class="font-weight-regular txt-444" v-html="l.content"></span></v-col>
                    </v-row>
                    <v-divider v-if="detagrievance.length - 1 != j"></v-divider>
                </div>
            </v-card>
            <p class="font-weight-light lh-28">(2) <a target="_blank" rel="noopener noreferrer" class='mailto-txt font-weight-light' href='/investordps'>For the
                    Multi-level complaint resolution mechanism
                    available at the Depositories<sup>9</sup></a></p>
            <p class="font-weight-bold title">5. Guidance pertaining to special circumstances related to market
                activities: Termination of the Depository Participant</p>
            <v-card width="100%" class="price-card-sty elevation-0 rounded-xl overflow-hidden mb-6">
                <v-row class="pa-4">
                    <v-col cols="1" class="d-none d-md-block"><span class="font-weight-bold subtitle-1">Sr.no</span></v-col>
                    <v-col cols="6"><span class="font-weight-bold subtitle-1">Type of special
                            circumstances</span></v-col>
                    <v-col cols="6" md="5"><span class="font-weight-bold subtitle-1">Timelines for the Activity/
                            Service</span></v-col>
                </v-row>
                <v-divider></v-divider>
                <div v-for="(l, j) in guidencepre" :key="j">
                    <v-row class="pa-4">
                        <v-col cols="1" class="d-none d-md-block"><span class="font-weight-regular txt-444">{{ l.sr
                        }}</span></v-col>
                        <v-col cols="6"><span class="font-weight-regular txt-444" v-html="l.splcir"></span></v-col>
                        <v-col cols="6" md="5"><span class="font-weight-regular txt-444">{{ l.timelsact }}</span></v-col>
                    </v-row>
                    <v-divider v-if="guidencepre.length - 1 != j"></v-divider>
                </div>
            </v-card>

            <p class="font-weight-light title">6. <a target="_blank" rel="noopener noreferrer" class='mailto-txt font-weight-light' href='/investordps'>Dos and Don'ts
                    for Investors<sup>10</sup></a></p>
            <p class="font-weight-light title">7. <a  target="_blank" rel="noopener noreferrer"  class='mailto-txt font-weight-light' href='/investordps'>Rights of
                    investors<sup>11</sup></a></p>
            <p class="font-weight-light title">8. <a  target="_blank" rel="noopener noreferrer"  class='mailto-txt font-weight-light'
                    href='/investordps'>Responsibilities of Investors<sup>12</sup></a></p>

            <v-card width="100%" class="brk-scr-crd cust-scroll price-card-sty elevation-0 rounded-xl mb-6">
                <div class="cnt-brk-1">
                    <tbody>
                        <tr class="text-left">
                            <th class="px-2 pt-4 va-t  brt-b"><span class="font-weight-bold subtitle-1">SN</span></th>
                            <th class="px-2 pt-4 va-t  brt-b"><span class="font-weight-bold subtitle-1">Received
                                    from</span></th>
                            <th class="px-2 pt-4 va-t  brt-b"><span class="font-weight-bold subtitle-1">carried forward
                                    from previous
                                    month</span>
                            </th>
                            <th class="px-2 pt-4 va-t  brt-b"><span class="font-weight-bold subtitle-1">Received during
                                    the
                                    month</span></th>
                            <th class="px-2 pt-4 va-t  brt-b"><span class="font-weight-bold subtitle-1">Total
                                    pending</span></th>
                            <th class="px-2 pt-4 va-t  brt-b"><span class="font-weight-bold subtitle-1">Resolved</span>
                            </th>
                            <th class="px-2 pt-4 va-t  brt-b"><span class="font-weight-bold subtitle-1">Pending at the
                                    end
                                    of the month**
                                </span></th>
                            <th class="px-2 pt-4 va-t brt-b"><span class="font-weight-bold subtitle-1">Average Resolution
                                    time^
                                    (in days)
                                </span></th>
                        </tr>
                        <tr class="text-left px-3">
                            <th class="px-2  brt-b"><span class="font-weight-bold subtitle-1"></span></th>
                            <th class="px-2  brt-b"><span class="font-weight-bold subtitle-1"></span></th>
                            <th class="px-2  brt-b"><span class="font-weight-bold subtitle-1"></span></th>
                            <th class="px-2  brt-b"><span class="font-weight-bold subtitle-1"></span></th>
                            <th class="px-2  brt-b"><span class="font-weight-bold subtitle-1"></span></th>
                            <th class="px-2  brt-b"><span class="font-weight-bold subtitle-1"></span></th>
                            <th class="px-2 py-3  brt-b">
                                <v-row>
                                    <v-col cols="6"><span class="font-weight-light subtitle-1">Pending for less
                                            than 3
                                            month</span></v-col>
                                    <v-col cols="6">
                                        <span class="font-weight-light subtitle-1">Pending for more than 3
                                            month</span>
                                    </v-col>
                                </v-row>
                            </th>
                            <th class="px-2 brt-b"><span class="font-weight-bold subtitle-1"></span></th>
                        </tr>

                        <tr class="text-center px-3" v-for="(l, j) in responsiinvertab2" :key="j">
                            <th class="px-2 py-3 " :class="responsiinvertab2.length - 1 != j ? 'brt-b' : ''"><span
                                    class="font-weight-regular txt-444">{{ l.sn }}</span></th>
                            <th class="px-2 " :class="responsiinvertab2.length - 1 != j ? 'brt-b' : ''"><span
                                    class="font-weight-regular txt-444">{{ l.recivedfrn }}</span></th>
                            <th class="px-2 py-3 " :class="responsiinvertab2.length - 1 != j ? 'brt-b' : ''"><span
                                    class="font-weight-regular txt-444">{{ l.carrie
                                    }}</span></th>

                            <th class="px-2 py-3 " :class="responsiinvertab2.length - 1 != j ? 'brt-b' : ''"><span
                                    class="font-weight-regular txt-444">{{ l.receidur
                                    }}</span></th>
                            <th class="px-2 py-3 " :class="responsiinvertab2.length - 1 != j ? 'brt-b' : ''"><span
                                    class="font-weight-regular txt-444">{{ l.total }}</span>
                            </th>
                            <th class="px-2 py-3 " :class="responsiinvertab2.length - 1 != j ? 'brt-b' : ''"><span
                                    class="font-weight-regular txt-444">{{ l.resol }}</span></th>

                            <th class="px-2 " :class="responsiinvertab2.length - 1 != j ? 'brt-b' : ''">
                                <v-row>
                                    <v-col cols="6"><span class="font-weight-regular txt-444">{{
                                        l.prndiless }}</span></v-col>
                                    <v-col cols="6">
                                        <span class="font-weight-regular txt-444">{{
                                            l.prndimore }}</span>
                                    </v-col>
                                </v-row>
                            </th>
                            <th class="px-2 py-3" :class="responsiinvertab2.length - 1 != j ? 'brt-b' : ''"><span
                                    class="font-weight-regular txt-444">{{ l.avaer }}</span></th>
                        </tr>
                    </tbody>
                </div>
            </v-card>

            <p class="font-weight-bold">Trend of monthly dissposal of complaints</p>
            <v-card width="100%" class="brk-scr-crd cust-scroll price-card-sty elevation-0 rounded-xl mb-6">
                <div class="cnt-brk-1">
                    <v-row class="pa-4">
                        <v-col cols="1"><span class="font-weight-bold subtitle-1">Sr.no</span></v-col>
                        <v-col cols="2"><span class="font-weight-bold subtitle-1">Month</span></v-col>
                        <v-col cols="3"><span class="font-weight-bold subtitle-1">Carried forward from previous
                                month</span></v-col>
                        <v-col cols="2"><span class="font-weight-bold subtitle-1">Received</span></v-col>
                        <v-col cols="2"><span class="font-weight-bold subtitle-1">Resolved* </span></v-col>
                        <v-col cols="2"><span class="font-weight-bold subtitle-1">pending**</span></v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <div v-for="(l, j) in trendmontab3" :key="j">
                        <v-row class="pa-4">
                            <v-col cols="1"><span class="font-weight-regular txt-444">{{ l.sn }}</span></v-col>
                            <v-col cols="2"><span class="font-weight-regular txt-444">{{ l.month }}</span></v-col>
                            <v-col cols="3"><span class="font-weight-regular txt-444">{{ l.carrfor }}</span></v-col>
                            <v-col cols="2"><span class="font-weight-regular txt-444">{{ l.recived }}</span></v-col>
                            <v-col cols="2"><span class="font-weight-regular txt-444">{{ l.resolve }}</span></v-col>
                            <v-col cols="2"><span class="font-weight-regular txt-444">{{ l.pending }}</span></v-col>

                        </v-row>
                        <v-divider v-if="trendmontab3.length - 1 != j"></v-divider>
                    </div>
                </div>
            </v-card>

            <p class="font-weight-light lh-28">*should include complaints of previous resolved in the current month ,any.
            </p>

            <p class="font-weight-light lh-28">**should include total complaints pending as on last day of month,if any
                ^Average resolution time is the sum total of time taken to resolved each complaints in the current month
                divided by total number of complaints in the cureent month.</p>

            <p class="font-weight-bold">Trend of annual disposal of complaints</p>
            <v-card width="100%" class="brk-scr-crd cust-scroll price-card-sty elevation-0 rounded-xl mb-6">
                <div class="cnt-brk-1">
                    <v-row class="pa-4">
                        <v-col cols="1"><span class="font-weight-bold subtitle-1">Sr.no</span></v-col>
                        <v-col cols="2"><span class="font-weight-bold subtitle-1">Year</span></v-col>
                        <v-col cols="3"><span class="font-weight-bold subtitle-1">Carried forward from previous
                                year</span></v-col>
                        <v-col cols="2"><span class="font-weight-bold subtitle-1">Received during the year</span></v-col>
                        <v-col cols="2"><span class="font-weight-bold subtitle-1">Resolved during the year* </span></v-col>
                        <v-col cols="2"><span class="font-weight-bold subtitle-1">pending at the end of the
                                year</span></v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <div v-for="(l, j) in trendannutab4" :key="j">
                        <v-row class="pa-4">
                            <v-col cols="1"><span class="font-weight-regular txt-444">{{ l.sn }}</span></v-col>
                            <v-col cols="2"><span class="font-weight-regular txt-444">{{ l.year }}</span></v-col>
                            <v-col cols="3"><span class="font-weight-regular txt-444">{{ l.carrfowa }}</span></v-col>
                            <v-col cols="2"><span class="font-weight-regular txt-444">{{ l.receivedur }}</span></v-col>
                            <v-col cols="2"><span class="font-weight-regular txt-444">{{ l.resoldur }}</span></v-col>
                            <v-col cols="2"><span class="font-weight-regular txt-444">{{ l.pendingat }}</span></v-col>

                        </v-row>
                        <v-divider v-if="trendannutab4.length - 1 != j"></v-divider>
                    </div>
                </div>
            </v-card>
        </div>
    </div>
</template>

<script>
export default {

    data: () => ({
        basicservices: [
            {
                sr: '1',
                activiserv: 'Dematerialization of securities',
                timelines: '7 days',
            },
            {
                sr: '2',
                activiserv: 'Rematerialization of securities',
                timelines: '7 days',
            },
            {
                sr: '3',
                activiserv: 'Mutual Fund Conversion / Destatementization',
                timelines: '5 days',
            },
            {
                sr: '4',
                activiserv: 'Re-conversion / Restatementisation of Mutual fund units',
                timelines: '7 days',
            },
            {
                sr: '5',
                activiserv: 'Transmission of securities',
                timelines: '7 days',
            },
            {
                sr: '6',
                activiserv: 'Registering pledge request',
                timelines: '15 days',
            },
            {
                sr: '7',
                activiserv: 'Closure of demat account',
                timelines: '30 days',
            },
            {
                sr: '8',
                activiserv: 'Settlement Instruction',
                timelines: 'Depositories to accept physical DIS for pay-in of securities upto 4 p.m and DIS in electronic form upto 6 p.m on T+1 day',
            }
        ],
        depositpovi: [
            {
                sr: "1",
                typeact: "Value Added Services",
                breifabo: "Depositories also provide value added services such as <br>a. <a  target='_blank' rel='noopener noreferrer'  class='mailto-txt font-weight-medium' href='/investordps'>Basic Services Demat Account(BSDA)<sup>1</sup></a><br>b. <a target='_blank' rel='noopener noreferrer' class='mailto-txt font-weight-medium' href='/investordps'>Transposition cum dematerialization<sup>2</sup></a><br>c. <a target='_blank' rel='noopener noreferrer' class='mailto-txt font-weight-medium' href='/investordps'> Linkages with Clearing System<sup>3</sup></a><br>d. Distribution of cash and non-cash corporate benefits (Bonus, Rights, IPOs etc.), stock lending, demat of NSC / KVP, demat of warehouse receipts etc."
            },
            {
                sr: "2",
                typeact: "Consolidated Account statement (CAS)",
                breifabo: "CAS is issued 10 days from the end of the month (if there were transactions in the previous month) or half yearly (if no transactions)."
            },
            {
                sr: "3",
                typeact: "Digitalization of services provided by the depositories",
                breifabo: "Depositories offer below technology solutions and e-facilities to their demat account holders through DPs:"
            },
        ],
        emtrtab: [
            {
                sr: "",
                typeofser: "",
                breifser: "a. <a class='mailto-txt font-weight-medium' rel='noopener noreferrer' href='/investordps'>E-account opening</a><br> b. <a target='_blank' rel='noopener noreferrer' class='mailto-txt font-weight-medium' href='/investordps'>Online instructions for execution</a><br> c. <a target='_blank' rel='noopener noreferrer' class='mailto-txt font-weight-medium' href='/investordps'>e-DIS / Demat Gateway</a><br>d. <a target='_blank' rel='noopener noreferrer' class='mailto-txt font-weight-medium' href='/investordps'>e-CAS facility</a><br> e. <a target='_blank' rel='noopener noreferrer' class='mailto-txt font-weight-medium' href='/investordps'> Miscellaneous services</a><br>",
            }
        ],
        detagrievance: [
            {
                sr: '1',
                invername: "Investor Complaint/ Grievances",
                content: "<p class='mb-0 font-weight-light lh-28'>Investor can lodge complaint/ grievance against the Depository/DP in the following ways:</p><br><p class='mb-0 font-weight-light lh-28'>a. Electronic mode -</p><br><p class='mb-0 font-weight-light lh-28'>(i) SCORES (a web based centralized grievance redressal system of SEBI)</p><a target='_blank' rel='noopener noreferrer' class='mailto-txt font-weight-medium' href='https://www.scores.gov.in/scores/Welcome.html'>https://www.scores.gov.in/scores/Welcome.html</a> <br><br> <p class='mb-0 font-weight-light lh-28'>(ii) Respective Depository's web portal dedicated for the filing of compliant</p><a target='_blank' rel='noopener noreferrer' class='mailto-txt font-weight-medium' href='https://www.cdslindia.com/Footer/grievances.aspx'>https://www.cdslindia.com/Footer/grievances.aspx</a> <br><br> <p class='mb-0 font-weight-light lh-28'>(iii) Emails to designated email IDs of Depository</p><a target='_blank' rel='noopener noreferrer' class='mailto-txt font-weight-medium' href='mailto: complaints@cdslindia.com'>complaints@cdslindia.com</a> <br><br> <p class='mb-0 font-weight-light lh-28'>b) Offline mode</p><a target='_blank' rel='noopener noreferrer' class='mailto-txt font-weight-medium' href='mailto: complaints@cdslindia.com'>complaints@cdslindia.com</a> <br><br> <p class='mb-0 font-weight-light lh-28'>The complaints/ grievances lodged directly with the Depository shall be resolved within 30 days.</p>"
            },
            {
                sr: '2',
                invername: "Investor Grievance Redressal Committee of Depository",
                content: " <p class='mb-0 font-weight-light lh-28'>If no amicable resolution is arrived, then the Investor has the option to refer the complaint/ grievance to the Grievance Redressal Committee (GRC) of the Depository. Upon receipt of reference, the GRC will endeavor to resolve the complaint/ grievance by hearing the parties, and examining the necessary information and documents.</p>",
            },
            {
                sr: '3',
                invername: "Arbitration proceedings",
                content: " <p class='mb-0 font-weight-light lh-28'>The Investor may also avail the arbitration mechanism set out in the Byelaws and Business Rules/Operating Instructions of the Depository in relation to any grievance, or dispute relating to depository services. The arbitration reference shall be concluded by way of issue of an arbitral award within 4 months from the date of appointment of arbitrator(s).</p>",
            },
        ],
        guidencepre: [
            {
                sr: "1",
                splcir: "<ul><li>Depositories to terminate the participation in case a participant no longer meets the eligibility criteria and/or any other grounds as mentioned in the bye laws like suspension of trading member by the Stock Exchanges.</li><li>Participant surrenders the participation by its own wish.</li></ul>",
                timelsact: "Client will have a right to transfer all its securities to any other Participant of its choice without any charges for the transfer within 30 days from the date of intimation by way of letter/email.",
            }
        ],
        responsiinvertab2: [
            {
                sn: "1",
                recivedfrn: "Directly from investor",
                carrie: "0",
                receidur: "0",
                total: "0",
                resol: "0",
                prndiless: '0',
                prndimore: "0",
                avaer: "0",
            },
            {
                sn: "2",
                recivedfrn: "SEBI(Scores)",
                carrie: "0",
                receidur: "0",
                total: "0",
                resol: "0",
                prndiless: '0',
                prndimore: "0",
                avaer: "0",
            },
            {
                sn: "3",
                recivedfrn: "Depositories",
                carrie: "0",
                receidur: "0",
                total: "0",
                resol: "0",
                prndiless: '0',
                prndimore: "0",
                avaer: "0",
            },
            {
                sn: "4",
                recivedfrn: "OtherSources(if any)",
                carrie: "0",
                receidur: "0",
                total: "0",
                resol: "0",
                prndiless: '0',
                prndimore: "0",
                avaer: "0",
            },
            {
                sn: "5",
                recivedfrn: "Grand Total",
                carrie: "0",
                receidur: "0",
                total: "0",
                resol: "0",
                prndiless: '0',
                prndimore: "0",
                avaer: "0",
            },
        ],
        trendmontab3: [
            {
                sn: '1',
                month: 'Jan/22',
                carrfor: "0",
                recived: "0",
                resolve: "0",
                pending: "0",
            },
            {
                sn: '2',
                month: 'Feb/22',
                carrfor: "0",
                recived: "0",
                resolve: "0",
                pending: "0",
            },
            {
                sn: '3',
                month: 'Mar/22',
                carrfor: "0",
                recived: "0",
                resolve: "0",
                pending: "0",
            },
            {
                sn: '4',
                month: 'Apr/22',
                carrfor: "0",
                recived: "0",
                resolve: "0",
                pending: "0",
            },
            {
                sn: '5',
                month: 'May/22',
                carrfor: "0",
                recived: "0",
                resolve: "0",
                pending: "0",
            },
            {
                sn: '6',
                month: 'Jun/22',
                carrfor: "0",
                recived: "0",
                resolve: "0",
                pending: "0",
            },
            {
                sn: '',
                month: 'Grand Total',
                carrfor: "0",
                recived: "0",
                resolve: "0",
                pending: "0",
            },
        ],
        trendannutab4: [
            {
                sn: '1',
                year: "2016-17",
                carrfowa: '0',
                receivedur: "4",
                resoldur: "4",
                pendingat: "0",
            },
            {
                sn: '2',
                year: "2017-18",
                carrfowa: '0',
                receivedur: "0",
                resoldur: "0",
                pendingat: "0",
            },

            {
                sn: '3',
                year: "2018-19",
                carrfowa: '0',
                receivedur: "1",
                resoldur: "1",
                pendingat: "0",
            },
            {
                sn: '4',
                year: "2019-20",
                carrfowa: '0',
                receivedur: "0",
                resoldur: "0",
                pendingat: "0",
            },
            {
                sn: '5',
                year: "2020-21",
                carrfowa: '0',
                receivedur: "1",
                resoldur: "1",
                pendingat: "0",
            },
            {
                sn: '',
                year: 'Grand Total',
                carrfowa: "0",
                receivedur: "6",
                resoldur: "6",
                pendingat: "0",
            },

        ],
    }),
}
</script>