<template>
    <div class="no-xhide">
        <div data-aos="fade-up" class="pos-rlt pt-md-6 mb-16 d-none d-md-block">
            <v-card class="elevation-0 px-16 py-10 rounded-xl pos-rlt" color="#E5EBEC">
                <p class="fs-50 font-weight-bold mb-0">Zebu Blogs</p>
                <div class="d-inline-flex mb-6">
                    <v-btn @click="activePage(i = p)" v-for="(p, b) in blogitems" :key="b" medium color="#666666"
                        class="rounded-pill mr-2 text-none brd-2" outlined>
                        {{ p.txt }}
                    </v-btn>
                </div>
                <BlogSearch @setParentComponentDetails="setDetailsForComponent"></BlogSearch>
       
                <div>
                    <img class="pos-abs calc-img text-right" alt="main-bg.png" src="@/assets/blog/main-bg.png" width="42%" />
                </div>
                <div class="pos-abs learn-img">
                    <img alt="learn-bg.svg" src="@/assets/learn-bg.svg" width="172px" />
                </div>
            </v-card>
        </div>

        <div class="py-6 pb-md-16">
            <v-container class="px-0 px-md-16">
                <div class="mx-auto text-md-center px-6 px-sm-8 px-md-12">
                    <p class="mb-2 subtitle-2 font-weight-regular"><span class="cursor-p" @click="toHome()">Zebu
                            Blogs</span> → <span class="cursor-p" @click="catBlog()">{{ pagename.tit ? pagename.tit : 'All'
                            }}</span> →
                        Blog</p>

                    <p class="mb-6 font-weight-bold secondary-infont fs-32 d-none d-md-block">
                        {{ detailblog.attributes ? detailblog.attributes.blog_title : '' }}</p>
                        <p class="mb-6 font-weight-bold secondary-infont fs-28 d-md-none">
                        {{ detailblog.attributes ? detailblog.attributes.blog_title : '' }}</p>
                    <v-divider></v-divider>
                    <v-row no-glutters class="py-2">
                        <v-col cols="6" class="text-left">
                            <p class="mb-0 subtitle-2 font-weight-regular">by {{ detailblog.attributes ?
                                detailblog.attributes.blog_author : '' }}</p>
                        </v-col>
                        <v-col cols="6" class="text-right">
                            <p class="mb-0 subtitle-2 font-weight-regular">Last Updated: {{ detailblog.attributes ?
                                detailblog.attributes.publishedAt : '' }}
                            </p>
                        </v-col>
                    </v-row>
                    <v-card class="rounded-0 elevation-0">
                        <img :src="detailblog.attributes ? detailblog.attributes.img : ''" width="100%"
                            :alt="detailblog.attributes ? detailblog.attributes.img : ''">
                    </v-card>
                    <div class="text-left py-4 px-2 px-sm-4"
                        v-html="detailblog.attributes ? detailblog.attributes.blog_description : ''">
                    </div>
                    <v-divider></v-divider>
                </div>

            </v-container>
        </div>
    </div>
</template>
      
<script>
import axios from "axios";
import { strapi } from '../../apiurl'
import BlogSearch from '../Blogs/BlogSearch.vue'

export default {

    data: () => ({
        page: 1,
        pagecount: 0,
        catpagecount: 0,

        blogitems: [],

        pagename: {},

        allblogs: [],
        catblogs: [],

        detailblog: {},
        mainblog: {},
        mainside: [],
        mainslide: [],
        imgurl: 'http://192.168.5.22:1337',
        // imgurl: 'https://strapi.zebull.in',

    }),
    components: {
        BlogSearch
    },
    mounted() {
        let b = this.$route.params;
        if (b.id) {
            localStorage.setItem("50bf", JSON.stringify(b))
        } else {
            b = JSON.parse(localStorage.getItem("50bf"));
            if (b) {
                // 
            } else {
                this.$router.push('/blogs');
            }
        }
        this.pagename = b.attributes.pagename;
        this.detailblog = b;
        this.getCategorie();
        this.scrollBehavior();
    },

    methods: {
        scrollBehavior() {
            window.scrollTo(0, 0);
        },
        activePage(i) {
            this.$router.push({ name: 'blog category', params: i });
        },
        getCategorie() {
            this.blogitems = [];
            this.blogitems = [{ tit: 'All', txt: 'All', btn: 'Show all blogs', val: 'all', id: 1 }]
            let config = {
                method: 'get',
                url: `${strapi}/blog-categories`,
            };

            let axiosThis = this;
            axios.request(config)
                .then((response) => {
                    if (response.data.data) {
                        let data = response.data.data;
                        for (let c = 0; c < data.length; c++) {
                            let cconfig = {
                                method: 'get',
                                url: `${strapi}/blogs?filters[blog_categories][name][$eq]=${data[c].attributes.name}&sort[0]=blogdate:desc&pagination[pageSize]=${4}&populate[blog_image]=*`,
                            };
                            let catdata = [];
                            axios.request(cconfig)
                                .then((response) => {
                                    if (response.data.data) {
                                        let datas = response.data.data
                                        for (let i = 0; i < datas.length; i++) {
                                            datas[i].attributes['img'] = `${strapi}${datas[i].attributes.blog_image.data[0].attributes.url}`;
                                            datas[i].attributes.publishedAt = `${new Date(datas[i].attributes.publishedAt).toDateString().slice(3)} ${new Date(datas[i].attributes.publishedAt).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })} IST`;
                                            catdata.push(datas[i]);
                                        }
                                    } else {
                                        catdata = []
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                            axiosThis.blogitems.push({ tit: data[c].attributes.name, txt: data[c].attributes.name.split(' ', 1)[0], btn: `More ${data[c].attributes.name} blog`, val: data[c].attributes.name.slice(0, 3), id: c + 2, data: catdata })
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        catBlog() {
            var item = this.blogitems.find(o => o.val === this.pagename.val);
            if (item == undefined) {
                this.pagename = this.blogitems.find(o => o.val === 'All');
            } else {
                this.pagename = item;
            }
            var i = this.pagename;
            this.activePage(i)
        },
        toHome() {
            this.$router.push('/blogs');
        },
        setDetailsForComponent() {
            this.detailblog = {};
            let b = JSON.parse(localStorage.getItem("50bf"));
            this.pagename = b.attributes.pagename;
            this.detailblog = b;
            this.getCategorie();
            this.scrollBehavior();
        }
    }
}
</script>