<template>
    <div class="px-0 px-md-14">
        <div>
            <v-card width="100%" class="price-card-sty elevation-0 rounded-c-xl mb-md-6 overflow-hidden">
                <div class="pb-md-8 px-6 px-sm-8 pt-12">
                    <p class="mb-6 font-weight-bold secondary-infont fs-32 lh-32">HRA calculator</p>
                    <v-row no-gutters class="mb-6 mb-md-0">
                        <v-col cols="12" md="6" class="pr-md-16">
                            <p class="mb-0 title font-weight-regular">Monthly salary(Basic + DA)</p>
                            <v-text-field type="number" @keyup="atChange()" hide-spin-buttons dense
                                v-model="Monthlyinvestment" block flat solo background-color="#ffffff" min="1"
                                class="calc-field elevation-0 rounded-pill mb-6" prefix="₹" hide-details>
                            </v-text-field>

                            <p class="mb-0 title font-weight-regular">HRA received</p>
                            <v-text-field type="number" @keyup="atChange()" hide-spin-buttons dense v-model="interestrate"
                                block flat solo background-color="#ffffff" min="1"
                                class="calc-field elevation-0 rounded-pill mb-6" prefix="₹" hide-details>
                            </v-text-field>

                            <p class="mb-0 title font-weight-regular">Total rent paid</p>
                            <v-text-field type="number" @keyup="atChange()" hide-spin-buttons dense v-model="tenureperiod"
                                block flat solo background-color="#ffffff" min="1"
                                class="calc-field elevation-0 rounded-pill mb-6" prefix="₹" hide-details>
                            </v-text-field>

                        </v-col>
                        <v-col cols="12" md="6">
                            <p class="title font-weight-regular">Estimation</p>
                            <v-list-item class="pa-0 mb-8">
                                <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                    color="#015FEC"></v-card>
                                <v-list-item-content class="pa-0">
                                    <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Exempted HRA
                                    </v-list-item-subtitle>
                                    <p class="mb-0 title font-weight-black">&#8377; {{ emireport ?
                                        emireport : '0.00'
                                    }}</p>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="pa-0">
                                <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                    color="#1D1D1D"></v-card>
                                <v-list-item-content class="pa-0">
                                    <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Taxable HRA
                                    </v-list-item-subtitle>
                                    <p class="mb-0 title font-weight-black">&#8377; {{ emiammount ?
                                        emiammount : '0.00'
                                    }}</p>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
            <v-card width="100%" class="elevation-0 rounded-c-xl overflow-hidden" outlined>
                <div class="px-6 px-sm-8 px-md-10 py-6">
                    <p class="mb-0 font-weight-bold title">What is HRA?</p>
                    <p class="mb-6 font-weight-light txt-444">The house rent allowance (HRA) is an essential part of your
                        compensation. All employers must provide HRA as housing rental expense compensation. However, many
                        of us are unaware that we can also save tax on it. The HRA amount is determined by factors such
                        as the employee's wage structure, actual salary, and city of residence. Using our HRA calculator,
                        you may determine your tax burden if you are a salaried employee who rents a residence.
                    </p>
                    <p class="mb-0 font-weight-bold title">What is HRA calculator?</p>
                    <p class="mb-6 font-weight-light txt-444">Over the past decade or so, the cost of living has
                        increased significantly in the majority of Indian cities. This is partially the result of a rise in
                        disposable income in addition to inflation. In order to protect the well-being of their employees,
                        many businesses pay a House Rent Allowance or HRA to those who reside in rented housing. This HRA
                        calculator can assist you in determining your allowance amount.
                    </p>

                    <p class="mb-0 font-weight-bold title">How is HRA calculated?</p>
                    <p class="mb-6 font-weight-light txt-444">Your wage largely determines your HRA. According to income
                        tax regulations, the tax-exempt portion of the HRA (House Rent Allowance) is the lowest sum of the
                        following:
                        <br><br>
                    <ul>
                        <li>Actual HRA salary component</li>
                        <li>50 percent of basic income if he resides in Delhi, Chennai, Kolkata, or Mumbai; 40 percent in
                            all other cities.</li>
                        <li>Actual rent paid minus 10% of fundamental salary</li>
                    </ul>
                    </p>
                </div>
            </v-card>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            Monthlyinvestment: 10000,
            interestrate: 5000,
            tenureperiod: 10,
            emireport: 0,
            emiammount: 0,
            emiinterest: 0,

        }
    },

    mounted() {
        this.compCalculator();
    },

    methods: {
        atChange() {
            // setTimeout(() => {
            this.compCalculator();
            // }, 600);
        },
        compCalculator() {
            var salry = parseFloat(this.Monthlyinvestment);
            var hrarecived = parseFloat(this.interestrate);
            var totalrentpaid = parseFloat(this.tenureperiod);

            var diff = hrarecived + (salry * 0.1)
            if (totalrentpaid >= diff) {
                this.emireport = hrarecived;
                this.emiammount = 0;
            } else {
                var hratax = totalrentpaid - (salry * 0.1)
                var extempted = Math.max(0, hratax);
                var taxable = -(hratax) + hrarecived;
                if (taxable > hrarecived) {
                    this.emiammount = hrarecived;
                } else {
                    this.emiammount = taxable;
                }
                this.emireport = extempted;
            }
        }
    }


}
</script>