<template>
    <div class="no-xhide">
        <div data-aos="fade-up" class="pt-md-6">
            <v-card class="elevation-0 px-16 py-10 rounded-xl pos-rlt d-none d-md-block" color="#E5EBEC">
                <p class="fs-50 font-weight-bold mb-0">Videos</p>
                <p class="headline">Intriguing videos for the investor in you. Easy <br> to understand videos to help with
                    everyday.
                </p>

                <v-autocomplete @change="videoShow()" item-text="title" item-value="videoid" append-icon=""
                    :items="allvideos" :disabled="isLoading" :loading="isLoading" v-model="videois" block flat solo
                    background-color="#ffffff" clearable return-object
                    class="search-field elevation-0 mr-3 rounded-pill caption" label="Search videos" hide-details>
                    <template #prepend-inner>
                        <img src="@/assets/search-icon.svg" width="100%" class="mx-2" alt="search-icon">
                    </template>
                </v-autocomplete>
                <div>
                    <img class="pos-abs calc-img text-right" alt="main-bg.png" src="@/assets/Video/main-bg.png" width="45%" />
                </div>
                <div class="pos-abs learn-img">
                    <img alt="learn-bg.svg" src="@/assets/learn-bg.svg" width="172px" />
                </div>
            </v-card>
            <v-card class="elevation-0 px-6 px-sm-8 py-10 rounded-0 text-center d-md-none" color="#E5EBEC">
                <p class="fs-38 font-weight-bold">Videos</p>
                <p class="fs-18 mb-8">Intriguing videos for the investor in you. Easy <br> to understand videos to help with
                    everyday.
                </p>
                <v-autocomplete @change="videoShow()" item-text="title" item-value="videoid" append-icon=""
                    :items="allvideos" :disabled="isLoading" :loading="isLoading" v-model="videois" block flat solo
                    background-color="#ffffff" clearable return-object class="elevation-0 rounded-pill caption"
                    label="Search videos" hide-details>
                    <template #prepend-inner>
                        <img src="@/assets/search-icon.svg" width="100%" class="mx-2" alt="search-icon">
                    </template>
                </v-autocomplete>
            </v-card>
        </div>
        <div class="pt-4">
            <router-view />
        </div>
        <div>
            <v-dialog v-model="videodialog" class="elevation-0 my-0" content-class="elevation-0">
                <v-container class="pa-md-0" fill-height>
                    <v-card color="transparent" class="video-frame rounded-md mx-0 mx-md-auto elevation-0 pb-0 mb-0">
                        <v-toolbar color="transparent" dense class="tool-dialog elevation-0">
                            <div>
                                <v-list-item class="pl-0 d-none d-md-block">
                                    <v-list-item-content class="pt-1">
                                        <v-list-item-title class="font-weight-bold title white--text">{{ playvideo ?
                                            playvideo.title : null }}</v-list-item-title>
                                        <p class="mb-0 white--text">{{ playvideo ? playvideo.view : null }} views <v-icon
                                                color="#fff" size="8">mdi-checkbox-blank-circle</v-icon> {{ playvideo ?
                                                    playvideo.ago : null }}</p>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item class="pl-0 d-md-none">
                                    <v-list-item-content class="pt-1">
                                        <v-list-item-title class="font-weight-bold fs-14 white--text">{{ playvideo ?
                                            playvideo.title : null }}</v-list-item-title>
                                        <p class="mb-0 white--text fs-12">{{ playvideo ? playvideo.view : null }} views
                                            <v-icon color="#fff" size="8">mdi-checkbox-blank-circle</v-icon> {{ playvideo ?
                                                playvideo.ago : null }}
                                        </p>
                                    </v-list-item-content>
                                </v-list-item>
                            </div>
                            <v-spacer></v-spacer>
                            <v-icon @click="videoClose()" color="#ffffff">mdi-close</v-icon>
                        </v-toolbar>
                        <div>
                            <iframe class="rounded-lg video-dialog" type="text/html"
                                :src="`https://www.youtube.com/embed/${playvideo ? playvideo.videoid : null}?autoplay=1&amp;modestbranding=1`"
                                frameborder="0"
                                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen></iframe>
                        </div>
                        <div class="pos-abs z-in1 trans-cent abs-post4">
                            <v-progress-circular v-if="videodialog" size="80" :width="4" indeterminate
                                color="#ffffff"></v-progress-circular>
                        </div>
                    </v-card>
                </v-container>
            </v-dialog>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { sessapi } from '../../apiurl'

export default {
    data: () => ({
        playvideo: null,
        videodialog: false,
        page: 1,
        allvideos: [],
        videois: null,
        isLoading: null,
    }),
    mounted() {
        this.getVideos();
        this.videoClose();
    },
    methods: {

        getVideos() {
            this.isLoading = true;
            this.allvideos = []
            let config = {
                method: 'post',
                url: `${sessapi}/getallvideos?pagecount=${this.page}&pageSize=1095`,
            };

            let axiosThis = this;
            axios.request(config)
                .then((response) => {
                    if (response.data.data) {
                        let data = response.data.data
                        for (let v = 0; v < data.length; v++) {
                            data[v]['isdate'] = `${new Date(data[v].uploadtime).toDateString().slice(3)} ${new Date(data[v].uploadtime).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })} IST`;
                            data[v]['ago'] = axiosThis.differentDate(data[v].uploadtime);
                            data[v]['view'] = axiosThis.differentView(Number(data[v].viewCount));
                            axiosThis.allvideos.push(data[v]);
                        }
                    }
                    axiosThis.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        differentView(view) {
            return `${view > 999 ? Math.round(view / 1000) : view > 999999 ? Math.round(view / 1000000) : view > 999999999 ? Math.round(view / 1000000000) : view} ${view > 999 ? 'K' : view > 999999 ? 'M' : view > 999999999 ? 'B' : ''}`
        },
        differentDate(date) {
            var date1 = new Date(date);
            var date2 = new Date();
            var ov = Math.abs(date2.getTime() - date1.getTime())
            var mt = Math.round(ov / 3600000)
            var dd = mt > 60 ? Math.round(mt / 24) : 0;
            var mm = dd > 30.4166667 ? Math.round(dd / 30.4166667) : 0;
            var yy = mm > 12 ? Math.round(dd / 365) : 0;
            return `${yy != 0 ? yy : mm != 0 ? mm : dd != 0 ? dd : mt != 0 ? mt : 0} ${yy != 0 ? yy > 1 ? 'years' : 'year' : mm != 0 ? mm > 1 ? 'months' : 'month' : dd != 0 ? dd > 1 ? 'days' : 'day' : mt > 1 ? 'hours' : 'minutes'} ago`;
        },
        videoShow() {
            if (this.videois) {
                this.playvideo = this.videois;
                this.videois = null;
                this.videodialog = true;
            } else {
                this.videoClose();
            }
        },
        videoClose() {
            this.videodialog = false;
            this.videois = null;
            this.playvideo = [];
        },
    }
}
</script>