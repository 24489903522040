<template>
    <div class="no-xhide">
        <div data-aos="fade-up">
            <v-card class="elevation-0 px-16 py-10 support-main-bg rounded-xl d-none d-md-block">
                <p class="white--text headline font-weight-bold mb-6">INVESTOR CHARTER FOR DEPOSITORIES <br> AND DEPOSITORY
                    PARTICIPANTS</p>
       
            </v-card>
            <v-card class="elevation-0 px-6 px-sm-8 py-10 support-main-bg rounded-0 d-md-none text-center">
                <p class="white--text fs-18 font-weight-bold mb-6">INVESTOR CHARTER FOR DEPOSITORIES <br> AND DEPOSITORY
                    PARTICIPANTS</p>
           
            </v-card>
        </div>
        <div class="pa-6 px-sm-8 px-md-14 py-md-16 mb-6">
            <p class="font-weight-light lh-28">This document contains the contents pertaining to the qualifier <a
                    target="_blank" rel="noopener noreferrer" class="mailto-txt" href="https://www.cdslindia .com/Investors /InvestorCha rter.html">
                    “https://www.cdslindia .com/Investors
                    /InvestorCha rter.html”</a> in the Investor Charter main document. The same is to be made available by
                the Depositories on their websites and web-links to the same is to be provided for incorporation in the
                Investor Charter.<br>
                For reasons of convenience, the contents in main Charter and this document have been mapped with the same
                superscript.</p>
            <p class="font-weight-bold title mb-0">Para 4 (2) of Investor Charter</p>
            <p class="font-weight-bold subtitle-1 mb-0">Point 1: Value Added Services</p>
            <p class="font-weight-bold subtitle-1 mb-0">a. Basic Services Demat Account (BSDA)1:</p>
            <p class="font-weight-light lh-28">The facility of BSDA with limited services for eligible individuals was
                introduced with the objective of achieving wider financial inclusion and to encourage holding of demat
                accounts. No Annual Maintenance Charges (AMC) shall be levied, if the value of securities holding is upto
                Rs. 50,000. For value of holdings between Rs 50,001- 2,00,000, AMC not exceeding Rs 100 is chargeable. In
                case of debt securities, there are no AMC charges for holding value upto Rs 1,00,000 and a maximum of Rs 100
                as AMC is chargeable for value of holdings between Rs 1,00,001 and Rs 2,00,000.</p>
            <p class="mb-0 font-weight-bold subtitle-1">b. Transposition cum dematerialization2:</p>
            <p class="font-weight-light lh-28">In case of transposition-cum- dematerialisation, client can get
                securities dematerialised in the same account if the names appearing on the certificates match with the
                names in which the account has been opened but are in a different order. The same may be done by submitting
                the security certificates along with the Transposition Form and Demat Request Form.</p>
            <p class="mb-0 font-weight-bold subtitle-1">c. Linkages with Clearing System3</p>
            <p class="font-weight-light lh-28">for actual delivery of securities to the clearing system from the
                selling brokers and delivery of securities from the clearing system to the buying broker.</p>
            <p class="mb-0 font-weight-bold subtitle-1">Point 3: Digitization of services provided by the depositories</p>

            <p class="mb-0 font-weight-bold subtitle-1">a. E-account opening4</p>
            <p class="font-weight-light lh-28">Account opening through digital mode, popularly known as “On-line
                Account opening”, wherein investor intending to open the demat account can visit DP website, fill in the
                required information, submit the required documents, conduct video IPV and demat account gets opened without
                visiting DPs office.</p>
            <p class="mb-0 font-weight-bold subtitle-1">b. Online instructions for execution5:</p>
            <p class="font-weight-light lh-28">internet-enabled services like Speed-e (NSDL) & Easiest (CDSL) empower a
                demat account holder in managing his/her securities ‘anytime-anywhere' in an efficient and convenient manner
                and submit instructions online without the need to use paper. These facilities allows Beneficial Owner (BO)
                to submit transfer instructions and pledge instructions including margin pledge from their demat account.
                The instruction facilities are also available on mobile applications through android, windows and IOS
                platforms.</p>
            <p class="mb-0 font-weight-bold subtitle-1">c. e-DIS / Demat Gateway:6</p>
            <p class="font-weight-light lh-28">Investors can give instructions for transfer of securities through e-DIS
                apart from physical DIS. Here, for on-market transfer of securities, investors need to provide settlement
                number along with the ISIN and quantity of securities being authorized for transfer. Client shall be
                required to authorize each e-DIS valid for a single settlement number / settlement date, by way of OTP and
                PIN/password, both generated at Depositories end. Necessary risk containment measures are being adopted by
                Depositories in this regard.</p>
            <p class="mb-0 font-weight-bold tsubtitle-1itle">d. e-CAS facility7:</p>
            <p class="font-weight-light lh-28">Consolidated Account Statements are available online and could also be
                accessed through mobile app to facilitate the investors to view their holdings in demat form.</p>
            <p class="mb-0 font-weight-bold subtitle-1">e. Miscellaneous services8:</p>
            <p class="font-weight-light lh-28">Transaction alerts through SMS, e-locker facilities, chatbots for
                instantaneously responding to investor queries etc. have also been developed.</p>
            <p class="mb-0 font-weight-bold title">Para 5(1) of Investor Charter</p>
            <p class="mb-0 font-weight-bold subtitle-1">Point 2 (Investor Grievance Redressal Committee of Depository)9 :
            </p>

            <p class="font-weight-light lh-28">If no amicable resolution is arrived, then the Investor has the option
                to refer the complaint/ grievance to the Grievance Redressal Committee (GRC) of the Depository. Upon receipt
                of reference, the GRC will endeavor to resolve the complaint/ grievance by hearing the parties and examining
                the necessary information and documents.</p>

            <p class="mb-0 font-weight-bold subtitle-1">Point 3(Arbitration proceedings)10:</p>

            <p class="font-weight-light lh-28">The Investor may also avail the arbitration mechanism set out in the
                Byelaws and Business Rules/Operating Instructions of the Depository in relation to any grievance, or dispute
                relating to depository services. The arbitration reference shall be concluded by way of issue of an arbitral
                award within 4 months from the date of appointment of arbitrator(s).</p>
            <div>
                <img class="px-md-8 py-2" src="@/assets/Compliance/investerimage.png" width="100%" alt="investerimage">
            </div>
            <p class="mb-1 font-weight-bold title">Para 7 of Investor Charter</p>
            <p class="font-weight-bold subtitle-1">Dos and Don'ts for Investor12</p>
            <v-card width="100%" class="price-card-sty elevation-0 rounded-xl overflow-hidden mb-6">
                <v-row class="pa-4">
                    <v-col cols="2" class="d-none d-md-block"><span class="font-weight-bold subtitle-1">Sr.no</span></v-col>
                    <v-col cols="11" md="10"><span class="font-weight-bold subtitle-1">Guidance</span></v-col>
                </v-row>
                <v-divider></v-divider>
                <div v-for="(l, j) in InvesterChater" :key="j">
                    <v-row class="pa-4">
                        <v-col cols="2" class="d-none d-md-block"><span class="font-weight-regular txt-444">{{ l.SN }}</span></v-col>
                        <v-col cols="11" md="10"><span class="font-weight-regular txt-444">{{ l.Guidance }}</span></v-col>
                    </v-row>
                    <v-divider v-if="InvesterChater.length - 1 != j"></v-divider>
                </div>
            </v-card>

            <p class="mb-1 font-weight-bold title">Para 8 of Investor Charter</p>
            <p class="mb-0 font-weight-bold subtitle-1">Rights of investors13</p>
            <p class="font-weight-light lh-28">
            <ul>
                <li>Receive a copy of KYC, copy of account opening documents.</li>
                <li>No minimum balance is required to be maintained in a demat account.</li>
                <li>No charges are payable for opening of demat accounts.</li>
                <li>If executed, receive a copy of Power of Attorney. However, Power of Attorney is not a mandatory
                    requirement as per SEBI / Stock Exchanges. You have the right to revoke any authorization given at any
                    time.</li>
                <li>You can open more than one demat account in the same name with single DP/ multiple DPs.</li>
                <li>Receive statement of accounts periodically. In case of any discrepancies in statements, take up the same
                    with the DP immediately. If the DP does not respond, take up the matter with the Depositories.</li>
                <li>Pledge and /or any other interest or encumbrance can be created on demat holdings.</li>
                <li>Right to give standing instructions with regard to the crediting of securities in demat account.</li>
                <li>Investor can exercise its right to freeze/defreeze his/her demat account or specific securities /
                    specific quantity of securities in the account, maintained with the DP.</li>
                <li>In case of any grievances, Investor has right to approach Participant or Depository or SEBI for getting
                    the same resolved within prescribed timelines.</li>
                <li>Every eligible investor shareholder has a right to cast its vote on various resolutions proposed by the
                    companies for which Depositories have developed an internet based ‘e-Voting' platform.</li>
                <li>Receive information about charges and fees. Any charges/tariff agreed upon shall not increase unless a
                    notice in writing of not less than thirty days is given to the Investor.</li>
            </ul>
            </p>
            <p class="mb-1 font-weight-bold title">Para 9 of Investor Charter.</p>
            <p class="mb-0 font-weight-bold subtitle-1">Responsibilities of Investors14</p>
            <p class="font-weight-light lh-28">

            <ul>
                <li>Deal with a SEBI registered DP for opening demat account, KYC and Depository activities.</li>
                <li>Provide complete documents for account opening and KYC (Know Your Client).
                    Fill all the required details in Account Opening Form / KYC form in own handwriting
                    and cancel out the blanks.</li>
                <li>Read all documents and conditions being agreed before signing the account opening form.</li>
                <li>Accept the Delivery Instruction Slip (DIS) book from DP only (preprinted with a
                    serial number along with client ID) and keep it in safe custody and do not sign or
                    issue blank or partially filled DIS.</li>
                <li>Always mention the details like ISIN, number of securities accurately.</li>
                <li>Inform any change in information linked to demat account and obtain confirmation of updation in the
                    system.</li>
                <li>Regularly verify balances and demat statement and reconcile with trades / transactions.</li>
                <li>Appoint nominee(s) to facilitate heirs in obtaining the securities in their demat account.</li>
                <li>Do not fall prey to fraudsters sending emails and SMSs luring to trade in stocks / securities promising
                    huge profits.</li>
            </ul>
            </p>
        </div>
    </div>
</template>

<script>
export default {

    data: () => ({
        InvesterChater: [
            {
                SN: "1",
                Guidance: "Always deal with a SEBI registered Depository Participant for opening a demat account.",
            },
            {
                SN: "2",
                Guidance: "Read all the documents carefully before signing them.",
            },
            {
                SN: "3",
                Guidance: "Before granting Power of attorney to operate your demat account to an intermediary like Stockbroker, Portfolio Management Services (PMS) etc., carefully examine the scope and implications of powers being granted.",
            },
            {
                SN: "4",
                Guidance: "Always make payments to registered intermediary using banking channels. No payment should be made in name of employee of intermediary.",
            },
            {
                SN: "5",
                Guidance: "Accept the Delivery Instruction Slip (DIS) book from your DP only (pre-printed with a serial number along with your Client ID) and keep it in safe custody and do not sign or issue blank or partially filled DIS slips.<br><br>Always mention the details like ISIN, number of securities accurately. In case of any queries, please contact your DP or broker and it should be signed by all demat account holders.<br><br>Strike out any blank space on the slip and Cancellations or corrections on the DIS should be initialed or signed by all the account holder(s).<br><br>Do not leave your instruction slip book with anyone else.<br><br>Do not sign blank DIS as it is equivalent to a bearer cheque.   ",
            },
            {
                SN: "6",
                Guidance: "Inform any change in your Personal Information (for example address or Bank Account details, email ID, Mobile number) linked to your demat account in the prescribed format and obtain confirmation of updation in system",
            },
            {
                SN: "7",
                Guidance: "Mention your Mobile Number and email ID in account opening form to receive SMS alerts and regular updates directly from depository.",
            },
            {
                SN: "8",
                Guidance: "Always ensure that the mobile number and email ID linked to your demat account are the same as provided at the time of account opening/updation.",
            },
            {
                SN: "9",
                Guidance: "Do not share password of your online trading and demat account with anyone.",
            },
            {
                SN: "10",
                Guidance: "Do not share One Time Password (OTP) received from banks, brokers, etc. These are meant to be used by you only.",
            },
            {
                SN: "11",
                Guidance: "Do not share login credentials of e-facilities provided by the depositories such as e-DIS/demat gateway, SPEED-e/easiest etc. with anyone else.",
            },
            {
                SN: "12",
                Guidance: "Demat is mandatory for any transfer of securities of Listed public limited companies with few exceptions.",
            },
            {
                SN: "13",
                Guidance: "If you have any grievance in respect of your demat account, please write to designated email IDs of depositories or you may lodge the same with SEBI online at https://scores.gov.in/scores/Welcome.html",
            },
            {
                SN: "14",
                Guidance: "Keep a record of documents signed, DIS issued and account statements received.",
            },
            {
                SN: "15",
                Guidance: "As Investors you are required to verify the transaction statement carefully for all debits and credits in your account. In case of any unauthorized debit or credit, inform the DP or your respective Depository.",
            },
            {
                SN: "16",
                Guidance: "Appoint a nominee to facilitate your heirs in obtaining the securities in your demat account, on completion of the necessary procedures.",
            },
            {
                SN: "17",
                Guidance: "Register for Depository's internet-based facility or download mobile app of the depository to monitor your holdings.",
            },
            {
                SN: "18",
                Guidance: "Ensure that, both, your holding and transaction statements are received periodically as instructed to your DP. You are entitled to receive a transaction statement every month if you have any transactions.",
            },
            {
                SN: "19",
                Guidance: "Do not follow herd mentality for investments. Seek expert and professional advice for your investments",
            },
            {
                SN: "20",
                Guidance: "Beware of assured/fixed returns.",
            },

        ],
    }),
}
</script>