<template>
    <div class="no-xhide">
        <v-snackbar class="snakbar-sty pt-6 d-md-none pr-6 z-i6 rounded-pill" transition="slide-x-reverse-transition" v-model="snackbar"
            timeout="4000" :value="true" :color="snackbarclr" absolute text-color="white">
            <v-icon class="mr-2" color="#fff">mdi-alert-outline</v-icon>
            {{ snackmsgbar }}
            <v-icon @click="snackbar = false" class="float-right" color="#fff">mdi-close-circle</v-icon>
        </v-snackbar>
        <v-snackbar class="snakbar-sty d-none d-md-flex pt-6 pr-6 z-i6 rounded-pill mt-10" transition="slide-x-reverse-transition"
            top right v-model="snackbar" timeout="4000" :value="true" :color="snackbarclr" absolute text-color="white">
            <v-icon class="mr-2" color="#fff">mdi-alert-outline</v-icon>
            {{ snackmsgbar }}
            <v-icon @click="snackbar = false" class="float-right" color="#fff">mdi-close-circle</v-icon>
        </v-snackbar>
        <div data-aos="fade-up">
            <v-card class="elevation-0 px-16 py-10 support-main-bg rounded-xl d-none d-md-flex">
                <v-row no-glutters>
                    <v-col cols="12" md="6">
                        <p class="white--text fs-50 font-weight-bold mb-6">Career @ Zebu</p>
                        <p class="white--text headline">Looking for a change? Or Want to <br> be the change? <br>
                            You're at the right place.
                        </p>

                    </v-col>
                    <v-col cols="12" md="6">
                        <v-container fill-height class="px-0">
                            <div class="d-flex">
                                <v-card
                                    class="elevation-0 my-auto pl-12 pr-10 py-8 c-brd-3 rounded-xl white--text mr-6">
                                    <div class="mt-1">
                                        <span class="secondary-infont fs-60" :class="loader ? 'blinktxt' : ''">{{
            allcareers.length > 0 ? allcareers.length < 10 ? `0${allcareers.length}` :
                allcareers.length : '00' }}</span>
                                    </div>
                                    <p class="mb-0">
                                        <span class="font-weight-bold fs-20">Active <br>
                                            positions</span>
                                    </p>
                                </v-card>
                                <v-card class="elevation-0 my-auto pl-12 pr-10 py-8 c-brd-3 rounded-xl white--text">
                                    <div class="mt-1">
                                        <span class="secondary-infont fs-60" :class="loader ? 'blinktxt' : ''">{{
            postlocation > 0 ? postlocation < 10 ? `0${postlocation}` : postlocation
                : '00' }}</span>
                                    </div>
                                    <p class="mb-0">
                                        <span class="font-weight-bold fs-20">Posting <br>
                                            locations</span>
                                    </p>
                                </v-card>
                            </div>

                        </v-container>

                    </v-col>
                </v-row>
            </v-card>
            <v-card class="elevation-0 px-6 px-sm-8 py-10 support-main-bg rounded-0 d-md-none" v-if="carrer">
                <v-row no-glutters>
                    <v-col cols="12" class="text-center">
                        <p class="white--text fs-32 lh-32 font-weight-bold">Career @ Zebu</p>
                        <p class="white--text fs-18">Looking for a change? Or Want to be the change? You're at the right
                            place.
                        </p>

                    </v-col>
                    <v-col cols="12" class="text-left">
                        <div class="d-flex">
                            <v-row no-glutters>
                                <v-col cols="6">
                                    <v-card class="elevation-0 pl-4 py-3 c-brd-3 rounded-xl white--text" width="100%">
                                        <div class="mt-1">
                                            <span class="secondary-infont fs-32" :class="loader ? 'blinktxt' : ''">{{
            allcareers.length > 0 ? allcareers.length < 10 ? `0${allcareers.length}`
                : allcareers.length : '00' }}</span>
                                        </div>
                                        <p class="mb-0">
                                            <span class="font-weight-bold fs-16">Active positions</span>
                                        </p>
                                    </v-card>
                                </v-col>
                                <v-col cols="6">
                                    <v-card class="elevation-0 pl-4 py-3 c-brd-3 rounded-xl white--text" width="100%">
                                        <div class="mt-1">
                                            <span class="secondary-infont fs-32" :class="loader ? 'blinktxt' : ''">{{
            postlocation > 0 ? postlocation < 10 ? `0${postlocation}` : postlocation
                : '00' }}</span>
                                        </div>
                                        <p class="mb-0">
                                            <span class="font-weight-bold fs-16">Posting locations</span>
                                        </p>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                </v-row>
            </v-card>
        </div>
        <div class="py-md-10 mb-md-6 px-md-16">
            <div class="pt-10 mb-8 mb-md-4 px-6 px-sm-8 px-md-16 mx-md-0">
                <div v-if="carrer">
                    <div class=" d-none d-md-block">
                        <p class="secondary-infont fs-32 lh-48">Join the next financial innovator.</p>
                        <p id="jobs" class="font-weight-regular txt-444">At Zebu, we believe in a flat
                            hierarchy that is conducive for maximum growth for everyone working for us. If you are
                            motivated by challenges and enjoy an environment that is dynamic, challenging and rewarding,
                            we will always have a place for you.
                        </p>
                    </div>
                    <div class="d-md-none">
                        <p class="secondary-infont fs-32 lh-38">Join the next financial innovator.</p>
                        <p id="jobs" class="font-weight-regular fs-18 txt-444">At Zebu, we believe in a flat
                            hierarchy that is conducive for maximum growth for everyone working for us. If you are
                            motivated by challenges and enjoy an environment that is dynamic, challenging and rewarding,
                            we will always have a place for you.
                        </p>
                    </div>
                    <v-row v-if="!loader" class="pt-md-10 pb-6">
                        <v-col cols="12" md="6" class="pr-md-6" v-for="(s, p) in allcareers" :key="p">
                            <v-card @click="jobis(s)" data-aos="flip-up" width="100%" class="box-s1 rounded-xl px-2">
                                <v-list-item class="pr-0 py-2">
                                    <v-list-item-content>
                                        <v-list-item-title class="font-weight-bold fs-18">{{ s.attributes.jobtitle
                                            }}</v-list-item-title>
                                        <p class="mt-1 mb-0 font-weight-regular">{{ s.attributes.joblocation }}</p>
                                    </v-list-item-content>
                                    <v-btn text plain color="#0037B7"><span
                                            class="font-weight-bold fs-18 text-none">Apply</span></v-btn>
                                </v-list-item>
                            </v-card>
                        </v-col>
                    </v-row>
                    <div v-else>
                        <v-container fill-height>
                            <v-card class="elevation-0 mx-auto my-16">
                                <v-progress-circular size="80" indeterminate color="#1e53e5"></v-progress-circular>
                            </v-card>
                        </v-container>
                    </div>
                </div>
                <div v-else>
                    <p class="mb-4 ml-md-4 subtitle-2 font-weight-regular cursor-p"><span @click="goback()">← Back to
                            Careers</span>
                    </p>

                    <div v-if="jobdata">

                        <v-card data-aos="flip-up" width="100%"
                            class="box-s1 rounded-xl px-4 pt-6 pb-4 d-none d-md-block">
                            <v-toolbar color="transparent" dense flat>
                                <div>
                                    <p class="mb-0 fs-24 font-weight-bold">{{ jobdata.jobtitle }}</p>
                                    <p class="mb-0 fs-20 txt-666666 font-weight-bold">{{ jobdata.jobdepartment }}, {{
            jobdata.joblocation }}</p>
                                </div>
                                <v-spacer></v-spacer>

                                <v-btn height="48px" color="#2A2A2A" @click="scrollway()" :disabled="carrloader"
                                    class="text-none rounded-pill elevation-0 my-8"><span
                                        class="white--text subtitle-1 font-weight-medium px-2">Apply
                                        for
                                        opening</span></v-btn>
                            </v-toolbar>

                            <v-divider class="my-6 mx-2"></v-divider>

                            <div class="pa-4">
                                <p class="white-space">{{ jobdata.jobdescription }}</p>
                            </div>
                        </v-card>
                        <v-card data-aos="flip-up" width="100%" class="elevation-0 rounded-0 d-md-none">
                            <div>
                                <p class="mb-0 title font-weight-bold">{{ jobdata.jobtitle }}</p>
                                <p class="mb-0 fs-18 txt-666666 font-weight-medium">{{ jobdata.jobdepartment }}, {{
                                    jobdata.joblocation }}</p>
                            </div>

                            <v-btn height="48px" block color="#2A2A2A" @click="scrollway()" :disabled="carrloader"
                                class="text-none rounded-pill elevation-0 my-8"><span
                                    class="white--text subtitle-1 font-weight-medium px-2">Apply
                                    for
                                    opening</span></v-btn>

                            <v-divider class="my-3 mx-2"></v-divider>

                            <div class="pa-4">
                                <p class="white-space">{{ jobdata.jobdescription }}</p>
                            </div>
                        </v-card>
                    </div>


                    <div id="content" class="pt-md-16">
                        <v-card width="100%" class="price-card-sty elevation-0 rounded-xl py-6 px-8">
                            <v-row>
                                <v-col cols="12" md="6" class="d-none d-md-block">
                                    <p class="mb-0 font-weight-bold secondary-infont fs-32">Apply for the role
                                    </p>
                                    <p class="mb-2 fs-20 txt-666666 font-weight-medium">Share your Resume/CV in PDF</p>
                                </v-col>
                                <v-col cols="12" md="6" class="d-md-none">
                                    <p class="mb-0 font-weight-regular secondary-infont fs-28">Apply for the role
                                    </p>
                                    <p class="mb-2 fs-18 txt-666666 font-weight-medium">Share your Resume/CV in PDF</p>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <div class="mb-4">
                                        <v-file-input block flat solo background-color="#ffffff" hide-details
                                            id="resumeCVCareersForm" v-model="files"
                                            class="elevation-0 rounded-pill caption" label="No file choose"
                                            accept="application/pdf" @input="$v.files.$touch()">
                                        </v-file-input>
                                    </div>
                                    <div class="text-right d-none d-md-block">
                                        <v-btn large color="#2A2A2A" @click="files != null ? uploadResume() : ''"
                                            :loading="carrloader" class="elevation-0 rounded-pill mr-2 text-none brd-2"
                                            outlined>
                                            <span>Submit your resume</span>
                                        </v-btn>
                                    </div>
                                    <div class="d-md-none">
                                        <v-btn large color="#2A2A2A" @click="files != null ? uploadResume() : ''" block
                                            :loading="carrloader" class="elevation-0 rounded-pill text-none brd-2"
                                            outlined>
                                            <span>Submit your resume</span>
                                        </v-btn>
                                    </div>

                                </v-col>
                            </v-row>
                        </v-card>
                    </div>

                </div>
            </div>

        </div>
    </div>
</template>

<script>
import axios from "axios";
import { strapi, sessapi } from '../apiurl'

export default {
    data: () => ({
        snackbar: false,
        snackbarclr: "#2a2a2a",
        snackmsgbar: "",
        carrloader: false,
        email: null,

        carrer: true,
        allcareers: [],
        jobdata: [],
        scroll: null,
        files: null,
        postlocation: '00',
        loader: true,
    }),

    mounted() {
        this.getcareers();
    },
    created() {
        let params = this.$route.params;
        if (params.id && params.id.attributes) {
            this.carrer = false;
            this.jobdata = params.id ? params.id.attributes : [];
            this.jobdata['id'] = params.id.id;
        } else {
            this.carrer = true;
        }
    },

    methods: {
        getcareers() {
            this.loader = true;
            let config = {
                method: 'get',
                url: `${strapi}/careers`,
            };

            let axiosThis = this;
            axios.request(config)
                .then((response) => {
                    if (response.data.data) {
                        axiosThis.allcareers = response.data.data;
                        var obj = {};
                        response.data.data.forEach(function (item) {
                            obj[item.attributes.joblocation] ? obj[item.attributes.joblocation]++ : obj[item.attributes.joblocation] = 1;
                        });
                        axiosThis.postlocation = Object.keys(obj).length;
                        // axiosThis.jobdata = response.data.data[0].attributes;
                    }
                    axiosThis.loader = false;
                })
                .catch((error) => {
                    axiosThis.loader = false;
                    console.log(error);
                });
        },
        jobis(s) {
            this.carrer = false;
            this.jobdata = s.attributes;
            this.jobdata['id'] = s.id;
        },
        goback() {
            this.jobdata = [];
            this.carrer = true;
        },
        scrollway() {
            const element = document.getElementById("content");
            element.scrollIntoView();
        },
        uploadResume() {
            if (this.files) {
                this.carrloader = true;
                let formData = new FormData();
                formData.append("files", this.files);
                // formData.append("email", this.email);
                let axiosThis = this;
                axios.post(`${sessapi}resumeupload?jobid=${this.jobdata.id}`,
                    formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                }
                ).then(function (response) {
                    axiosThis.snackbar = true;
                    axiosThis.snackmsgbar = response.data.msg ? response.data.msg : response.data;
                    if (response.data && response.data.msg) {
                        axiosThis.files = [];
                        // axiosThis.email = null;
                    }
                    axiosThis.carrloader = false;
                })
                    .catch(function (e) {
                        axiosThis.carrloader = false;
                        axiosThis.snackbar = true;
                        axiosThis.snackmsgbar = e;
                    });
            }
        },
    }
}
</script>