<template>
    <div class="px-md-14">
        <div>
            <v-card width="100%" class="price-card-sty elevation-0 rounded-c-xl mb-md-6 overflow-hidden">
                <div class="px-6 py-8 px-sm-8">
                    <p class="mb-6 font-weight-bold secondary-infont fs-32 lh-32">Brokerage Calculator</p>

                    <v-row no-glutters>
                        <v-col cols="12" md="3">
                            <p class="mb-0 title font-weight-regular">Total quantity</p>
                            <v-text-field type="number" hide-spin-buttons
                                @keyup="intraday1(), intraday2(), intraday3(), intraday4(), intraday5(), intraday6(), intraday7(), intraday8()"
                                dense v-model="intraday_q1" block flat solo background-color="#ffffff" max="10000000" min="1"
                                class="search-field calc-field elevation-0 mr-md-3 rounded-pill caption mb-3"
                                label="Enter quantity" hide-details>
                                <template #prepend-inner>
                                    <div class="mr-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17"
                                            viewBox="0 0 16 17" fill="none">
                                            <g clip-path="url(#clip0_1116_9811)">
                                                <path
                                                    d="M8 7.02304L14 3.68904L8.486 0.626041C8.33735 0.543381 8.17008 0.5 8 0.5C7.82992 0.5 7.66265 0.543381 7.514 0.626041L2 3.68904L8 7.02304Z"
                                                    fill="#666666" />
                                                <path
                                                    d="M9 8.75488V16.0879L14.486 13.0399C14.6418 12.9532 14.7716 12.8265 14.862 12.6729C14.9524 12.5192 15 12.3441 15 12.1659V5.42188L9 8.75488Z"
                                                    fill="#666666" />
                                                <path
                                                    d="M7 8.75488L1 5.42188V12.1659C0.999993 12.3441 1.04764 12.5192 1.13802 12.6729C1.22839 12.8265 1.3582 12.9532 1.514 13.0399L7 16.0879V8.75488Z"
                                                    fill="#666666" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1116_9811">
                                                    <rect width="16" height="16" fill="white"
                                                        transform="translate(0 0.5)" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                            <p class="mb-0 title font-weight-regular">Buy value</p>
                            <v-text-field type="number" hide-spin-buttons
                                @keyup="intraday1(), intraday2(), intraday3(), intraday4(), intraday5(), intraday6(), intraday7(), intraday8()"
                                dense v-model="intraday_buy1" block flat solo background-color="#ffffff"
                                max="10000000000" min="0"
                                class="search-field calc-field elevation-0 mr-md-3 rounded-pill caption mb-3"
                                label="Enter price" hide-details>
                                <template #prepend-inner>
                                    <div class="mr-1">
                                        <span class="title">₹</span>
                                    </div>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                            <p class="mb-0 title font-weight-regular">Sell value</p>
                            <v-text-field type="number" hide-spin-buttons
                                @keyup="intraday1(), intraday2(), intraday3(), intraday4(), intraday5(), intraday6(), intraday7(), intraday8()"
                                dense v-model="intraday_sell1" block flat solo background-color="#ffffff"
                                max="10000000000" min="0"
                                class="search-field calc-field elevation-0 mr-md-3 rounded-pill caption mb-3"
                                label="Enter price" hide-details>
                                <template #prepend-inner>
                                    <div class="mr-1">
                                        <span class="title">₹</span>
                                    </div>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                            <p class="mb-0 title font-weight-regular">Brokerage type</p>

                            <v-tooltip top color="#000">
                                <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs" v-on="on">
                                        <v-switch @change="setBrockerage" v-model="brok_type" inset
                                            :label="`In ${brok_type ? 'percentage %' : 'flat ₹'}`"></v-switch>
                                    </div>
                                </template>
                                <span>Switch to {{ brok_type ? 'percentage %' : 'flat ₹' }}</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </div>

                <v-card class="brk-scr-crd cust-scroll elevation-0" color="transparent">
                    <div class="cnt-brk">
                        <v-row no-glutters class="px-6 py-3">
                            <v-col cols="1" class="ws-p">
                                <span class="mb-0 font-weight-bold title">Segments</span>
                            </v-col>
                            <v-col cols="11">
                                <v-row no-glutters>
                                    <v-col cols="3" class="text-center pl-16"
                                        v-for="(s, j) in ['Equity', 'F&O', 'Currency', 'Commodity']" :key="j">
                                        <span class="mb-0 font-weight-bold title">{{ s }}</span>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-divider></v-divider>
                        <v-row no-glutters class="px-6 py-3">
                            <v-col cols="1">
                            </v-col>
                            <v-col cols="11">
                                <v-row no-glutters>
                                    <v-col cols="3" v-for="(c, s) in segtypes" :key="s">
                                        <v-row no-glutters>
                                            <v-col cols="6" class="text-right pr-4">
                                                <span class="font-weight-regular txt-black">{{ c.o }}</span>
                                            </v-col>
                                            <v-col cols="6" class="text-right pr-4">
                                                <span class="font-weight-regular txt-black">{{ c.t }}</span>
                                            </v-col>
                                        </v-row>
                                    </v-col>

                                </v-row>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <div v-for="(c, k) in brkcalcdata" :key="k">
                            <v-row no-glutters class="px-6 py-3">
                                <v-col cols="1" class="ws-p">
                                    <span
                                        :class="c.type == 'f' ? 'font-weight-bold' : 'font-weight-regular txt-black'">{{
                                            c.name
                                        }}</span>
                                </v-col>
                                <v-col cols="11">
                                    <v-row v-if="c.type != 'f'" no-glutters>
                                        <v-col cols="3">
                                            <v-row no-glutters>
                                                <v-col cols="6" class="text-right ws-p pr-4">
                                                    <span class="font-weight-light txt-black fs-15" v-if="c.v1 == '-'">-
                                                        -</span>
                                                    <span class="font-weight-light txt-black fs-15" v-else
                                                        :id="c.v1"></span>
                                                </v-col>
                                                <v-col cols="6" class="text-right ws-p pr-4">
                                                    <span class="font-weight-light txt-black fs-15" v-if="c.v2 == '-'">-
                                                        -</span>
                                                    <span class="font-weight-light txt-black fs-15" v-else
                                                        :id="c.v2"></span>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-row no-glutters>
                                                <v-col cols="6" class="text-right ws-p pr-4">
                                                    <span class="font-weight-light txt-black fs-15" v-if="c.v3 == '-'">-
                                                        -</span>
                                                    <span class="font-weight-light txt-black fs-15" v-else
                                                        :id="c.v3"></span>
                                                </v-col>
                                                <v-col cols="6" class="text-right ws-p pr-4">
                                                    <span class="font-weight-light txt-black fs-15" v-if="c.v4 == '-'">-
                                                        -</span>
                                                    <span class="font-weight-light txt-black fs-15" v-else
                                                        :id="c.v4"></span>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-row no-glutters>
                                                <v-col cols="6" class="text-right ws-p pr-4">
                                                    <span class="font-weight-light txt-black fs-15" v-if="c.v5 == '-'">-
                                                        -</span>
                                                    <span class="font-weight-light txt-black fs-15" v-else
                                                        :id="c.v5"></span>
                                                </v-col>
                                                <v-col cols="6" class="text-right ws-p pr-4">
                                                    <span class="font-weight-light txt-black fs-15" v-if="c.v6 == '-'">-
                                                        -</span>
                                                    <span class="font-weight-light txt-black fs-15" v-else
                                                        :id="c.v6"></span>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-row no-glutters>
                                                <v-col cols="6" class="text-right ws-p pr-4">
                                                    <span class="font-weight-light txt-black fs-15" v-if="c.v7 == '-'">-
                                                        -</span>
                                                    <span class="font-weight-light txt-black fs-15" v-else
                                                        :id="c.v7"></span>
                                                </v-col>
                                                <v-col cols="6" class="text-right ws-p pr-4">
                                                    <span class="font-weight-light txt-black fs-15" v-if="c.v8 == '-'">-
                                                        -</span>
                                                    <span class="font-weight-light txt-black fs-15" v-else
                                                        :id="c.v8"></span>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <v-row v-else no-glutters>
                                        <v-col cols="3">
                                            <v-row no-glutters>
                                                <v-col cols="6" class="text-right">
                                                    <v-text-field reverse block flat solo background-color="#ffffff"
                                                        class=" calc-field brk-calc-field ml-auto elevation-0 rounded-pill"
                                                        hide-details dense v-model="brok_per1" value="0.03"
                                                        hide-spin-buttons @keyup="intraday1()"
                                                        type="number"></v-text-field>
                                                </v-col>
                                                <v-col cols="6" class="text-right">
                                                    <v-text-field reverse block flat solo background-color="#ffffff"
                                                        class=" calc-field brk-calc-field ml-auto elevation-0 rounded-pill"
                                                        hide-details dense v-model="brok_per2" value="0.03"
                                                        hide-spin-buttons @keyup="intraday2()"
                                                        type="number"></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-row no-glutters>
                                                <v-col cols="6" class="text-right">
                                                    <v-text-field reverse block flat solo background-color="#ffffff"
                                                        class=" calc-field brk-calc-field ml-auto elevation-0 rounded-pill"
                                                        hide-details dense v-model="brok_per3" value="0.03"
                                                        hide-spin-buttons @keyup="intraday3()"
                                                        type="number"></v-text-field>
                                                </v-col>
                                                <v-col cols="6" class="text-right">
                                                    <v-text-field reverse block flat solo background-color="#ffffff"
                                                        class=" calc-field brk-calc-field ml-auto elevation-0 rounded-pill"
                                                        hide-details dense v-model="brok_per4" value="0.03"
                                                        hide-spin-buttons @keyup="intraday4()"
                                                        type="number"></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-row no-glutters>
                                                <v-col cols="6" class="text-right">
                                                    <v-text-field reverse block flat solo background-color="#ffffff"
                                                        class=" calc-field brk-calc-field ml-auto elevation-0 rounded-pill"
                                                        hide-details dense v-model="brok_per5" value="0.03"
                                                        hide-spin-buttons @keyup="intraday5()"
                                                        type="number"></v-text-field>
                                                </v-col>
                                                <v-col cols="6" class="text-right">
                                                    <v-text-field reverse block flat solo background-color="#ffffff"
                                                        class=" calc-field brk-calc-field ml-auto elevation-0 rounded-pill"
                                                        hide-details dense v-model="brok_per6" value="0.03"
                                                        hide-spin-buttons @keyup="intraday6()"
                                                        type="number"></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-row no-glutters>
                                                <v-col cols="6" class="text-right">
                                                    <v-text-field reverse block flat solo background-color="#ffffff"
                                                        class=" calc-field brk-calc-field ml-auto elevation-0 rounded-pill"
                                                        hide-details dense v-model="brok_per7" value="0.03"
                                                        hide-spin-buttons @keyup="intraday7()"
                                                        type="number"></v-text-field>
                                                </v-col>
                                                <v-col cols="6" class="text-right">
                                                    <v-text-field reverse block flat solo background-color="#ffffff"
                                                        class=" calc-field brk-calc-field ml-auto elevation-0 rounded-pill"
                                                        hide-details dense v-model="brok_per8" value="0.03"
                                                        hide-spin-buttons @keyup="intraday8()"
                                                        type="number"></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                        </div>
                    </div>
                </v-card>
                <p class="px-6 py-3 mb-0 font-weight-light txt-666666">Note : STT Will be higher in case of option
                    exercised & Option Brokerage per lot, single side is Rs. 50</p>

            </v-card>
            <v-card width="100%" class="elevation-0 rounded-c-xl overflow-hidden" outlined>
                <div class="px-6 px-sm-8 px-md-10 py-6">
                    <p class="mb-0 font-weight-bold title">What is brokerage?</p>
                    <p class="mb-6 font-weight-light txt-444">A brokerage fee is a fee or commission that a broker
                        charges a client to handle transactions or do specialised work on their behalf. Brokers charge
                        fees
                        for services like buying, selling, consulting, negotiating, and delivery.</p>

                    <p class="mb-0 font-weight-bold title">How to calculate brokerage?</p>
                    <p class="mb-6 font-weight-light txt-444">As we've already said, brokerage charges are the fees
                        that
                        traders pay to brokers for helping them trade. So, investors have to pay brokerage fees both
                        when
                        they buy and when they sell securities. Major brokers charge a fee that is a certain percentage
                        of the
                        value of the trade. Depending on the trade value, these percentages could be different.
                        Regardless,
                        the following is the formula for calculating brokerage in the stock market:Brokerage = Number of
                        shares sold/bought x Price of one unit of stock x brokerage percentage</p>

                    <p class="mb-0 font-weight-bold title">What are the benefits of a brokerage calculator?</p>
                    <p class="mb-6 font-weight-light txt-444">Investors can compare brokers who charge different
                        brokerage fees by using a brokerage calculator. Brokerage calculators give you correct results
                        right
                        away. It takes into account all the costs of taking a trade. It doesn't cost anything.It is
                        critical
                        for traders who rely heavily on timing to execute their transactions, such as intraday traders.
                        They
                        can use an intraday brokerage calculator to make cost analysis easier before buying and selling
                        stocks</p>

                    <p class="mb-0 font-weight-bold title">How do you use a brokerage calculator?</p>
                    <p class="mb-0 font-weight-light txt-444">Calculate your stock order's brokerage and other
                        fees.It's
                        an online tool that brokers and other investing platforms put at traders' disposal to make
                        brokerage
                        calculations easier before executing a trade.Input the number of shares or lots, the buying cost
                        and
                        the selling cost to find out the brokerage.</p>
                </div>
            </v-card>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            intraday_buy1: 499,
            intraday_sell1: 501,
            intraday_q1: 100,
            brok_per1: 0.03,
            brok_per2: 0.3,
            brok_per3: 0.03,
            brok_per4: 20,
            brok_per5: 0.03,
            brok_per6: 20,
            brok_per7: 0.03,
            brok_per8: 0.03,

            brok_type: false,

            segtypes: [
                { o: 'Intraday', t: 'Delivery' },
                { o: 'Futures', t: 'Options' },
                { o: 'Futures', t: 'Options' },
                { o: '(Non-Agri)', t: '(Agri)' },
            ],
            brkcalcdata: [
                {
                    name: 'Brokerage', type: 'f', vmodel1: 'brok_per1', vmodel2: 'brok_per2', vmodel3: 'brok_per3', vmodel4: 'brok_per4', vmodel5: 'brok_per5', vmodel6: 'brok_per6', vmodel7: 'brok_per7', vmodel8: 'brok_per8',
                    keyup1: 'intraday1()', keyup2: 'intraday2()', keyup3: 'intraday3()', keyup4: 'intraday4()', keyup5: 'intraday5()', keyup6: 'intraday6()', keyup7: 'intraday7()', keyup8: 'intraday8()'
                },
                { name: 'Net profit', v1: 'intraday_netprofit1', v2: 'intraday_netprofit2', v3: 'intraday_netprofit3', v4: 'intraday_netprofit4', v5: 'intraday_netprofit5', v6: 'intraday_netprofit6', v7: 'intraday_netprofit7', v8: 'intraday_netprofit8' },

                { name: 'Turnover', v1: 'toamt1', v2: 'toamt2', v3: 'toamt3', v4: 'toamt4', v5: 'toamt5', v6: 'toamt6', v7: 'toamt7', v8: 'toamt8' },
                { name: 'Brokerage', v1: 'broker1', v2: 'broker2', v3: 'broker3', v4: 'broker4', v5: 'broker5', v6: 'broker6', v7: 'broker7', v8: 'broker8' },
                { name: 'Transaction charge', v1: 'trans_charge1', v2: 'trans_charge2', v3: 'trans_charge3', v4: 'trans_charge4', v5: 'trans_charge5', v6: 'trans_charge6', v7: 'trans_charge7', v8: 'trans_charge8' },

                // { name: 'CM Charges', v1: 'cm_charge1', v2: 'cm_charge2', v3: 'cm_charge3', v4: 'cm_charge4', v5: 'cm_charge5', v6: 'cm_charge6', v7: 'cm_charge7', v8: 'cm_charge8' },
                { name: 'GST', v1: 'gst1', v2: 'gst2', v3: 'gst3', v4: 'gst4', v5: 'gst5', v6: 'gst6', v7: 'gst7', v8: 'gst8' },
                { name: 'SEBI Charge', v1: 'sebi_charge1', v2: 'sebi_charge2', v3: 'sebi_charge3', v4: 'sebi_charge4', v5: 'sebi_charge5', v6: 'sebi_charge6', v7: 'sebi_charge7', v8: 'sebi_charge8' },

                { name: 'Stamp Duty', v1: 'stamp_charge1', v2: 'stamp_charge2', v3: 'stamp_charge3', v4: 'stamp_charge4', v5: 'stamp_charge5', v6: 'stamp_charge6', v7: 'stamp_charge7', v8: 'stamp_charge8' },
                { name: 'STT total', v1: 'stt_tot1', v2: 'stt_tot2', v3: 'stt_tot3', v4: 'stt_tot4', v5: '-', v6: '-', v7: '-', v8: '-' },
                { name: 'CTT Total', v1: '-', v2: '-', v3: '-', v4: '-', v5: '-', v6: '-', v7: 'ctt7', v8: '-' },

                { name: 'Total tax & charges', v1: 'intra_tot1', v2: 'intra_tot2', v3: 'intra_tot3', v4: 'intra_tot4', v5: 'intra_tot5', v6: 'intra_tot6', v7: 'intra_tot7', v8: 'intra_tot8' },
            ],

        }
    },
    mounted() {
        this.setBrockerage();
    },

    methods: {
        setBrockerage() {
            if (this.brok_type) {
                this.brok_per1 = 0.03;
                this.brok_per2 = 0.3;
                this.brok_per3 = 0.03;
                this.brok_per4 = 0.03;
                this.brok_per5 = 0.03;
                this.brok_per6 = 0.03;
                this.brok_per7 = 0.03;
                this.brok_per8 = 0.03;
            } else {
                this.brok_per1 = 20;
                this.brok_per2 = 20;
                this.brok_per3 = 20;
                this.brok_per4 = 20;
                this.brok_per5 = 20;
                this.brok_per6 = 20;
                this.brok_per7 = 20;
                this.brok_per8 = 20;
            }
            this.intraday1()
            this.intraday2()
            this.intraday3()
            this.intraday4()
            this.intraday5()
            this.intraday6()
            this.intraday7()
            this.intraday8()
        },

        intraday1() {
            var intradays_buy1 = parseFloat(this.intraday_buy1);
            var intradays_sell1 = parseFloat(this.intraday_sell1);
            var intradays_q1 = parseFloat(this.intraday_q1);
            var eq_buyamt = parseFloat((intradays_buy1 * intradays_q1) * 0.003 / 100).toFixed(2);
            if ((intradays_buy1 + intradays_sell1) * intradays_q1) {
                document.getElementById('toamt1').innerHTML = (((parseFloat(intradays_buy1) + parseFloat(intradays_sell1)) * parseFloat(intradays_q1)).toFixed(2));
                document.getElementById('broker1').innerHTML = this.brok_type ? ((document.getElementById('toamt1').innerHTML * this.brok_per1 / 100).toFixed(2)) : this.brok_per1;
                document.getElementById('stt_tot1').innerHTML = (((intradays_sell1 * intradays_q1) * 0.025 / 100).toFixed(2));
                document.getElementById('trans_charge1').innerHTML = ((document.getElementById('toamt1').innerHTML * 0.00325 / 100).toFixed(2));
                // document.getElementById('cm_charge1').innerHTML = ((document.getElementById('toamt1').innerHTML * 0.0011 / 100).toFixed(2));
                document.getElementById('sebi_charge1').innerHTML = ((document.getElementById('toamt1').innerHTML * 0.00010 / 100).toFixed(2));
                document.getElementById('gst1').innerHTML = (((parseFloat(document.getElementById('broker1').innerHTML) + parseFloat(document.getElementById('trans_charge1').innerHTML) + parseFloat(document.getElementById('sebi_charge1').innerHTML)) * 18 / 100).toFixed(2));
                document.getElementById('stamp_charge1').innerHTML = (parseFloat(eq_buyamt).toFixed(2));
                var eq_totamt = ((parseFloat(document.getElementById('broker1').innerHTML)) + (parseFloat(document.getElementById('stt_tot1').innerHTML)) + (parseFloat(document.getElementById('trans_charge1').innerHTML)) + (parseFloat(document.getElementById('gst1').innerHTML)) + (parseFloat(document.getElementById('sebi_charge1').innerHTML))).toFixed(2);
                document.getElementById('intra_tot1').innerHTML = ((parseFloat(eq_totamt) + parseFloat(eq_buyamt)).toFixed(2));
                document.getElementById('intraday_netprofit1').innerHTML = (((intradays_sell1 * intradays_q1) - (intradays_buy1 * intradays_q1) - document.getElementById('intra_tot1').innerHTML).toFixed(2));
            }
            else {
                document.getElementById('toamt1').innerHTML = 0;
                document.getElementById('broker1').innerHTML = 0;
                document.getElementById('stt_tot1').innerHTML = 0;
                document.getElementById('trans_charge1').innerHTML = 0;
                // document.getElementById('cm_charge1').innerHTML = 0;
                document.getElementById('gst1').innerHTML = 0;
                document.getElementById('sebi_charge1').innerHTML = 0;
                document.getElementById('intra_tot1').innerHTML = 0;
                document.getElementById('intraday_netprofit1').innerHTML = 0;
            }
        },



        intraday2() {
            var intradays_buy = parseFloat(this.intraday_buy1);
            var intradays_sell = parseFloat(this.intraday_sell1);
            var intradays_q = parseFloat(this.intraday_q1);
            var eq_buyamt1 = parseFloat((intradays_buy * intradays_q) * 0.015 / 100).toFixed(2);
            if ((intradays_buy + intradays_sell) * intradays_q) {
                document.getElementById('toamt2').innerHTML = (((parseFloat(intradays_buy) + parseFloat(intradays_sell)) * parseFloat(intradays_q)).toFixed(2));
                document.getElementById('broker2').innerHTML = this.brok_type ? ((document.getElementById('toamt2').innerHTML * this.brok_per2 / 100).toFixed(2)) : this.brok_per2;
                document.getElementById('stt_tot2').innerHTML = ((document.getElementById('toamt2').innerHTML * 0.1 / 100).toFixed(2));
                document.getElementById('trans_charge2').innerHTML = ((document.getElementById('toamt2').innerHTML * 0.00325 / 100).toFixed(2));
                // document.getElementById('cm_charge2').innerHTML = ((document.getElementById('toamt2').innerHTML * 0.0011 / 100).toFixed(2));
                document.getElementById('sebi_charge2').innerHTML = (parseFloat(document.getElementById('toamt2').innerHTML * 0.00010 / 100).toFixed(2));
                document.getElementById('gst2').innerHTML = (((parseFloat(document.getElementById('broker2').innerHTML) + parseFloat(document.getElementById('trans_charge2').innerHTML) + parseFloat(document.getElementById('sebi_charge2').innerHTML)) * 18 / 100).toFixed(2));
                document.getElementById('stamp_charge2').innerHTML = (parseFloat(eq_buyamt1).toFixed(2));
                //$("#intra_tot2").html((parseFloat($("#broker2").html())+parseFloat($("#stt_tot2").html())+parseFloat($("#trans_charge2").html())+parseFloat($("#cm_charge2").html())+parseFloat($("#gst2").html())+parseFloat($("#sebi_charge2").html())).toFixed(2));
                var eq_totamt1 = (parseFloat(document.getElementById('broker2').innerHTML) + parseFloat(document.getElementById('stt_tot2').innerHTML) + parseFloat(document.getElementById('trans_charge2').innerHTML) + parseFloat(document.getElementById('gst2').innerHTML) + parseFloat(document.getElementById('sebi_charge2').innerHTML)).toFixed(2);
                document.getElementById('intra_tot2').innerHTML = ((parseFloat(eq_totamt1) + parseFloat(eq_buyamt1)).toFixed(2));
                document.getElementById('intraday_netprofit2').innerHTML = (((intradays_sell * intradays_q) - (intradays_buy * intradays_q) - document.getElementById('intra_tot2').innerHTML).toFixed(2));
            } else {
                document.getElementById('toamt2').innerHTML = 0;
                document.getElementById('broker2').innerHTML = 0;
                document.getElementById('stt_tot2').innerHTML = 0;
                document.getElementById('trans_charge2').innerHTML = 0;
                // document.getElementById('cm_charge2').innerHTML = 0;
                document.getElementById('gst2').innerHTML = 0;
                document.getElementById('sebi_charge2').innerHTML = 0;
                document.getElementById('intra_tot2').innerHTML = 0;
                document.getElementById('intraday_netprofit2').innerHTML = 0;
            }
        },

        intraday3() {
            var intradays_buy = parseFloat(this.intraday_buy1);
            var intradays_sell = parseFloat(this.intraday_sell1);
            var intradays_q = parseFloat(this.intraday_q1);
            var fno_buyamt = parseFloat((intradays_buy * intradays_q) * 0.002 / 100).toFixed(2);
            if ((intradays_buy + intradays_sell) * intradays_q) {
                document.getElementById('toamt3').innerHTML = (((parseFloat(intradays_buy) + parseFloat(intradays_sell)) * parseFloat(intradays_q)).toFixed(2));
                document.getElementById('broker3').innerHTML = this.brok_type ? ((document.getElementById('toamt3').innerHTML * (this.brok_per3) / 100).toFixed(2)) : this.brok_per3;
                document.getElementById('stt_tot3').innerHTML = ((intradays_sell * intradays_q * 0.0125 / 100).toFixed(2));
                document.getElementById('trans_charge3').innerHTML = ((document.getElementById('toamt3').innerHTML * 0.0019 / 100).toFixed(2));
                document.getElementById('sebi_charge3').innerHTML = (parseFloat(document.getElementById('toamt3').innerHTML * 0.00010 / 100).toFixed(2));
                document.getElementById('stamp_charge3').innerHTML = (parseFloat(fno_buyamt).toFixed(2));
                // document.getElementById('cm_charge3').innerHTML = ((document.getElementById('toamt3').innerHTML * 0.0011 / 100).toFixed(2));
                document.getElementById('gst3').innerHTML = (((parseFloat(document.getElementById('broker3').innerHTML) + parseFloat(document.getElementById('trans_charge3').innerHTML) + parseFloat(document.getElementById('sebi_charge3').innerHTML)) * 18 / 100).toFixed(2));
                //$("#intra_tot3").html((parseFloat($("#broker3").html())+parseFloat($("#stt_tot3").html())+parseFloat($("#trans_charge3").html())+parseFloat($("#cm_charge3").html())+parseFloat($("#gst3").html())+parseFloat($("#sebi_charge3").html())).toFixed(2));
                var fno_totamt = (parseFloat(document.getElementById('broker3').innerHTML) + parseFloat(document.getElementById('stt_tot3').innerHTML) + parseFloat(document.getElementById('trans_charge3').innerHTML) + parseFloat(document.getElementById('gst3').innerHTML) + parseFloat(document.getElementById('sebi_charge3').innerHTML)).toFixed(2);

                document.getElementById('intra_tot3').innerHTML = ((parseFloat(fno_totamt) + parseFloat(fno_buyamt)).toFixed(2));
                document.getElementById('intraday_netprofit3').innerHTML = (((intradays_sell * intradays_q) - (intradays_buy * intradays_q) - document.getElementById('intra_tot3').innerHTML).toFixed(2));
                // $("#intraday_netprofit3").html(((intraday_sell*intraday_q)-(intraday_buy*intraday_q)-$("#intra_tot3").html()).toFixed(2));

            } else {

                document.getElementById('toamt3').innerHTML = 0;
                document.getElementById('broker3').innerHTML = 0;
                document.getElementById('stt_tot3').innerHTML = 0;
                document.getElementById('trans_charge3').innerHTML = 0;
                // document.getElementById('cm_charge3').innerHTML = 0;
                document.getElementById('gst3').innerHTML = 0;
                document.getElementById('sebi_charge3').innerHTML = 0;
                document.getElementById('intra_tot3').innerHTML = 0;
                document.getElementById('intraday_netprofit3').innerHTML = 0;

            }
        },


        intraday4() {
            var intradays_buy = parseFloat(this.intraday_buy1);
            var intradays_sell = parseFloat(this.intraday_sell1);
            var intradays_q = parseFloat(this.intraday_q1);
            var fno_buyamt1 = parseFloat((intradays_buy * intradays_q) * 0.003 / 100).toFixed(2);
            ///console.log("jdchgskj", intradays_buy, intradays_sell, intradays_q, fno_buyamt1)
            if ((intradays_buy + intradays_sell) * intradays_q) {
                document.getElementById('toamt4').innerHTML = (((parseFloat(intradays_buy) + parseFloat(intradays_sell)) * parseFloat(intradays_q)).toFixed(2));
                document.getElementById('broker4').innerHTML = this.brok_type ? ((document.getElementById('toamt4').innerHTML * (this.brok_per4) / 100).toFixed(2)) : this.brok_per4;
                document.getElementById('stt_tot4').innerHTML = (((intradays_sell * intradays_q) * 0.0625 / 100).toFixed(2));
                document.getElementById('trans_charge4').innerHTML = ((document.getElementById('toamt4').innerHTML * 0.05 / 100).toFixed(2));
                document.getElementById('sebi_charge4').innerHTML = ((parseFloat(document.getElementById('toamt4').innerHTML * 0.00010 / 100)).toFixed(2));
                // document.getElementById('cm_charge4').innerHTML = ((document.getElementById('toamt4').innerHTML * 0.02 / 100).toFixed(2));
                document.getElementById('gst4').innerHTML = (((parseFloat(document.getElementById('broker4').innerHTML) + parseFloat(document.getElementById('trans_charge4').innerHTML) + parseFloat(document.getElementById('sebi_charge4').innerHTML)) * 18 / 100).toFixed(2));
                document.getElementById('stamp_charge4').innerHTML = (parseFloat(fno_buyamt1).toFixed(2));
                //$("#intra_tot4").html((parseFloat($("#broker4").html())+parseFloat($("#stt_tot4").html())+parseFloat($("#trans_charge4").html())+parseFloat($("#cm_charge4").html())+parseFloat($("#gst4").html())+parseFloat($("#sebi_charge4").html())).toFixed(2));
                var fno_totamt1 = (parseFloat(document.getElementById('broker4').innerHTML) + parseFloat(document.getElementById('stt_tot4').innerHTML) + parseFloat(document.getElementById('trans_charge4').innerHTML) + parseFloat(document.getElementById('gst4').innerHTML) + parseFloat(document.getElementById('sebi_charge4').innerHTML)).toFixed(2);
                document.getElementById('intra_tot4').innerHTML = ((parseFloat(fno_totamt1) + parseFloat(fno_buyamt1)).toFixed(2));
                document.getElementById('intraday_netprofit4').innerHTML = (((intradays_sell * intradays_q) - (intradays_buy * intradays_q) - document.getElementById('intra_tot4').innerHTML).toFixed(2));
            }
            else {

                document.getElementById('toamt4').innerHTML = 0;
                document.getElementById('broker4').innerHTML = 0;
                document.getElementById('stt_tot4').innerHTML = 0;
                document.getElementById('trans_charge4').innerHTML = 0;
                // document.getElementById('cm_charge4').innerHTML = 0;
                document.getElementById('gst4').innerHTML = 0;
                document.getElementById('sebi_charge4').innerHTML = 0;
                document.getElementById('intra_tot4').innerHTML = 0;
                document.getElementById('intraday_netprofit4').innerHTML = 0;
            }
        },
        intraday5() {
            var intradays_buy = parseFloat(this.intraday_buy1);
            var intradays_sell = parseFloat(this.intraday_sell1);
            var intradays_q = parseFloat(this.intraday_q1);
            var cur_buyamt = parseFloat((intradays_buy * intradays_q) * 0.0001 / 100).toFixed(2);

            if ((intradays_buy + intradays_sell) * intradays_q) {
                document.getElementById('toamt5').innerHTML = (((parseFloat(intradays_buy) + parseFloat(intradays_sell)) * parseFloat(intradays_q)).toFixed(2));
                document.getElementById('broker5').innerHTML = this.brok_type ? ((document.getElementById('toamt5').innerHTML * (this.brok_per5) / 100).toFixed(2)) : this.brok_per5;
                document.getElementById('trans_charge5').innerHTML = ((document.getElementById('toamt5').innerHTML * 0.00090 / 100).toFixed(2));
                document.getElementById('sebi_charge5').innerHTML = (parseFloat(document.getElementById('toamt5').innerHTML * 0.00010 / 100).toFixed(2));
                // document.getElementById('cm_charge5').innerHTML = ((document.getElementById('toamt5').innerHTML * 0.0011 / 100).toFixed(2));
                document.getElementById('gst5').innerHTML = (((parseFloat(document.getElementById('broker5').innerHTML) + parseFloat(document.getElementById('trans_charge5').innerHTML) + parseFloat(document.getElementById('sebi_charge5').innerHTML)) * 18 / 100).toFixed(2));
                document.getElementById('stamp_charge5').innerHTML = (parseFloat(cur_buyamt).toFixed(2));
                //$((parseFloat($("#broker5").html()))+(parseFloat($("#trans_charge5").html()))+(parseFloat($("#cm_charge5").html()))+(parseFloat($("#gst5").html()))+(parseFloat($("#sebi_charge5").html()))).toFixed(2)("#intra_tot5").html();
                var cur_totamt = ((parseFloat(document.getElementById('broker5').innerHTML)) + (parseFloat(document.getElementById('trans_charge5').innerHTML)) + (parseFloat(document.getElementById('gst5').innerHTML)) + (parseFloat(document.getElementById('sebi_charge5').innerHTML))).toFixed(2);
                document.getElementById('intra_tot5').innerHTML = (parseFloat(cur_totamt) + parseFloat(cur_buyamt)).toFixed(2);
                document.getElementById('intraday_netprofit5').innerHTML = (((intradays_sell * intradays_q) - (intradays_buy * intradays_q) - document.getElementById('intra_tot5').innerHTML).toFixed(2));
            }
            else {
                document.getElementById('toamt5').innerHTML = 0;
                document.getElementById('broker5').innerHTML = 0;
                document.getElementById('trans_charge5').innerHTML = 0;
                // document.getElementById('cm_charge5').innerHTML = 0;
                document.getElementById('gst5').innerHTML = 0;
                document.getElementById('sebi_charge5').innerHTML = 0;
                document.getElementById('intra_tot5').innerHTML = 0;
                document.getElementById('intraday_netprofit5').innerHTML = 0;
            }
        },
        intraday6() {
            var intradays_buy = parseFloat(this.intraday_buy1);
            var intradays_sell = parseFloat(this.intraday_sell1);
            var intradays_q = parseFloat(this.intraday_q1);
            var cur_buyamt1 = parseFloat((intradays_buy * intradays_q) * 0.0001 / 100).toFixed(2);
            if ((intradays_buy + intradays_sell) * intradays_q) {
                document.getElementById('toamt6').innerHTML = (((parseFloat(intradays_buy) + parseFloat(intradays_sell)) * parseFloat(intradays_q)).toFixed(2));
                //$("#broker6").html(($("#toamt6").html()*$("#brok_per6").val()/100).toFixed(2));
                document.getElementById('broker6').innerHTML = this.brok_type ? ((document.getElementById('toamt6').innerHTML * (this.brok_per6) / 100).toFixed(2)) : this.brok_per6;
                document.getElementById('trans_charge6').innerHTML = ((document.getElementById('toamt6').innerHTML * 0.03500 / 100).toFixed(2));
                // document.getElementById('cm_charge6').innerHTML = ((document.getElementById('toamt6').innerHTML * 0.02 / 100).toFixed(2));
                document.getElementById('sebi_charge6').innerHTML = ((parseFloat(document.getElementById('toamt6').innerHTML * 0.00010 / 100)).toFixed(2));
                document.getElementById('gst6').innerHTML = (((parseFloat(document.getElementById('broker6').innerHTML) + parseFloat(document.getElementById('trans_charge6').innerHTML) + parseFloat(document.getElementById('sebi_charge6').innerHTML)) * 18 / 100).toFixed(2));
                document.getElementById('stamp_charge6').innerHTML = (parseFloat(cur_buyamt1).toFixed(2));
                var cur_totamt1 = ((parseFloat(document.getElementById('broker6').innerHTML)) + (parseFloat(document.getElementById('trans_charge6').innerHTML)) + (parseFloat(document.getElementById('gst6').innerHTML)) + (parseFloat(document.getElementById('sebi_charge6').innerHTML))).toFixed(2);
                document.getElementById('intra_tot6').innerHTML = ((parseFloat(cur_totamt1) + parseFloat(cur_buyamt1)).toFixed(2));
                document.getElementById('intraday_netprofit6').innerHTML = (((intradays_sell * intradays_q) - (intradays_buy * intradays_q) - document.getElementById('intra_tot6').innerHTML).toFixed(2));
            } else {
                document.getElementById('toamt6').innerHTML = (0);
                document.getElementById('broker6').innerHTML = (0);
                document.getElementById('trans_charge6').innerHTML = (0);
                // document.getElementById('cm_charge6').innerHTML = (0);
                document.getElementById('gst6').innerHTML = (0);
                document.getElementById('sebi_charge6').innerHTML = (0);
                document.getElementById('intra_tot6').innerHTML = (0);
                document.getElementById('intraday_netprofit6').innerHTML = (0);
            }
        },
        intraday7() {
            var intraday_buy = parseFloat(this.intraday_buy1);
            var intraday_sell = parseFloat(this.intraday_sell1);
            var intraday_q = parseFloat(this.intraday_q1);
            var comm_buyamt = parseFloat((intraday_buy * intraday_q) * 0.002 / 100).toFixed(2);
            if ((intraday_buy + intraday_sell) * intraday_q) {
                document.getElementById('toamt7').innerHTML = (((parseFloat(intraday_buy) + parseFloat(intraday_sell)) * parseFloat(intraday_q)).toFixed(2));
                document.getElementById('broker7').innerHTML = this.brok_type ? ((document.getElementById('toamt7').innerHTML * (this.brok_per7) / 100).toFixed(2)) : this.brok_per7;
                document.getElementById('trans_charge7').innerHTML = ((document.getElementById('toamt7').innerHTML * 0.0026 / 100).toFixed(2));
                // document.getElementById('cm_charge7').innerHTML = ((document.getElementById('toamt7').innerHTML * 0.0011 / 100).toFixed(2));
                document.getElementById('sebi_charge7').innerHTML = ((parseFloat(document.getElementById('toamt7').innerHTML * 0.00010 / 100)).toFixed(2));
                document.getElementById('gst7').innerHTML = (((parseFloat(document.getElementById('broker7').innerHTML) + parseFloat(document.getElementById('trans_charge7').innerHTML) + parseFloat(document.getElementById('sebi_charge7').innerHTML)) * 18 / 100).toFixed(2));
                document.getElementById('ctt7').innerHTML = ((intraday_sell * intraday_q * 0.01 / 100).toFixed(2));
                document.getElementById('stamp_charge7').innerHTML = ((parseFloat(comm_buyamt)).toFixed(2));
                //$("#intra_tot7").html(((parseFloat($("#broker7").html()))+(parseFloat($("#trans_charge7").html()))+(parseFloat($("#cm_charge7").html()))+(parseFloat($("#gst7").html()))+(parseFloat($("#ctt7").html()))+(parseFloat($("#sebi_charge7").html()))).toFixed(2));
                var comm_totamt = ((parseFloat(document.getElementById('broker7').innerHTML)) + (parseFloat(document.getElementById('trans_charge7').innerHTML)) + (parseFloat(document.getElementById('gst7').innerHTML)) + (parseFloat(document.getElementById('ctt7').innerHTML)) + (parseFloat(document.getElementById('sebi_charge7').innerHTML))).toFixed(2);
                document.getElementById('intra_tot7').innerHTML = ((parseFloat(comm_totamt) + parseFloat(comm_buyamt)).toFixed(2));
                document.getElementById('intraday_netprofit7').innerHTML = (((intraday_sell * intraday_q) - (intraday_buy * intraday_q) - document.getElementById('intra_tot7').innerHTML).toFixed(2));
            } else {
                document.getElementById('toamt7').innerHTML = (0);
                document.getElementById('broker7').innerHTML = (0);
                document.getElementById('trans_charge7').innerHTML = (0);
                // document.getElementById('cm_charge7').innerHTML = (0);
                document.getElementById('gst7').innerHTML = (0);
                document.getElementById('ctt7').innerHTML = (0);
                document.getElementById('sebi_charge7').innerHTML = (0);
                document.getElementById('intra_tot7').innerHTML = (0);
                document.getElementById('intraday_netprofit7').innerHTML = (0);
            }
        },
        intraday8() {
            var intradays_buy = parseFloat(this.intraday_buy1);
            var intradays_sell = parseFloat(this.intraday_sell1);
            var intradays_q = parseFloat(this.intraday_q1);
            var comm_buyamt1 = parseFloat((intradays_buy * intradays_q) * 0.003 / 100).toFixed(2);
            if ((intradays_buy + intradays_sell) * intradays_q) {
                document.getElementById('toamt8').innerHTML = (((parseFloat(intradays_buy) + parseFloat(intradays_sell)) * parseFloat(intradays_q)).toFixed(2));
                document.getElementById('broker8').innerHTML = this.brok_type ? ((document.getElementById('toamt8').innerHTML * (this.brok_per8) / 100).toFixed(2)) : this.brok_per1;
                document.getElementById('trans_charge8').innerHTML = ((document.getElementById('toamt8').innerHTML * 0.0026 / 100).toFixed(2));
                // document.getElementById('cm_charge8').innerHTML = ((document.getElementById('toamt8').innerHTML * 0.0011 / 100).toFixed(2));
                document.getElementById('sebi_charge8').innerHTML = (parseFloat((document.getElementById('toamt8').innerHTML * 0.00010 / 100)).toFixed(2));
                document.getElementById('gst8').innerHTML = (((parseFloat(document.getElementById('broker8').innerHTML) + parseFloat(document.getElementById('trans_charge8').innerHTML) + parseFloat(document.getElementById('sebi_charge8').innerHTML)) * 18 / 100).toFixed(2));
                document.getElementById('stamp_charge8').innerHTML = ((parseFloat(comm_buyamt1)).toFixed(2));
                //$("#intra_tot8").html(((parseFloat($("#broker8").html()))+(parseFloat($("#trans_charge8").html()))+(parseFloat($("#cm_charge8").html()))+(parseFloat($("#gst8").html()))+(parseFloat($("#sebi_charge8").html()))).toFixed(2));
                var comm_totamt1 = ((parseFloat(document.getElementById('broker8').innerHTML)) + (parseFloat(document.getElementById('trans_charge8').innerHTML)) + (parseFloat(document.getElementById('gst8').innerHTML)) + (parseFloat(document.getElementById('sebi_charge8').innerHTML))).toFixed(2);
                document.getElementById('intra_tot8').innerHTML = ((parseFloat(comm_totamt1) + parseFloat(comm_buyamt1)).toFixed(2));
                document.getElementById('intraday_netprofit8').innerHTML = (((intradays_sell * intradays_q) - (intradays_buy * intradays_q) - document.getElementById('intra_tot8').innerHTML).toFixed(2));
            }
            else {
                document.getElementById('toamt8').innerHTML = (0);
                document.getElementById('broker8').innerHTML = (0);
                document.getElementById('trans_charge8').innerHTML = (0);
                // document.getElementById('cm_charge8').innerHTML = (0);
                document.getElementById('gst8').innerHTML = (0);
                document.getElementById('sebi_charge8').innerHTML = (0);
                document.getElementById('intra_tot8').innerHTML = (0);
                document.getElementById('intraday_netprofit8').innerHTML = (0);
            }
        }
    }

}
</script>